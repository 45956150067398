import { SendPersonRegular } from "@fluentui/react-icons";
import { ActionButton } from "components/styled";
import { useAtomValue } from "jotai";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useResendEmployeeInvitation } from "state/queries/useResendEmployeeInvitation";
import {
  selectedCompanyState,
  selectedTableViewEmployeesAtom,
} from "store/UIHrPage";

export function ResendInvitations() {
  const selectedEmployees = useAtomValue(selectedTableViewEmployeesAtom);
  const company = useAtomValue(selectedCompanyState);
  const { t } = useTranslation();

  const emailList = selectedEmployees.map(
    (employee) => employee.employeeLoginEmailAddress
  );
  const { mutate: onResendInvitation, isLoading: sendInvitationInProgress } =
    useResendEmployeeInvitation();

  return (
    <ActionButton
      noBorder
      appearance="subtle"
      disabled={sendInvitationInProgress || !emailList.length}
      icon={<SendPersonRegular />}
      onClick={async () => {
        onResendInvitation(
          {
            payload: {
              clientInternalNumberGos: company?.clientInternalNumberGos || 0,
              countryCode: company?.countryCode || 0,
              emailList: emailList,
            },
          },
          {
            onSuccess: () => {
              toast.success(t("hbh.invitationSentSuccessfully.label"));
            },
          }
        );
      }}
    >
      {t("hbh.resendInvitations.label")}
    </ActionButton>
  );
}

export default ResendInvitations;
