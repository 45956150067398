import { Text } from "@fluentui/react-components";
import { EditableTable } from "components/EditableTable/EditableTable";
import { useAtomValue } from "jotai";
import { columnNames } from "library/constants";
import { IBenefitImplementation } from "library/types";
import {
  getImplementedBenefitsColumnsDef,
  defaultColumnSizingOptions,
} from "components/columns/implementationColumnsConfig";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { selectedCompanyState } from "store/UIHrPage";
import styled from "styled-components";

type Props = {
  implementedBenefits: IBenefitImplementation[];
  previewMode?: boolean;
};

export function HrImplementationsTable({
  implementedBenefits,
  previewMode,
}: Props) {
  const { t } = useTranslation();
  const selectedCompany = useAtomValue(selectedCompanyState);

  const columns = getImplementedBenefitsColumnsDef({
    countryCode: Number(selectedCompany?.countryCode),
    clientInternalNumberGOS: Number(selectedCompany?.clientInternalNumberGos),
    isReadOnlyUser: previewMode,
    hasSelection: true,
    t,
  });

  const benefitImplementationsTableItems = useMemo(() => {
    return (implementedBenefits || [])
      ?.map((benefit) => {
        return {
          ...benefit,
          coverPhoto: {
            name: benefit.backgroundPicturePath,
            base64String: benefit.backgroundPictureContent,
            type: benefit.backgroundPictureMimeType,
          },
          id: benefit.benefitImplementationTag,
          defaultState: {
            ...benefit,

            coverPhoto: {
              name: benefit.backgroundPicturePath,
              base64String: benefit.backgroundPictureContent,
              type: benefit.backgroundPictureMimeType,
            },
            id: benefit.benefitImplementationTag,
          },
        };
      })
      .sort(
        (a, b) =>
          Number(a.benefitImplementationTag) -
          Number(b.benefitImplementationTag)
      );
  }, [implementedBenefits]);

  return (
    <>
      {implementedBenefits?.length ? (
        <TableWrap>
          <EditableTable
            defaultColumnSizingOptions={defaultColumnSizingOptions}
            defaultItems={benefitImplementationsTableItems}
            columns={columns}
            columnNames={columnNames}
            selectionMode={"single"}
          />
        </TableWrap>
      ) : (
        <Text>
          {t("hbh.noImplementedBenefitsFoCompany.label", {
            company: selectedCompany?.clientName,
          })}
        </Text>
      )}
    </>
  );
}

const TableWrap = styled.div`
  margin-top: 10px;
`;
