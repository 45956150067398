import useNotificationHandler from "hooks/useNotificationHandler";
import { MUTATION_KEYS, QUERY_KEYS } from "library/shared";
import { useMutation, useQueryClient } from "react-query";
import api from "service/api";

const endpoint = process.env.REACT_APP_BASE_URL_AI;
const getChatSessionId = async (documentList: string[]) => {
  const response = await api.post(
    `${endpoint}/GenerateVectorBase`,
    documentList
  );

  return response.data;
};

export function useGetChatSessionId() {
  const { handleAddAxiosErrorNotification } = useNotificationHandler();

  return useMutation(getChatSessionId, {
    mutationKey: MUTATION_KEYS.chatSessionId,

    onError: (error) => {
      // handle error
      handleAddAxiosErrorNotification(error);
    },
  });
}
