import { Button } from "@fluentui/react-components";
import { ArrowClockwiseRegular } from "@fluentui/react-icons";
import { useInvalidateBenefitCategories } from "state/queries/useGetBenefitCategories";

export function CountryPageRefreshBtn() {
  const invalidateBenefitCategories = useInvalidateBenefitCategories();

  const handleRefresh = () => {
    invalidateBenefitCategories();
  };

  return (
    <Button
      onClick={handleRefresh}
      appearance="outline"
      icon={<ArrowClockwiseRegular />}
    />
  );
}
