import {
  Menu,
  MenuButtonProps,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  SplitButton,
} from "@fluentui/react-components";
import {
  ChartMultipleRegular,
  CollectionsRegular,
  DataTrendingRegular,
  HistoryRegular,
  PeopleRegular,
  PeopleSettingsRegular,
  RibbonRegular,
} from "@fluentui/react-icons";
import { ExcelDocumentIcon } from "@fluentui/react-icons-mdl2-branded";
import { OverflowComponent } from "components/OverflowComponent/OverflowComponent";
import { StyledTab, StyledTabList, ToolbarContainer } from "components/styled";
import { useRoleType } from "hooks/useRoleType";
import { useAtom } from "jotai";
import { EManageHrTabView, ERole } from "library/types";
import { useTranslation } from "react-i18next";
import { useGetAuthorization } from "state/queries/useGetAuthorization";
import { selectedHrTabState } from "store";
import styled from "styled-components";
import { ManageEmployee } from "../ManagePageView/EmployeesView/EmployeesTable/EmployeeActions/ManageEmployee/ManageEmployee";
import { EmployeeEnrollmentsReport } from "./EmployeeEnrollmentsReport";
import { EmployeeEnrollmentsReportByTimePeriod } from "./EmployeeEnrollmentsReportByTimePeriod";
import { GenerateEmployeeTemplate } from "./GenerateEmployeeTemplate";
import { ImportEmployeeTemplate } from "./ImportEmployeeTemplate";
import ResendInvitations from "./ResendInvitations";

const getTabs = ({ t, canSeeUserAssignment, canSeeBulkActions }) => [
  {
    value: EManageHrTabView.summary,
    name: t("hbh.summary.label"),
    icon: <ChartMultipleRegular />,
  },
  {
    value: EManageHrTabView.benefits,
    name: t("hbh.benefits.label"),

    icon: <RibbonRegular />,
  },
  {
    value: EManageHrTabView.employees,
    name: t("hbh.employees.label"),

    icon: <PeopleRegular />,
  },

  ...(canSeeBulkActions
    ? [
        {
          value: EManageHrTabView.bulk,
          name: t("hbh.bulkManagement.label"),
          icon: <CollectionsRegular />,
        },
      ]
    : []),
  {
    value: EManageHrTabView.summaryPbi,
    name: t("hbh.analytics.label"),

    icon: <DataTrendingRegular />,
  },
  {
    value: EManageHrTabView.actionHistory,
    name: t("hbh.actionHistory.label"),

    icon: <HistoryRegular />,
  },
  ...(canSeeUserAssignment
    ? [
        {
          value: EManageHrTabView.userAssignments,
          name: t("hbh.userAssignments.label"),

          icon: <PeopleSettingsRegular />,
        },
      ]
    : []),
];

const canSeeUserAssignmentRoles = [
  ERole.SuperAdmin,
  ERole.GlobalAdmin,
  ERole.ReadonlyGlobalAdmin,
  ERole.CountryAdmin,
  ERole.ReadonlyCountryAdmin,
  ERole.CompanyAdmin,
  ERole.ReadonlyCompanyAdmin,
];

export function HrPageToolbar() {
  const { t } = useTranslation();
  const { data: authorization } = useGetAuthorization();
  const isAdminUser = useRoleType()?.hasAdminRole;

  const canSeeUserAssignment = authorization?.roles?.some((role) =>
    canSeeUserAssignmentRoles.includes(role as ERole)
  );

  const canSeeBulkActions = isAdminUser;

  const [selectedTab, setSelectedTab] = useAtom(selectedHrTabState);

  const hasActions =
    selectedTab === EManageHrTabView.employees ||
    selectedTab === EManageHrTabView.bulk;

  const employeeTabActive = selectedTab === EManageHrTabView.employees;

  const tabs = getTabs({
    t,
    canSeeUserAssignment,
    canSeeBulkActions,
  });

  return (
    <ToolbarContainer $mobileTop={employeeTabActive ? "90px" : ""}>
      <StyledTabList
        selectedValue={selectedTab}
        onTabSelect={(e, data) => {
          setSelectedTab(data.value as EManageHrTabView);
        }}
        style={{
          overflowX: "auto",
        }}
      >
        <OverflowComponent>
          {tabs.map((tab) => {
            return (
              <StyledTab key={tab.value} icon={tab.icon} value={tab.value}>
                {tab.name}
              </StyledTab>
            );
          })}
        </OverflowComponent>
      </StyledTabList>

      {hasActions && (
        <ActionsWrap>
          <Menu positioning="below-end" persistOnItemClick>
            <MenuTrigger disableButtonEnhancement>
              {(triggerProps: MenuButtonProps) => (
                <SplitButton
                  icon={<ExcelDocumentIcon />}
                  appearance="transparent"
                  menuButton={triggerProps}
                >
                  <span onClick={triggerProps.onClick}>
                    {t("hbh.actions.label")}
                  </span>
                </SplitButton>
              )}
            </MenuTrigger>

            <StyledPopover>
              <StyledMenuList>
                <StyledMenuItem>
                  <GenerateEmployeeTemplate />
                </StyledMenuItem>
                <StyledMenuItem>
                  <EmployeeEnrollmentsReport />
                </StyledMenuItem>
                <StyledMenuItem>
                  <EmployeeEnrollmentsReportByTimePeriod />
                </StyledMenuItem>
                <MenuItem>
                  <ImportEmployeeTemplate />
                </MenuItem>
              </StyledMenuList>
            </StyledPopover>
          </Menu>
          <div>
            <ManageEmployee isAddEmployee disabled={!isAdminUser} />
          </div>
        </ActionsWrap>
      )}
    </ToolbarContainer>
  );
}

const ActionsWrap = styled.div`
  display: flex;
  align-items: center;

  gap: 10px;
  justify-content: flex-end;
  flex: 1;
  .fui-SplitButton__primaryActionButton {
    color: ${({ theme }) => theme.palette.themePrimary};
  }
`;

const StyledPopover = styled(MenuPopover)`
  max-width: max-content;
  min-width: max-content;
  width: max-content;
`;
const StyledMenuList = styled(MenuList)`
  max-width: max-content;
  min-width: max-content;
  width: max-content;
`;

const StyledMenuItem = styled(MenuItem)`
  max-width: max-content;
  min-width: max-content;
  width: max-content;
`;
