import { Button, Text } from "@fluentui/react-components";
import {
  SquareHintArrowBack20Regular,
  TextClearFormatting24Regular,
} from "@fluentui/react-icons";
import FormFieldLabel from "components/FormFieldLabel/FormFieldLabel";
import { Flex } from "components/styled";
import { useFormikContext } from "formik";
import { useAppTheme } from "hooks/useAppTheme";
import ImageCompress from "quill-image-compress";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactQuill, { Quill } from "react-quill";
import styled from "styled-components";
import { ClientLandingPagePreview } from "./ClientLandingPagePreview";
import { hexColorPalette } from "library/constants";

import BlotFormatter from "quill-blot-formatter";

Quill.register("modules/blotFormatter", BlotFormatter);

// import ImageResize from "quill-image-resize-module-react";

Quill.register("modules/imageCompress", ImageCompress);
// Quill.register("modules/imageResize", ImageResize);

// BEGIN allow image alignment styles
const ImageFormatAttributesList = ["alt", "height", "width", "style"];

const BaseImageFormat = Quill.import("formats/image");
class ImageFormat extends BaseImageFormat {
  static formats(domNode) {
    return ImageFormatAttributesList.reduce(function (formats, attribute) {
      if (domNode.hasAttribute(attribute)) {
        formats[attribute] = domNode.getAttribute(attribute);
      }
      return formats;
    }, {});
  }
  format(name, value) {
    if (ImageFormatAttributesList.indexOf(name) > -1) {
      if (value) {
        this.domNode.setAttribute(name, value);
      } else {
        this.domNode.removeAttribute(name);
      }
    } else {
      super.format(name, value);
    }
  }
}
Quill.register(ImageFormat, true);
// END allow image alignment styles

var icons = Quill.import("ui/icons");

const CustomUndoIcon = `<svg viewbox="0 0 18 18">
<polygon class="ql-fill ql-stroke" points="6 10 4 12 2 10 6 10"></polygon>
<path class="ql-stroke" d="M8.09,13.91A4.6,4.6,0,0,0,9,14,5,5,0,1,0,4,9"></path>
</svg>`;

const CustomRedoIcon = `<svg viewbox="0 0 18 18">
<polygon class="ql-fill ql-stroke" points="12 10 14 12 16 10 12 10"></polygon>
<path class="ql-stroke" d="M9.91,13.91A4.6,4.6,0,0,1,9,14a5,5,0,1,1,5-5"></path>
</svg>`;

icons["undo"] = CustomUndoIcon;
icons["redo"] = CustomRedoIcon;

const MAX_CHARACTERS = 4_000_000; // 4MB in bytes
// const MAX_SIZE = 4 * 1024 * 1024; // 4MB in bytes

type Props = {
  defaultValue?: string;
};

export function ClientTextEditor({ defaultValue = "" }: Props) {
  const { t } = useTranslation();
  const theme = useAppTheme();
  const [value, setValue] = useState("");
  const { setFieldValue, errors } = useFormikContext<any>();

  const [maxCharReached, setMaxCharReached] = useState(false);
  const quillRef = useRef(null);

  // Function to handle Undo
  const handleUndo = () => {
    if (quillRef.current) {
      (quillRef.current as any).getEditor().history.undo();
    }
  };

  // Function to handle Redo
  const handleRedo = () => {
    if (quillRef.current) {
      (quillRef.current as any).getEditor().history.redo();
    }
  };

  const modules = useMemo(() => {
    return {
      history: { delay: 200, maxStack: 500, userOnly: true },
      toolbar: {
        container: [
          [{ header: "1" }, { header: "2" }, { font: [] }],
          [{ size: [] }],
          [
            {
              color: hexColorPalette,
            },
            {
              background: hexColorPalette,
            },
          ],
          ["code-block", "bold", "italic", "underline", "strike", "blockquote"],
          [
            { align: "" },
            { align: "center" },
            { align: "right" },
            { align: "justify" },
          ],
          [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
          ],

          ["link", "image"],
          ["undo", "redo"],
          ["clean"],
        ],
        handlers: {
          undo: handleUndo,
          redo: handleRedo,
          //   background: () => {
          //     console.log("background");
          //   },
        },
      },
      clipboard: {
        // toggle to add extra line breaks when pasting HTML:
        matchVisual: false,
      },
      // imageResize: {
      //   modules: ["Resize", "DisplaySize"],
      // },
      blotFormatter: {},
      imageCompress: {
        quality: 1, // default
        maxWidth: 900, // default
        maxHeight: 900, // default
        imageType: "image/jpeg", // default
        debug: true, // default
        suppressErrorLogging: false, // default
        handleOnPaste: true, //default
        insertIntoEditor: undefined, // default
      },
    };
  }, []);

  useEffect(() => {
    if (value.length > MAX_CHARACTERS) {
      setMaxCharReached(true);
    } else {
      setMaxCharReached(false);
    }
    // const sizeInBytes = new Blob([value]).size; // Convert HTML string to bytes
    // if (sizeInBytes > MAX_CHARACTERS) {
    //   setMaxCharReached(true);
    // } else {
    //   setMaxCharReached(false);
    // }
  }, [value]);

  useEffect(() => {
    setValue(defaultValue || "");
  }, [defaultValue]);

  return (
    <Container $maxCharReached={maxCharReached || !!errors["welcomePageText"]}>
      <Flex justify="space-between" width={"100%"} $align="center">
        <FormFieldLabel
          label={t("hbh.welcomePageText.label")}
          required={true}
        />
        <Flex gap={"20px"}>
          {value ? (
            <Button
              appearance="transparent"
              icon={<TextClearFormatting24Regular />}
              onClick={() => {
                setValue("");
              }}
            >
              {t("greco.clear")}
            </Button>
          ) : (
            <Button
              appearance="transparent"
              icon={<SquareHintArrowBack20Regular />}
              onClick={() => {
                setValue(defaultValue);
              }}
            >
              {t("greco.default")}
            </Button>
          )}
          <ClientLandingPagePreview html={value} />
        </Flex>
      </Flex>
      {maxCharReached && (
        <Text
          style={{
            color: theme.palette.redDark,
          }}
          weight="semibold"
        >
          {t("hbh.maxCharReached.label", {
            limit: "4 MB",
          })}
        </Text>
      )}

      <ReactQuill
        placeholder={t("hbh.welcomePagePlaceholder.label")}
        theme="snow"
        ref={quillRef}
        value={value}
        onChange={(value, delta, source, editor) => {
          const text = editor?.getText()?.trim();

          if (source === "api" && !text) return; //this fix bug when set value to "", on clear button click
          setValue(value);
        }}
        onBlur={() => setFieldValue("welcomePageText", value)}
        modules={modules}
      />
    </Container>
  );
}

const Container = styled.div<{ $maxCharReached?: boolean }>`
  .quill {
    box-sizing: border-box;
    ${({ $maxCharReached, theme }) =>
      $maxCharReached && `border: 1px solid ${theme.palette.redDark};`};
  }
  .ql-container,
  .ql-editor {
    min-height: 400px;
  }
  .ql-editor {
    // white-space: pre;
  }

  .ql-picker-options {
    width: 217px !important;
  }
  .ql-picker-item {
    width: 30px !important;
    height: 30px !important;
  }
`;
