import { Button } from "@fluentui/react-components";
import { ArrowClockwiseRegular } from "@fluentui/react-icons";
import { useAtomValue, useSetAtom } from "jotai";
import { ECompanyTabView } from "library/types";
import { useInvalidateBenefitCategories } from "state/queries/useGetBenefitCategories";
import { refreshAnalyticsAtom, selectedCompanyTabState } from "store";
import {
  selectedCompanyState,
  selectedCountryState,
} from "store/UICompanyPage";

export function CompanyPageRefreshBtn() {
  const selectedCompany = useAtomValue(selectedCompanyState);
  const selectedCountry = useAtomValue(selectedCountryState);

  const selectedCompanyPageTab = useAtomValue(selectedCompanyTabState);
  const setRefreshAnalyticsAtom = useSetAtom(refreshAnalyticsAtom);

  const invalidateBenefitCategories = useInvalidateBenefitCategories();

  const handleRefresh = () => {
    if (!selectedCompany || !selectedCountry) return;

    if (selectedCompanyPageTab === ECompanyTabView.benefits) {
      invalidateBenefitCategories();
      return;
    }
    if (selectedCompanyPageTab === ECompanyTabView.analytics) {
      setRefreshAnalyticsAtom((prev) => !prev);
      return;
    }
  };

  return (
    <Button
      disabled={!selectedCompany || !selectedCountry}
      onClick={handleRefresh}
      appearance="outline"
      icon={<ArrowClockwiseRegular />}
    />
  );
}
