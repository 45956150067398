import { useAtomValue } from "jotai";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  ThemeProvider as StyledThemeProvider,
  styled,
} from "styled-components";
import "./App.css";
import AppHeader from "./components/AppHeader/AppHeader";

import { panelIsOpenState } from "./store";

import {
  FluentProvider,
  webDarkTheme,
  webLightTheme,
} from "@fluentui/react-components";
import { AppSpinner } from "components/AppSpinner/AppSpinner";
import GuardedRoute from "components/GuardedRoute/GuardedRoute";
import { useGetUserAppTargetSetting } from "hooks/useGetUserAppTargetSetting";
import { useResizeObserverDevErrorFix } from "hooks/useResizeObserverDevErrorFix";
import { ROUTES } from "library/constants";
import { EAppSettings } from "library/types";
import { AdminPage } from "pages/AdminPage/AdminPage";
import { CompanyBenefitImplementationPage } from "pages/CompanyBenefitImplementationPage/CompanyBenefitImplementationPage";
import { CompanyPackagesPage } from "pages/CompanyPackagesPage/CompanyPackagesPage";
import { CompanyProfilePage } from "pages/CompanyProfilePage/CompanyProfilePage";
import { CountryPage } from "pages/CountryPage/CountryPage";
import { DemoPage } from "pages/DemoPage/DemoPage";
import { HrPage } from "pages/HrPage/HrPage";
import { UnauthorizedPage } from "pages/UnauthorizedPage/UnauthorizedPage";
import { useEffect, useState } from "react";
import { useGetAuthorization } from "state/queries/useGetAuthorization";
import { useGetTaxonomies } from "state/queries/useGetTaxonomies";
import { allThemes } from "theme";
import { EThemeMode, EThemeName, IStyledTheme } from "theme/types";
import grecoTriangleBg from "./assets/GrECoGraphicTriangles.svg";
import { CompanyPackageEmployeesPage } from "pages/CompanyPackagesPage/CompanyPackageEmployeesPage";

function App() {
  useResizeObserverDevErrorFix();
  const { data: authorization } = useGetAuthorization();

  const location = window.location;
  const is403Page = location.pathname === ROUTES.UNAUTHORIZED_PAGE;
  const { data: taxonomies } = useGetTaxonomies(!is403Page);

  const panelIsOpen = useAtomValue(panelIsOpenState);
  const darkModeAppSetting = useGetUserAppTargetSetting({
    targetSettingName: EAppSettings.darkMode,
  });

  const darkMode = darkModeAppSetting?.userAppSettingValue === "true";

  const themeAppSetting = useGetUserAppTargetSetting({
    targetSettingName: EAppSettings.theme,
  });
  const selectedThemeName =
    themeAppSetting?.userAppSettingValue || EThemeName.main;

  const [appTheme, setAppTheme] = useState<IStyledTheme>(
    allThemes[selectedThemeName][darkMode ? EThemeMode.dark : EThemeMode.light]
  );

  const [fluentTheme, setFluentTheme] = useState(
    darkMode ? webDarkTheme : webLightTheme
  );

  useEffect(() => {
    const fluentTheme = darkMode ? webDarkTheme : webLightTheme;
    setAppTheme(
      allThemes[selectedThemeName][
        darkMode ? EThemeMode.dark : EThemeMode.light
      ]
    );
    setFluentTheme(fluentTheme);
  }, [darkMode, selectedThemeName]);

  if (!authorization?.roles) {
    return null;
  }

  return (
    <div className="App">
      <FluentProvider theme={fluentTheme}>
        <StyledThemeProvider theme={appTheme}>
          {!taxonomies && !is403Page && <AppSpinner />}
          <BrowserRouter>
            <AppHeader />
            {/* <Navigation /> */}
            <Container $isPanelOpen={panelIsOpen}>
              <Routes>
                <Route
                  path={ROUTES.ADMIN}
                  element={
                    <GuardedRoute
                      path={ROUTES.ADMIN}
                      redirectRoute={ROUTES.UNAUTHORIZED_PAGE}
                    >
                      <AdminPage />
                    </GuardedRoute>
                  }
                />
                <Route
                  path={ROUTES.COUNTRY}
                  element={
                    <GuardedRoute
                      path={ROUTES.COUNTRY}
                      redirectRoute={ROUTES.UNAUTHORIZED_PAGE}
                    >
                      <CountryPage />
                    </GuardedRoute>
                  }
                />
                <Route
                  path={ROUTES.COMPANY_PROFILE}
                  element={
                    <GuardedRoute
                      path={ROUTES.COMPANY_PROFILE}
                      redirectRoute={ROUTES.UNAUTHORIZED_PAGE}
                    >
                      <CompanyProfilePage />
                    </GuardedRoute>
                  }
                />
                <Route
                  path={`${ROUTES.COMPANY_PROFILE}/:categoryId/:countryCode/:clientInternalNumberGOS`}
                  element={
                    <GuardedRoute
                      path={ROUTES.COMPANY_PROFILE}
                      redirectRoute={ROUTES.UNAUTHORIZED_PAGE}
                    >
                      <CompanyBenefitImplementationPage />
                    </GuardedRoute>
                  }
                />
                <Route
                  path={`${ROUTES.HR}/employees/:packageId/:categoryId/:implementationId/:countryCode/:clientInternalNumberGOS`}
                  element={
                    <GuardedRoute
                      path={ROUTES.HR}
                      redirectRoute={ROUTES.UNAUTHORIZED_PAGE}
                    >
                      <CompanyPackageEmployeesPage />
                    </GuardedRoute>
                  }
                />
                <Route
                  path={`${ROUTES.HR}/packages/:categoryId/:implementationId/:countryCode/:clientInternalNumberGOS`}
                  element={
                    <GuardedRoute
                      path={ROUTES.HR}
                      redirectRoute={ROUTES.UNAUTHORIZED_PAGE}
                    >
                      <CompanyPackagesPage />
                    </GuardedRoute>
                  }
                />
                <Route
                  path={`${ROUTES.COMPANY_PROFILE}/:categoryId/:implementationId/:countryCode/:clientInternalNumberGOS`}
                  element={
                    <GuardedRoute
                      path={ROUTES.COMPANY_PROFILE}
                      redirectRoute={ROUTES.UNAUTHORIZED_PAGE}
                    >
                      <CompanyPackagesPage />
                    </GuardedRoute>
                  }
                />
                <Route
                  path={ROUTES.HR}
                  element={
                    <GuardedRoute
                      path={ROUTES.HR}
                      redirectRoute={ROUTES.UNAUTHORIZED_PAGE}
                    >
                      <HrPage />
                    </GuardedRoute>
                  }
                />

                <Route
                  path={ROUTES.UNAUTHORIZED_PAGE}
                  element={<UnauthorizedPage />}
                />
                <Route path="/demo" element={<DemoPage />} />
                <Route
                  path="*"
                  element={<Navigate to={ROUTES.ADMIN} replace />}
                />
              </Routes>
            </Container>
            <ToastContainer
              style={{
                zIndex: 9999999,
              }}
            />
          </BrowserRouter>
        </StyledThemeProvider>
      </FluentProvider>
    </div>
  );
}

export default App;

const Container = styled("div")<{ $isPanelOpen: boolean }>`
  position: absolute;
  overflow: auto;
  top: 50px;
  left: 0;
  bottom: 0;
  right: 0;
  // width: ${({ $isPanelOpen }) =>
    $isPanelOpen ? "calc(100% - 340px)" : "100%"};
  width: 100%;
  background-color: ${(props) => props.theme.palette.themeBackground};

  background-image: url("${grecoTriangleBg}");
  background-size: auto;
  background-position-x: right;
  background-position-y: bottom;
  background-repeat: no-repeat;
`;
