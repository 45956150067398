import { Checkbox } from "@fluentui/react-components";
import FormFieldLabel from "components/FormFieldLabel/FormFieldLabel";
import {
  ErrorMessageContainer,
  FormFieldContainer,
  StyledErrorMessage,
} from "components/styled";
import { useEffect, useState } from "react";

type Props = {
  name: string;
  label: string;
  required?: boolean;
  info?: string;
  disabled?: boolean;
  value: boolean;
  setFieldValue: (name: string, value: boolean) => void;
  error?: string;
  style?: React.CSSProperties;
};

const CheckboxField = ({
  name,
  label,
  required = false,
  info,
  error,
  disabled = false,
  setFieldValue,
  value,
  style = {},
}: Props) => {
  const [localValue, setLocalValue] = useState<boolean>(false);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);
  return (
    <FormFieldContainer>
      <Checkbox
        style={style}
        disabled={disabled}
        checked={localValue}
        onChange={(ev, data) => {
          setFieldValue(name, data.checked ? true : false);
        }}
        label={<FormFieldLabel info={info} label={label} required={required} />}
      />
      <ErrorMessageContainer>
        <StyledErrorMessage>{error}</StyledErrorMessage>
      </ErrorMessageContainer>
    </FormFieldContainer>
  );
};

export default CheckboxField;
