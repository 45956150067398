import { useEffect, useMemo, useCallback, useState } from "react";
import { useGetCompanyBenefitPackages } from "state/queries/useGetCompanyBenefitPackages";
import { useAtom, useSetAtom } from "jotai";
import {
  companyEmployeeViewsAtom,
  companyPackagesAtom,
  companyPackagesState,
} from "store/UIHrPage";
import { useTaxonomyOptions } from "./useTaxonomyOptions";
import {
  EApprovalStatus,
  ETaxonomy,
  ITransformedBenefitPackages,
} from "library/types";
import { useTranslation } from "react-i18next";
import { useGetCompanyEmployeeViews } from "state/queries/useGetCompanyEmployeeViews";
import {
  getEnrollmentStatusData,
  packageAgeConditionPassedForUser,
} from "library/utils";

const useEmployeeData = ({
  countryCode,
  clientInternalNumberGOS,
  enabled,
  onlyActiveImplementations,
  searchEmployeeFilterValue,
  selectedTab,
}) => {
  const { t } = useTranslation();
  const enrollmentStatusOptions = useTaxonomyOptions(ETaxonomy.ApprovalStatus);

  const { data: companyPackages, isLoading: companyPackagesLoading } =
    useGetCompanyBenefitPackages({
      countryCode: countryCode || null,
      clientInternalNumberGOS: clientInternalNumberGOS || "",
      enabled: enabled,
    });
  const setCompanyPackageViews = useSetAtom(companyPackagesAtom);

  const setCompanyPackageObj = useSetAtom(companyPackagesState);

  const transformedCompanyPackages = useMemo(() => {
    if (!companyPackages) return {} as ITransformedBenefitPackages;
    const result = {} as ITransformedBenefitPackages;
    setCompanyPackageViews(companyPackages);
    companyPackages.forEach((item) => {
      const tag = item.benefitPackageTag; // assuming benefitPackageTag exists
      if (!tag) return {};
      // If the benefitPackageTag doesn't already exist in the result object, add it
      if (!result[tag]) {
        result[tag] = {};
      }

      // Push the item into the corresponding tag array
      result[tag] = item;
    });
    return result;
  }, [companyPackages]);

  useEffect(() => {
    if (transformedCompanyPackages) {
      setCompanyPackageObj(transformedCompanyPackages);
    }
  }, [transformedCompanyPackages]);

  const { data: employeesDataView, isLoading: loadingEmployeeData } =
    useGetCompanyEmployeeViews({
      countryCode: countryCode || null,
      // clientInternalNumberGOS: 408439,
      clientInternalNumberGOS: clientInternalNumberGOS || "",
      onlyActive: onlyActiveImplementations,
      enabled: enabled,
    });

  const employeesData = useMemo(() => {
    // Group the data by employee email
    if (!employeesDataView) return [];

    const employees = employeesDataView.filter(
      (person) => person.isDependent === false
    );
    const dependents = employeesDataView.filter(
      (person) => person.isDependent === true
    );

    const mappedData = employees.map((employee) => {
      const employeeDependents = dependents.filter(
        (dependent) =>
          dependent.employeeLoginEmailAddress ===
          employee.employeeLoginEmailAddress
      );

      // Add the dependents as a new property to the employee object
      const hasDetails =
        employee.enrollments?.length || employeeDependents?.length;

      const employeeNeedsAttention = employee.enrollments?.some(
        (enrollment) => {
          const benefitPackage =
            transformedCompanyPackages[enrollment.benefitPackageTag];

          const employeePackageAgeConditionPassed =
            packageAgeConditionPassedForUser({
              userDateOfBirth: employee?.dateOfBirth,
              packageAgeLimitLower: benefitPackage?.ageLimitLower,
              packageAgeLimitUpper: benefitPackage?.ageLimitUpper,
            });
          const enrollmentData = getEnrollmentStatusData({
            t,
            employeeEnrollment: enrollment,
            enrollmentStatusOptions,
          });
          return (
            enrollmentData.approvalStatusCode === EApprovalStatus.PENDING ||
            enrollmentData.cancellationStatusCode === EApprovalStatus.PENDING ||
            !employeePackageAgeConditionPassed
          );
        }
      );

      const employeeDependentsNeedAttention = employeeDependents?.some(
        (dependent) => {
          return (
            dependent.enrollments?.some((enrollment) => {
              const enrollmentData = getEnrollmentStatusData({
                t,
                employeeEnrollment: enrollment,
                enrollmentStatusOptions,
              });
              const benefitPackage =
                transformedCompanyPackages[enrollment.benefitPackageTag];
              const dependentPackageAgeConditionPassed =
                packageAgeConditionPassedForUser({
                  userDateOfBirth: dependent?.dateOfBirth,
                  packageAgeLimitLower: benefitPackage?.ageLimitLower,
                  packageAgeLimitUpper: benefitPackage?.ageLimitUpper,
                });
              return (
                enrollmentData.approvalStatusCode === EApprovalStatus.PENDING ||
                enrollmentData.cancellationStatusCode ===
                  EApprovalStatus.PENDING ||
                !dependentPackageAgeConditionPassed
              );
            }) || dependent.needsAttention
          );
        }
      );

      return {
        ...employee,
        // expanded: hasDetails
        //   ? employee.employeeLoginEmailAddress ===
        //     selectedItem?.employeeLoginEmailAddress
        //   : false,

        dependentList: employeeDependents,
        needsAttention:
          employee?.needsAttention ||
          employeeNeedsAttention ||
          employeeDependentsNeedAttention,
      };
    });

    return mappedData;
  }, [
    employeesDataView,
    enrollmentStatusOptions,
    // selectedItem?.employeeLoginEmailAddress,
    t,
    transformedCompanyPackages,
  ]);

  const [companyEmployees, setCompanyEmployees] = useAtom(
    companyEmployeeViewsAtom
  );

  useEffect(() => {
    if (employeesData) {
      setCompanyEmployees(
        employeesData?.filter((item) =>
          `${item.firstName} ${item.lastName}`
            .toLowerCase()
            .includes(searchEmployeeFilterValue)
        )
      );
    }
  }, [employeesData, searchEmployeeFilterValue, selectedTab]);

  return { companyEmployees, companyPackagesLoading, loadingEmployeeData };
};

export default useEmployeeData;
