import { PageSkeleton } from "components/Skeletons/PageSkeleton";
import { PageContent } from "components/styled";
import { useTaxonomyOptions } from "hooks/useTaxonomyOptions";
import { useAtom, useSetAtom } from "jotai";
import { ETaxonomy } from "library/types";
import { useCallback, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useGetBenefitImplementation } from "state/queries/useGetBenefitImplementation";
import { useGetBenefitImplementationPackages } from "state/queries/useGetBenefitImplementationPackages";
import { getClients } from "state/queries/useGetClients";
import {
  filteredPackagesState,
  packagesSearchFilterValueState,
  selectedCompanyState,
  selectedCountryState,
} from "store/UICompanyPage";
import { CompanyPackagesSubheader } from "./components/CompanyPackagesSubheader/CompanyPackagesSubheader";
import { CompanyPackagesToolbar } from "./components/CompanyPackagesToolbar/CompanyPackagesToolbar";
import { ManagePackagesView } from "./components/ManagePackagesView/ManagePackagesView";

export function CompanyPackagesPage() {
  const params = useParams();
  const implementationId = params.implementationId;
  const categoryId = params.categoryId;
  const countryCode = params.countryCode;
  const clientInternalNumberGOS = params.clientInternalNumberGOS;

  const countryOptions = useTaxonomyOptions(ETaxonomy.Country);

  const setSelectedCompany = useSetAtom(selectedCompanyState);
  const setSelectedCountry = useSetAtom(selectedCountryState);

  const [searchFilterValue, setSearchFilterValue] = useAtom(
    packagesSearchFilterValueState
  );

  const [filteredPackages, setFilteredPackages] = useAtom(
    filteredPackagesState
  );

  const selectedCountry = countryOptions.find(
    (option) => option.value === Number(countryCode)
  );

  const {
    data: benefitImplementation,
    isLoading: benefitImplementationLoading,
  } = useGetBenefitImplementation({
    benefitCategoryTag: Number(categoryId),
    countryCode: Number(countryCode),
    benefitImplementationTag: Number(implementationId),
    clientInternalNumberGOS: Number(clientInternalNumberGOS),
  });

  const {
    data: packageList,
    isLoading: packagesLoading,
    isRefetching: packagesRefetching,
  } = useGetBenefitImplementationPackages({
    countryCode: Number(countryCode),

    clientInternalNumberGOS: Number(clientInternalNumberGOS),
    benefitCategoryTag: Number(categoryId),
    benefitImplementationTag: Number(implementationId),
  });

  const handleSearchFilterValue = useCallback(
    (value) => {
      setSearchFilterValue(value);
    },
    [setSearchFilterValue]
  );

  const getAndSetSelectedCompany = async () => {
    const clients = await getClients({
      countryCode: countryCode || "",
      letters: clientInternalNumberGOS,
    });
    const company = clients?.find(
      (client) => client.internalNumber === Number(clientInternalNumberGOS)
    );
    setSelectedCompany({
      label: company?.name?.toString(),
      value: company?.id?.toString(),
      ...company,
    });
  };

  useEffect(() => {
    if (selectedCountry) {
      setSelectedCountry(selectedCountry);
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (countryCode && clientInternalNumberGOS) {
      getAndSetSelectedCompany();
    }
  }, [countryCode, clientInternalNumberGOS]);

  useEffect(() => {
    if (!packageList) return;
    setFilteredPackages(
      packageList?.filter((item) =>
        item?.name?.toLowerCase().includes(searchFilterValue)
      )
    );
  }, [packageList, searchFilterValue, setFilteredPackages]);

  const isLoading =
    benefitImplementationLoading || packagesLoading || packagesRefetching;

  if (isLoading || !benefitImplementation || !packageList)
    return <PageSkeleton />;

  return (
    <>
      <CompanyPackagesSubheader
        disabled={!packageList?.length}
        items={filteredPackages || []}
        benefitImplementation={benefitImplementation}
        searchFilterValue={searchFilterValue}
        setSearchFilterValue={handleSearchFilterValue}
      />
      <CompanyPackagesToolbar benefitImplementation={benefitImplementation} />

      <PageContent>
        <ManagePackagesView
          benefitImplementation={benefitImplementation}
          packageList={filteredPackages || []}
        />
      </PageContent>
    </>
  );
}
