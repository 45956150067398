import {
  Avatar,
  Button,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerHeaderTitle,
  OverlayDrawer,
  Text,
} from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";
import { Flex } from "components/styled";
import { useIsMobile } from "hooks/useIsMobile";
import { useAtom, useAtomValue } from "jotai";
import { useCallback, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { chatHistoryAtom, chatIdAtom, chatPanelOpenAtom } from "store/UIHrPage";
import styled from "styled-components";
import { ChatIcon } from "./ChatIcon";

import { useAppTheme } from "hooks/useAppTheme";
import { MUTATION_KEYS } from "library/shared";
import { useIsMutating } from "react-query";
import { useUserData } from "state/queries/useUserData";
import chatLogo from "../../assets/chatIcon.svg";
import NewMessage from "./NewMessage";

export function ChatPanel() {
  const theme = useAppTheme();
  const [ratingOpen, setRatingOpen] = useState(false);
  const [chatPanelOpen, setChatPanelOpen] = useAtom(chatPanelOpenAtom);
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const { data: user } = useUserData();

  const historyContainerRef = useRef<HTMLDivElement | null>(null);

  const [newMessageState, setNewMessageState] = useState({
    message: "",
    sent: false,
  });

  const chatHistory = useAtomValue(chatHistoryAtom);

  const chatSessionId = useAtomValue(chatIdAtom);

  const toggleChatPanelState = useCallback(() => {
    setChatPanelOpen((prev) => !prev);
  }, []);

  const handleChatPanelOpen = useCallback((state) => {
    setChatPanelOpen(state);
  }, []);

  const handleCloseRatingDialog = useCallback(() => {
    setRatingOpen(false);
  }, []);

  const waitingForChatbotResponse = useIsMutating({
    mutationKey: MUTATION_KEYS.sendMessageToChatbot,
  });

  const handleUpdateNewMessageState = useCallback((state) => {
    return setNewMessageState(state);
  }, []);

  return (
    <>
      <OverlayDrawer
        position="end"
        // size="small"
        open={chatPanelOpen}
        onOpenChange={(state) => {
          setChatPanelOpen(false);
        }}
        style={{
          top: "50px",
          width: isMobile ? "100%" : "400px",
        }}
      >
        <StyledHeader>
          <DrawerHeaderTitle
            action={
              <Button
                appearance="subtle"
                aria-label="Close"
                icon={<Dismiss24Regular />}
                onClick={() => {
                  handleChatPanelOpen(false);
                  // setRatingOpen(true);
                }}
              />
            }
          >
            <Flex $align="center" wrap="nowrap">
              <ChatIcon
                style={{
                  width: 30,
                  height: 28,
                }}
              />
              <Text size={300} weight="semibold">
                {t("hbh.botHello.label")}
              </Text>
            </Flex>
          </DrawerHeaderTitle>
        </StyledHeader>

        <StyledBody>
          {!chatSessionId && (
            <BodyLoadingLayout>
              <LoadingWrap noMarginLeft />
            </BodyLoadingLayout>
          )}
          {/* {waitingForChatbotResponse ? <AppLogoSpinner /> : null} */}

          <Flex direction="column" width={"100%"} gap={"20px"}>
            {(chatHistory || [])?.map((history, index) => {
              return (
                <>
                  {history?.PrevQuestion ? (
                    <Flex
                      ref={historyContainerRef}
                      wrap="nowrap"
                      $align="flex-start"
                      width={"100%"}
                      key={index}
                    >
                      <UserMessage>{history?.PrevQuestion}</UserMessage>
                      <Avatar
                        image={{
                          // src: user?.backgroundPicturePath,
                          src: "",
                        }}
                        style={{
                          width: 30,
                          height: 30,
                          border: `1px solid ${theme.palette.themePrimary}`,
                        }}
                      />
                    </Flex>
                  ) : null}
                  {history?.PrevAnswer ? (
                    <Flex
                      wrap="nowrap"
                      $align="flex-start"
                      width={"100%"}
                      key={index}
                    >
                      <ChatIcon
                        style={{
                          width: 32,
                          height: 30,
                        }}
                      />
                      <BotMessage style={{ textAlign: "left" }}>
                        {history?.PrevAnswer}
                      </BotMessage>
                    </Flex>
                  ) : null}
                </>
              );
            })}
            {newMessageState.sent ? (
              <Flex
                ref={historyContainerRef}
                wrap="nowrap"
                $align="flex-start"
                width={"100%"}
              >
                <UserMessage>{newMessageState.message}</UserMessage>
                <Avatar
                  image={{
                    // src: employeeData?.backgroundPicturePath,
                    src: "",
                  }}
                  style={{
                    width: 30,
                    height: 30,
                    border: `1px solid ${theme.palette.themePrimary}`,
                  }}
                />
              </Flex>
            ) : null}
            {waitingForChatbotResponse ? (
              <Flex wrap="nowrap" $align="flex-start" width={"100%"}>
                <ChatIcon
                  style={{
                    width: 32,
                    height: 30,
                  }}
                />
                <LoadingWrap />
              </Flex>
            ) : null}
          </Flex>
        </StyledBody>
        <StyledFooter>
          <NewMessage
            historyContainerRef={historyContainerRef}
            newMessageState={newMessageState}
            setNewMessageState={handleUpdateNewMessageState}
          />
        </StyledFooter>
      </OverlayDrawer>
      {/* {ratingOpen && (
        <RatingDialog open={ratingOpen} onClose={handleCloseRatingDialog} />
      )} */}
    </>
  );
}

const MessageBox = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: fit-content;
  min-height: 50px;
  background: ${({ theme }) => theme.palette.white};
  border: 1px solid #dedede;
  border-radius: 12px;
  padding: 10px;
  svg {
    width: 30px;
    height: 30px;
  }
`;

const BotMessage = styled(MessageBox)`
  text-align: left;
  border-top-left-radius: 0;
`;

const UserMessage = styled(MessageBox)`
  text-align: right;
  border-top-right-radius: 0;
`;

const BodyLoadingLayout = styled.div`
  display: flex;
  position: absolute;
  z-index: 1;
  opacity: 0.7;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  justify-content: center;
  align-items: center;
`;

const StyledHeader = styled(DrawerHeader)<{ bgImage?: string }>`
  padding: 10px 24px;

  background: ${({ theme }) => theme.palette.neutralLighterAlt};
  color: ${({ theme }) => theme.palette.black};

  border-bottom: 1px solid ${({ theme }) => theme.palette.themePrimary};
`;
const StyledHeader2 = styled(DrawerHeader)<{ bgImage?: string }>`
  padding: 10px 24px;

  background: ${({ theme }) => theme.palette.white};
  color: ${({ theme }) => theme.palette.orangeLighter};
`;

const StyledBody = styled(DrawerBody)`
  position: relative;
  padding: 10px;
  background-color: ${({ theme }) => theme.palette.neutralLighterAlt};
  background-size: calc(15px) calc(15px);
`;
const StyledBody2 = styled(DrawerBody)`
  padding: 10px;
  background: ${({}) =>
    `linear-gradient(rgba(255,255,255,.95), rgba(255,255,255,.95)), url("${chatLogo}")`};
  scrollbar-width: thin;
  background-color: ${({ theme }) => theme.palette.orangeLighter};
  background-size: calc(15px) calc(15px);
`;

const StyledFooter = styled(DrawerFooter)`
  display: flex;
  flex-direction: column;
  padding: 10px 10px 20px;
  background: ${({ theme }) => theme.palette.neutralLighterAlt};
`;

const StyledFooter2 = styled(DrawerFooter)`
  display: flex;
  flex-direction: column;
  padding: 20px 10px;
`;

const LoadingWrap = styled.div<{ noMarginLeft?: boolean }>`
  width: 15px;
  aspect-ratio: 1;
  border-radius: 50%;
  animation: l5 1s infinite linear alternate;
  margin-top: 10px;
  margin-left: ${({ noMarginLeft }) => (noMarginLeft ? "0px" : "50px")};
  @keyframes l5 {
    0% {
      box-shadow: 20px 0 ${({ theme }) => theme.palette.neutralLight},
        -20px 0 #0002;
      background: ${({ theme }) => theme.palette.neutralLight};
    }
    33% {
      box-shadow: 20px 0 ${({ theme }) => theme.palette.neutralLight},
        -20px 0 #0002;
      background: #0002;
    }
    66% {
      box-shadow: 20px 0 #0002,
        -20px 0 ${({ theme }) => theme.palette.neutralLight};
      background: #0002;
    }
    100% {
      box-shadow: 20px 0 #0002,
        -20px 0 ${({ theme }) => theme.palette.neutralLight};
      background: ${({ theme }) => theme.palette.neutralLight};
    }
  }
`;

// :before {
//   content: "";
//   position: absolute;
//   width: 200%;
//   height: 200%;
//   top: -50%;
//   left: -50%;
//   z-index: -1;
//   background: ${({ bgImage }) =>
//     `linear-gradient(rgba(255,255,255,.9), rgba(255,255,255,.9)), url("${bgImage}")`};
//   transform: rotate(30deg);
//   background-repeat: repeat(auto-fill, 40px);
// }
