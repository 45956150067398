import styled from "styled-components";

import { EmployeeRowItem } from "library/types";
import { EmployeeDependentRow } from "./EmployeeDependentRow";
import { EnrolledEmployeeRow } from "./EnrolledEmployeeRow";
import { useTranslation } from "react-i18next";

type Props = {
  item: any;
  updateState?: (data: any) => void;
  selectionMode?: "single" | "multiselect";
  selected?: boolean;
  [x: string]: any;
};

export function SubrowComponent({ item, selected }: Props) {
  const { t } = useTranslation();
  const rowData = { ...item } as EmployeeRowItem;

  const noData = !rowData.enrollments?.length && !rowData?.dependents?.length;

  if (noData) return null;

  const sortedEnrollments = rowData.enrollments?.sort((a, b) => {
    return (
      new Date(a?.dateOfEligibility || "").getTime() -
      new Date(b?.dateOfEligibility || "").getTime()
    );
  });

  const sortedDependents = rowData.dependentList?.sort((a, b) => {
    return (a?.firstName || "").localeCompare(b?.firstName || "");
  });

  return (
    <Container
      selected={!!selected}
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      <Content>
        {sortedEnrollments?.map((enrollment, index) => {
          return (
            <EnrolledEmployeeRow
              employeeData={rowData}
              enrollment={enrollment as EmployeeRowItem}
              key={enrollment.employeeEnrollmentTag + "" + index}
            />
          );
        })}
        {rowData?.dependents?.length ? (
          <SectionLabel>{t("hbh.dependents.label")}</SectionLabel>
        ) : null}

        {sortedDependents?.map((dependent) => {
          return (
            <EmployeeDependentRow
              dependent={dependent}
              key={dependent.employeeDependentTag}
              employee={rowData}
            />
          );
        })}
      </Content>
    </Container>
  );
}

const Container = styled.div<{ selected: boolean }>`
  width: 100%;
  border: 3px solid
    ${({ selected, theme }) =>
      selected ? theme.palette.themeLighter : theme.palette.white};
  background: ${(props) =>
    props.selected
      ? props.theme.palette.themeLighterAlt
      : props.theme.palette.white};
  border-top: none;
  padding-bottom: 20px;
  // padding: 20px 0;
  .fui-TreeItemLayout,
  .fui-TreeItemLayout__main {
    width: 100%;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 100px;
  width: 100%;
  box-sizing: border-box;
  padding-top: 5px;
`;

const SectionLabel = styled.div`
  margin: 20px 0 10px;
  font-weight: 600;
  text-decoration: underline;
`;
