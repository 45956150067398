import { atom } from "jotai";

import { IBenefitCategory, SelectItem } from "library/types";

export const filteredBenefitCategoriesState = atom<
  IBenefitCategory[] | undefined
>(undefined);
export const searchFilterValueState = atom<string>("");

export const selectImagesViewState = atom<SelectItem | null>(null);
