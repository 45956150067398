import {
  Breadcrumb,
  BreadcrumbButton,
  BreadcrumbDivider,
  BreadcrumbItem,
} from "@fluentui/react-components";
import {
  BuildingRegular,
  DocumentOnePageRegular,
  RibbonRegular,
} from "@fluentui/react-icons";
import { FlagIcon } from "@fluentui/react-icons-mdl2";
import { CompanyMobileBreadcrumb } from "components/CompanyMobileBreadcrumb/CompanyMobileBreadcrumb";
import { SearchBoxField } from "components/controls/SearchBoxField";
import { ResultsScore } from "components/ResultsScore/ResultsScore";
import { useIsMobile } from "hooks/useIsMobile";
import { useAtom, useAtomValue } from "jotai";
import { devices, LOCATION_STATES, ROUTES } from "library/constants";
import {
  IBenefitImplementation,
  IBenefitPackage,
  IEmployeeView,
} from "library/types";
import { useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  selectedCompanyState,
  selectedCountryState,
} from "store/UICompanyPage";
import styled from "styled-components";
import { CompanyPackageEmployeesRefreshBtn } from "./CompanyPackageEmployeesRefreshBtn";
// import { isListViewState } from "store/UIAdminPage";

type Props = {
  items: IEmployeeView[] | undefined[];
  setSearchFilterValue: (value: string) => void;
  disabled?: boolean;
  searchFilterValue: string;
  benefitImplementation: IBenefitImplementation;
};

export function CompanyPackageEmployeesSubheader({
  items,
  setSearchFilterValue,
  disabled,
  searchFilterValue,
  benefitImplementation,
}: Props) {
  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const location = useLocation();

  const [selectedCompany, setSelectedCompany] = useAtom(selectedCompanyState);
  const selectedCountry = useAtomValue(selectedCountryState);

  const handleSearch = useCallback((value) => {
    setSearchFilterValue(value.toLowerCase());
  }, []);

  const openFromNonCompanyPage = location.state
    ? !location.state[LOCATION_STATES.PREV_PATH_NAME]?.includes(
        ROUTES.COMPANY_PROFILE
      )
    : false;

  const mobileBreadcrumbItems = useMemo(() => {
    if (location.pathname.includes("/employees/")) {
      return [
        {
          key: 0,
          item: "Packages",
          itemProps: {
            icon: <DocumentOnePageRegular />,
          },
          onClick: () => {
            navigate(
              `${ROUTES.HR}/${benefitImplementation?.benefitCategoryTag}/${benefitImplementation?.benefitImplementationTag}/${benefitImplementation?.countryCode}/${benefitImplementation?.clientInternalNumberGos}`,
              {
                state: { [LOCATION_STATES.PREV_PATH_NAME]: location.pathname },
              }
            );
          },
        },
      ];
    } else if (location.pathname.includes("/packages/")) {
      return [
        {
          key: 0,
          item: "HR",
          itemProps: {
            icon: <DocumentOnePageRegular />,
          },
          onClick: () => {
            navigate(`${ROUTES.HR}`, {
              state: { [LOCATION_STATES.PREV_PATH_NAME]: location.pathname },
            });
          },
        },
      ];
    } else if (openFromNonCompanyPage) {
      return [
        {
          key: 0,
          item: location.state[LOCATION_STATES.PREV_PATH_NAME]
            .replace("/", "")
            .toUpperCase(),
          itemProps: {
            icon: <DocumentOnePageRegular />,
          },
          onClick: () => {
            navigate(location.state[LOCATION_STATES.PREV_PATH_NAME]);
          },
        },
      ];
    }
    return [
      {
        key: 0,
        item: selectedCountry?.text,
        itemProps: {
          icon: <FlagIcon />,
        },
        onClick: () => {
          setSelectedCompany(null);
          navigate(ROUTES.COMPANY_PROFILE);
        },
      },
      {
        key: 1,
        item: selectedCompany?.label,
        itemProps: {
          icon: <BuildingRegular />,
        },
        onClick: () => {
          navigate(ROUTES.COMPANY_PROFILE);
        },
      },
      {
        key: 2,
        item: benefitImplementation?.name,
        itemProps: {
          icon: <RibbonRegular />,
        },
        onClick: () => {
          navigate(
            `${ROUTES.COMPANY_PROFILE}/${benefitImplementation.benefitCategoryTag}/${benefitImplementation?.countryCode}/${benefitImplementation.clientInternalNumberGos}`
          );
        },
      },
    ];
  }, [openFromNonCompanyPage]);

  return (
    <Container>
      <Wrap>
        {/* <Navigation /> */}
        <CompanyPackageEmployeesRefreshBtn />
        <SearchBoxField
          disabled={disabled}
          value={searchFilterValue}
          appearance="outline"
          placeholder="Search..."
          onSearch={handleSearch}
          width={isMobile ? "max-content" : "360px"}
        />
        {isMobile ? (
          <CompanyMobileBreadcrumb items={mobileBreadcrumbItems} />
        ) : (
          <Breadcrumb aria-label="Breadcrumb default example">
            {location.pathname.includes("/employees/") ? (
              <BreadcrumbButton
                icon={<DocumentOnePageRegular />}
                onClick={() => {
                  navigate(
                    `${ROUTES.HR}/packages/${benefitImplementation?.benefitCategoryTag}/${benefitImplementation?.benefitImplementationTag}/${benefitImplementation?.countryCode}/${benefitImplementation?.clientInternalNumberGos}`,
                    {}
                  );
                }}
              >
                Packages
              </BreadcrumbButton>
            ) : location.pathname.includes("/packages/") ? (
              <BreadcrumbButton
                icon={<DocumentOnePageRegular />}
                onClick={() => {
                  navigate(`${ROUTES.HR}`, {});
                }}
              >
                HR
              </BreadcrumbButton>
            ) : openFromNonCompanyPage ? (
              <BreadcrumbItem>
                <BreadcrumbButton
                  icon={<DocumentOnePageRegular />}
                  onClick={() => {
                    navigate(location.state[LOCATION_STATES.PREV_PATH_NAME]);
                  }}
                >
                  {location.state[LOCATION_STATES.PREV_PATH_NAME]
                    .replace("/", "")
                    .toUpperCase()}
                </BreadcrumbButton>
              </BreadcrumbItem>
            ) : (
              <>
                <BreadcrumbItem>
                  <BreadcrumbButton
                    icon={<FlagIcon />}
                    onClick={() => {
                      setSelectedCompany(null);

                      navigate(ROUTES.COMPANY_PROFILE);
                    }}
                  >
                    {selectedCountry?.text}
                  </BreadcrumbButton>
                </BreadcrumbItem>
                <BreadcrumbDivider />
                <BreadcrumbItem>
                  <BreadcrumbButton
                    icon={<BuildingRegular />}
                    onClick={() => {
                      navigate(ROUTES.COMPANY_PROFILE);
                    }}
                  >
                    {selectedCompany?.label}
                  </BreadcrumbButton>
                </BreadcrumbItem>

                <BreadcrumbDivider />
                <BreadcrumbItem>
                  <BreadcrumbButton
                    icon={<RibbonRegular />}
                    onClick={() => {
                      navigate(
                        `${ROUTES.COMPANY_PROFILE}/${benefitImplementation.benefitCategoryTag}/${benefitImplementation?.countryCode}/${benefitImplementation.clientInternalNumberGos}`
                      );
                    }}
                  >
                    {benefitImplementation?.name}
                  </BreadcrumbButton>
                </BreadcrumbItem>
              </>
            )}
          </Breadcrumb>
        )}
      </Wrap>

      <ResultsScore score={items?.length || 0} />
    </Container>
  );
}

const Container = styled.div<{ $showSearchFilter?: boolean }>`
  display: flex;
  width: 100%;
  padding: 10px;
  margin: auto;
  position: sticky;
  z-index: 99;
  top: 0px;
  height: ${(props) => (props.$showSearchFilter ? "auto" : "52px")};
  background-color: ${(props) => props.theme.palette.white};
  box-sizing: border-box;
  justify-content: space-between;
  gap: 5px;
  @media only screen and ${devices.md} {
    padding: 10px 30px;
  }
`;

const Wrap = styled.div`
  display: flex;
  flex-wrap: nowrap;
  gap: 5px;
  @media only screen and ${devices.md} {
  }
`;
