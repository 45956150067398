import { useEffect } from "react";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { chatHistoryAtom, chatIdAtom } from "store/UIHrPage";
import { usePrevious } from "../../../hooks/usePrevious";
import { useGetChatSessionId } from "state/queries/useGetChatSessionId";
import { useAllUserDocumentsForChatbot } from "./useAllUserDocumentsForChatbot";
import { selectedCompanyState } from "store/UIHrPage";

export function useChatSessionId() {
  const allDocuments = useAllUserDocumentsForChatbot();
  const selectedCompany = useAtomValue(selectedCompanyState);
  const [chatId, setChatId] = useAtom(chatIdAtom);
  const setChatHistory = useSetAtom(chatHistoryAtom);

  const { mutate: getChatId, isLoading } = useGetChatSessionId();
  const prevChatId = usePrevious(chatId);

  const prevSelectedCompany = usePrevious(selectedCompany) as any;

  const fetchSessionId = () => {
    getChatId(allDocuments || [], {
      onSuccess: (id) => {
        setChatId(id);
      },
    });
  };
  useEffect(() => {
    if (
      isLoading ||
      !allDocuments ||
      chatId === prevChatId ||
      selectedCompany?.clientName === prevSelectedCompany?.clientName
    )
      return;
    setChatHistory([]);
    fetchSessionId();
  }, [allDocuments, selectedCompany]);
}
