import { Text } from "@fluentui/react-components";
import { useSelectedViewTab } from "hooks/useSelectedViewTab";
import {
  EBenefitTab,
  IBenefitImplementation,
  IBenefitPackage,
} from "library/types";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useInvalidateBenefitImplementationPackages } from "state/queries/useGetBenefitImplementationPackages";
import { BenefitImplementationPackages } from "../BenefitImplementationPackages/BenefitImplementationPackages";
import { PackageCardList } from "./PackageCardList";
import { usePrevious } from "hooks/usePrevious";

type Props = {
  benefitImplementation: IBenefitImplementation;
  packageList: IBenefitPackage[];
};

export function ManagePackagesView({
  benefitImplementation,
  packageList,
}: Props) {
  const { t } = useTranslation();
  const selectedTab = useSelectedViewTab();

  const invalidateBenefitPackages =
    useInvalidateBenefitImplementationPackages();

  const prevSelectedTab = usePrevious(selectedTab);
  useEffect(() => {
    if (!prevSelectedTab) return;
    if (selectedTab === EBenefitTab.grid) {
      invalidateBenefitPackages();
    }
  }, [selectedTab]);

  return (
    <div>
      <Text
        block
        weight="semibold"
        underline
        size={500}
        style={{
          marginBottom: "20px",
        }}
      >
        {t("hbh.implementedPackages.label")}
      </Text>
      {selectedTab === EBenefitTab.list && (
        <BenefitImplementationPackages
          benefitImplementation={benefitImplementation}
          packageList={packageList || []}
        />
      )}
      {selectedTab === EBenefitTab.grid && (
        <PackageCardList
          benefitImplementation={benefitImplementation}
          benefitPackages={packageList}
        />
      )}
    </div>
  );
}
