import { CustomPagination } from "components/CustomPagination/CustomPagination";
import { MainTable } from "components/MainTable/MainTable";
import { Flex, PaginationWrap } from "components/styled";
import { usePaginationConfig } from "hooks/usePaginationConfig";
import { useEffect, useState } from "react";
import styled from "styled-components";

type Props = {
  defaultItems: any[];
  defaultColumnSizingOptions: any;
  columns: any[];
  withPagination?: boolean;
  columnNames: {
    [x: string]: string;
  };
  selectionMode?: "single" | "multiselect";
  tableSize?: "small" | "extra-small" | "medium";
  tableDataAction?: (data: any) => void;
  SubrowComponent?: React.FC<{
    item: {
      updateState: (data: any) => void;
      selectionMode: "single" | "multiselect";
      selected?: boolean;
      [x: string]: any;
    };
  }>;
  onRowClick?: (row) => void;
};

export const EditableTable = ({
  defaultItems,
  defaultColumnSizingOptions,
  columns,
  tableDataAction,
  columnNames,
  onRowClick,
  SubrowComponent,
  withPagination = defaultItems.length > 50,
  selectionMode,
  tableSize = "small",
}: Props) => {
  const [items, setItems] = useState<any>([]);

  const { currentPage, totalPages, from, to, handlePageChange } =
    usePaginationConfig({ numberOfItems: defaultItems.length });

  useEffect(() => {
    setItems(defaultItems);
  }, [defaultItems]);

  return (
    <>
      {withPagination && (
        <PaginationWrap>
          <CustomPagination
            page={currentPage}
            totalPages={totalPages}
            onChange={handlePageChange}
          />
        </PaginationWrap>
      )}
      <TableWrap>
        <MainTable
          tableDataAction={tableDataAction}
          paginationState={withPagination ? { from, to } : null}
          SubrowComponent={SubrowComponent}
          className="main-table"
          size={tableSize}
          columnSizingOptionsState={defaultColumnSizingOptions}
          columnNames={columnNames}
          columnsDef={columns}
          items={items}
          onRowClick={onRowClick}
          selectionMode={selectionMode}
          withPagination={withPagination}
        />
      </TableWrap>
    </>
  );
};

const TableWrap = styled.div`
  overflow-x: auto;
  scrollbar-width: thin;
  display: flex;
  flex-direction: column;
  height: auto;
  // max-height: 75vh;
  max-height: 70vh;
  display: block;
  overflow-y: auto;

  table {
    border-collapse: separate;
  }

  table {
    tr,
    th {
      padding: 5px 10px;
    }

    td,
    th {
      border: none;
    }

    th {
      font-weight: 600;
      text-transform: capitalize;
      color: ${({ theme }) => theme.palette.neutralPrimaryAlt};
    }

    td:nth-of-type(1),
    th:nth-of-type(1) {
      position: sticky;
      left: 0;
      z-index: 1;
      background: inherit;
    }
    td:nth-of-type(2),
    th:nth-of-type(2) {
      position: sticky;
      left: 100px;
      z-index: 1;
      background: inherit;
      box-shadow: 16px 0px 32px 0px rgba(5, 54, 69, 0.06);
    }
    td:nth-of-type(3),
    th:nth-of-type(3) {
      position: sticky;
      left: 320px;
      z-index: 1;
      background: inherit;
      box-shadow: 16px 0px 32px 0px rgba(5, 54, 69, 0.06);
    }

    td:nth-last-of-type(1),
    th:nth-last-of-type(1) {
      position: sticky;
      right: -1px;
      z-index: 1;
      box-shadow: -16px 0px 32px 0px rgba(5, 54, 69, 0.06);
      background: inherit;
      .fui-TableHeaderCell__sortIcon {
        display: none;
      }
    }
  }

  .fui-TableHeader {
    position: sticky;
    top: 0;
    z-index: 3;
    th {
      background: ${({ theme }) => theme.palette.white} !important;
    }
  }

  .fui-TableBody {
    .fui-TableRow {
      height: 38px;
      min-height: 38px;
      max-height: 38px;
    }

    .fui-TableRow:nth-of-type(odd) {
      background: ${({ theme }) =>
        theme.isDark
          ? theme.semanticColors.bodyBackground
          : theme.palette.themeLighterAlt};
      // background: ${({ theme }) => "#F3F9FD"};
    }
    .fui-TableRow:nth-of-type(even) {
      background: ${({ theme }) => theme.palette.white};
    }
    .fui-TableRow:hover {
      background: ${({ theme }) => theme.palette.themeLighter};
      cursor: pointer;
    }
  }
`;
