import { BuildingRegular, PeopleSettingsRegular } from "@fluentui/react-icons";
import { RealEstateIcon } from "@fluentui/react-icons-mdl2";
import { AdminALogo32Icon } from "@fluentui/react-icons-mdl2-branded";
import { Flex } from "components/styled";
import { TFunction } from "i18next";
import { ROUTES } from "library/constants";
import { IAuthorization } from "library/types";
import { canSeePage, genUUID } from "library/utils";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useGetAuthorization } from "state/queries/useGetAuthorization";
import styled from "styled-components";

const getPages = ({
  roles,
  t,
}: {
  roles: IAuthorization["roles"];
  t: TFunction;
}) => {
  const canSeeAdminPage = canSeePage({
    roleList: roles,
    route: ROUTES.ADMIN,
  });

  const canSeeCountryPage = canSeePage({
    roleList: roles,
    route: ROUTES.COUNTRY,
  });
  const canSeeCompanyPage = canSeePage({
    roleList: roles,
    route: ROUTES.COMPANY_PROFILE,
  });
  const canSeeHrPage = canSeePage({
    roleList: roles,
    route: ROUTES.HR,
  });
  return [
    {
      name: t("hbh.admin.label"),
      route: ROUTES.ADMIN,
      Icon: AdminALogo32Icon,
      disabled: false,
      visible: canSeeAdminPage,
    },
    {
      name: t("hbh.country.label"),
      route: ROUTES.COUNTRY,
      Icon: RealEstateIcon,
      disabled: false,
      visible: canSeeCountryPage,
    },
    {
      name: t("hbh.company.label"),
      route: ROUTES.COMPANY_PROFILE,
      Icon: BuildingRegular,
      disabled: false,
      visible: canSeeCompanyPage,
    },
    {
      name: t("hbh.hr.label"),
      route: ROUTES.HR,
      Icon: PeopleSettingsRegular,
      disabled: false,
      visible: canSeeHrPage,
    },
  ];
};

export function NavigationContent() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: authorization } = useGetAuthorization();

  const location = useLocation();

  const pages = getPages({
    roles: authorization?.roles || [],
    t,
  });

  return (
    <Flex direction="column" $align="flex-start" gap={"15px"}>
      {pages.map((page, index) => {
        return (
          <PageItem
            $active={location.pathname.includes(page.route)}
            $disabled={page.disabled}
            $visible={Boolean(page.visible)}
            key={`${page.route} ${index}`}
            onClick={() => {
              if (page.disabled) return;
              navigate(page.route);
            }}
          >
            <Flex gap={"10px"} $align="center">
              {page.Icon && (
                <page.Icon
                  style={{
                    width: "24px",
                    height: "24px",
                  }}
                />
              )}
              {page.name}
            </Flex>
          </PageItem>
        );
      })}
    </Flex>
  );
}

const PageItem = styled.div<{
  $disabled?: boolean;
  $visible?: boolean;
  $active?: boolean;
}>`
  cursor: ${(props) => (props.$disabled ? "not-allowed" : "pointer")};
  font-size: 14px;
  color: ${(props) => {
    if (props.$disabled) return props.theme.semanticColors.disabledText;
    if (props.$active) return props.theme.palette.themePrimary;
    return props.theme.palette.black;
  }};

  border-left: 3px solid
    ${({ theme, $active }) =>
      $active ? theme.palette.themePrimary : "transparent"};
  display: ${(props) => (props.$visible ? "block" : "none")};
  padding: 5px 5px 5px 15px;
  width: 100%;
  &:hover {
    background-color: ${({ theme }) => theme.palette.neutralLighter};
  }
`;
