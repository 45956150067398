import {
  Accordion,
  AccordionHeader,
  AccordionItem,
  AccordionPanel,
  AccordionToggleEventHandler,
  Button,
  Dialog,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  Link,
  makeStyles,
} from "@fluentui/react-components";
import {
  ArrowStepBackRegular,
  Dismiss24Regular,
  DocumentEditRegular,
  DocumentRegular,
  EyeTrackingOffRegular,
  EyeTrackingRegular,
} from "@fluentui/react-icons";
import { SaveIcon } from "@fluentui/react-icons-mdl2";
import { AppSpinner } from "components/AppSpinner/AppSpinner";
import { UploadFile } from "components/BenefitCategoryDialog/UploadFile";
import { ButtonWithTooltip } from "components/ButtonWithTooltip/ButtonWithTooltip";
import { Flex } from "components/styled";
import { useAppTheme } from "hooks/useAppTheme";
import { devices } from "library/constants";
import { fileExtensionIcons } from "library/shared";
import {
  Attachment,
  EmployeeRowItem,
  IClient,
  IFormattedFile,
  IRowItem,
} from "library/types";
import { getExtensionFromUrl, openLinkInNewTab } from "library/utils";
import { IPackageRowItem } from "pages/CompanyPackagesPage/components/BenefitImplementationPackages/shared";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { AttachmentItem } from "./AttachmentItem";

function isClient(
  item: IRowItem | EmployeeRowItem | IPackageRowItem | IClient
): item is IClient {
  return (item as IClient).clientName !== undefined;
}

type Props = {
  showIsAttachHiddenForEmployeeField?: boolean;
  item: IRowItem | EmployeeRowItem | IPackageRowItem | IClient;
  open: boolean;
  setOpen: (open: boolean) => void;
  attachmentActionsState: {
    createAttachmentInProgress: boolean;
    deleteInProgress: boolean;
    onCreateAttachment: (
      attach: IFormattedFile | null,
      isHiddenForEmployee?: boolean
    ) => void;
    onDeleteAttachment: (attach: Attachment) => void;
    onUpdateAttachment: ({
      attachment,
      newValues,
    }: {
      attachment: Attachment;
      newValues: {
        attachmentName: string;
        attachmentSummary: string;
        attachmentAbstract: string;
        isHiddenForEmployee: boolean;
      };
    }) => void;
    updateInProgress: boolean;
  };
};

const useStyles = makeStyles({
  body: {
    display: "flex",
    flexDirection: "column",
    width: "650px",
  },
  content: {
    top: "100px",
    maxHeight: "80vh",

    scrollbarWidth: "thin",
  },
});

export function ManageAttachmentsDialog({
  item,
  open,
  setOpen,
  attachmentActionsState,
  showIsAttachHiddenForEmployeeField = true,
}: Props) {
  const { t } = useTranslation();
  const theme = useAppTheme();

  const [openAttachForms, setOpenAttachForms] = React.useState([""]);
  const handleToggleAttachForm: AccordionToggleEventHandler<string> = (
    event,
    data
  ) => {
    setOpenAttachForms(data.openItems);
  };

  const [attachmentList, setAttachmentList] = React.useState<Attachment[]>([]);
  const [newAttach, setNewAttach] = useState<IFormattedFile | null>(null);
  const [isAttachHiddenForEmployee, setIsAttachHiddenForEmployee] =
    useState(true);
  const styles = useStyles();

  useEffect(() => {
    setAttachmentList(item.attachments || []);
  }, [item?.attachments]);

  const {
    deleteInProgress,
    createAttachmentInProgress,
    onCreateAttachment,
    updateInProgress,
    onDeleteAttachment,
    onUpdateAttachment,
  } = attachmentActionsState;

  const isManageClientAttachments = isClient(item);

  const subjectName = (() => {
    if (isManageClientAttachments) {
      return `${item?.clientName ?? item?.clientName}`;
    }
    return `${item?.name ?? `${item?.firstName} ${item.lastName}`}`;
  })();

  return (
    <>
      <Dialog
        modalType="alert"
        open={open}
        onOpenChange={(event, data) => setOpen(data.open)}
      >
        <StyledDialogSurface
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <DialogBody className={styles.content}>
            <DialogTitle
              action={
                <DialogTrigger action="close">
                  <Button
                    appearance="subtle"
                    aria-label="close"
                    icon={<Dismiss24Regular />}
                  />
                </DialogTrigger>
              }
            >
              {subjectName}: {t("hbh.manageDocuments.label")}
            </DialogTitle>

            {deleteInProgress || createAttachmentInProgress ? (
              <AppSpinner />
            ) : null}
            <DialogContent className={styles.content}>
              <AttachmentList>
                <AddNewAttachment>
                  <UploadFile
                    defaultValue={newAttach}
                    setFieldValue={(_, file) => {
                      setNewAttach(file);
                    }}
                    fieldName=""
                    label=""
                  />
                  <Flex wrap="nowrap">
                    {showIsAttachHiddenForEmployeeField && (
                      <ButtonWithTooltip
                        appearance="subtle"
                        tooltipContent={
                          isAttachHiddenForEmployee
                            ? t("hbh.isHiddenForEmployee.label")
                            : t("hbh.isVisibleForEmployee.label")
                        }
                        onClick={() => {
                          setIsAttachHiddenForEmployee((prev) => !prev);
                        }}
                        icon={
                          isAttachHiddenForEmployee ? (
                            <EyeTrackingOffRegular />
                          ) : (
                            <EyeTrackingRegular />
                          )
                        }
                      />
                    )}
                    <Button
                      style={{
                        padding: "5px 15px",
                      }}
                      disabled={!newAttach}
                      icon={
                        <SaveIcon
                          style={{
                            color: theme.palette.themePrimary,
                            opacity: newAttach ? "1" : "0.5",
                          }}
                        />
                      }
                      // disabled={updateInProgress}
                      appearance="outline"
                      size="small"
                      onClick={async (e) => {
                        e.stopPropagation();
                        await onCreateAttachment(
                          newAttach,
                          isAttachHiddenForEmployee
                        );
                        setNewAttach(null);
                        setIsAttachHiddenForEmployee(true);
                      }}
                    />
                  </Flex>
                </AddNewAttachment>
                <Accordion
                  openItems={openAttachForms}
                  onToggle={handleToggleAttachForm}
                  multiple
                  collapsible
                >
                  {attachmentList?.map((attachment, index) => {
                    const docType = getExtensionFromUrl(
                      attachment.attachmentPath
                    );

                    return (
                      <AccordionItem
                        key={index}
                        value={attachment?.attachmentName}
                      >
                        <AccordionHeader
                          size="large"
                          expandIconPosition="end"
                          expandIcon={
                            <Button
                              size="small"
                              appearance="subtle"
                              icon={
                                openAttachForms.includes(
                                  attachment?.attachmentName || ""
                                ) ? (
                                  <ArrowStepBackRegular />
                                ) : (
                                  <DocumentEditRegular />
                                )
                              }
                            />
                          }
                        >
                          <Flex justify="space-between" width="100%">
                            <Button
                              style={{
                                color: theme.palette.themePrimary,
                                textDecoration: "underline",
                              }}
                              href={attachment?.attachmentPath || ""}
                              onClick={(e) => {
                                e.stopPropagation();
                                openLinkInNewTab(
                                  attachment?.attachmentPath || ""
                                );
                              }}
                              appearance="transparent"
                              icon={
                                fileExtensionIcons[docType] || (
                                  <DocumentRegular />
                                )
                              }
                            >
                              {attachment.attachmentName}
                            </Button>

                            {showIsAttachHiddenForEmployeeField && (
                              <ButtonWithTooltip
                                appearance="transparent"
                                tooltipContent={
                                  attachment.isHiddenForEmployee
                                    ? t("hbh.isHiddenForEmployee.label")
                                    : t("hbh.isVisibleForEmployee.label")
                                }
                                icon={
                                  attachment?.isHiddenForEmployee ? (
                                    <EyeTrackingOffRegular />
                                  ) : (
                                    <EyeTrackingRegular />
                                  )
                                }
                              />
                            )}
                          </Flex>
                        </AccordionHeader>
                        <AccordionPanel>
                          <AttachmentItem
                            showIsAttachHiddenForEmployeeField={
                              showIsAttachHiddenForEmployeeField
                            }
                            deleteInProgress={deleteInProgress}
                            onDeleteAttachment={onDeleteAttachment}
                            attachment={attachment}
                            updateInProgress={updateInProgress}
                            onUpdateAttachment={onUpdateAttachment}
                            item={item}
                            key={attachment.attachmentName + "_" + index}
                          />
                        </AccordionPanel>
                      </AccordionItem>
                    );
                  })}
                </Accordion>
              </AttachmentList>
            </DialogContent>
          </DialogBody>
        </StyledDialogSurface>
      </Dialog>
    </>
  );
}

const StyledDialogSurface = styled(DialogSurface)`
  margin-left: 10px;
  margin-right: 10px;
  width: 95%;
  min-width: 95%;
  max-height: 98vh;
  padding: 15px;

  @media only screen and ${devices.md} {
    width: 800px;
    min-width: 800px;
    padding: 24px;

    margin-left: auto;
    margin-right: auto;
  }
`;

const AttachmentList = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  //   border: 1px solid ${({ theme }) => theme.palette.neutralLight};
  margin: 10px 0;
`;

const AddNewAttachment = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${({ theme }) => theme.palette.neutralLight};
  padding: 5px;
  margin: 20px 0;
`;

const NameLink = styled(Link)`
  display: flex;
  justify-content: flex-start;
  padding: 5px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.neutralLight};
`;
