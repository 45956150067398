import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  makeStyles,
} from "@fluentui/react-components";
import { Dismiss24Regular } from "@fluentui/react-icons";
import { AppSpinner } from "components/AppSpinner/AppSpinner";
import { PrimaryButton } from "components/PrimaryButton/PrimaryButton";
import { Form, Formik } from "formik";
import { useRoleType } from "hooks/useRoleType";
import { useTaxonomyOptions } from "hooks/useTaxonomyOptions";
import { devices } from "library/constants";
import { ETaxonomy, IClient, IFormattedFile } from "library/types";
import { formatSelectFieldForPayload } from "library/utils";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useUpdateClient } from "state/queries/useUpdateClient";
import styled from "styled-components";
import * as yup from "yup";
import { ClientFormItems } from "./ClientFormItems";
import { ManageClientAttachments } from "./ManageClientAttachments";
import { ClientTextEditor } from "./ClientTextEditor";

type Props = {
  client: IClient;
  open: boolean;
  setOpen: (open: boolean) => void;
};

const useStyles = makeStyles({
  body: {
    display: "flex",
    flexDirection: "column",
    width: "800px",
  },
  content: {
    maxHeight: "90vh",

    scrollbarWidth: "thin",
  },
});

export function EditClientDialog({ client, open, setOpen }: Props) {
  const { t } = useTranslation();

  const styles = useStyles();

  const isReadOnlyUser = useRoleType()?.hasReadOnlyRole;

  const { mutate: onUpdateClient, isLoading: updateInProgress } =
    useUpdateClient();

  const sicCodeOptions = useTaxonomyOptions(ETaxonomy.SicCode);

  const handleSubmit = (values) => {
    onUpdateClient(
      {
        clientInternalNumberGOS: Number(client.clientInternalNumberGos),
        countryCode: Number(client.countryCode),

        payload: {
          ...client,
          ...values,
          sicCode: formatSelectFieldForPayload({
            field: "sicCode",
            values,
          }),
          backgroundPictureContent: values.coverPhoto?.base64String,
          backgroundPictureName: values.coverPhoto?.name,
          backgroundPicturePath: values.coverPhoto?.path,
          backgroundPictureMimeType: values.coverPhoto?.type,
          ...(values.coverPhoto?.base64String && {
            backgroundPictureContent: values.coverPhoto?.base64String,
            backgroundPictureName: values.coverPhoto?.name,
            backgroundPicturePath: values.coverPhoto?.path,
            backgroundPictureMimeType: values.coverPhoto?.type,
          }),
        },
      },
      {
        onSuccess: () => {
          toast.success(t("hbh.clientUpdatedSuccessfully.label"));
          setOpen(false);
        },
      }
    );
  };

  const initialValues = useMemo(() => {
    if (!client) return {};
    return {
      attachments: client.attachments,
      clientName: client.clientName,
      emailInvitationText: client.emailInvitationText,
      sicCode: sicCodeOptions.find((item) => item.value === client.sicCode),
      messageToEmployees: client.messageToEmployees,
      welcomePageText: client.welcomePageText,

      coverPhoto: client?.backgroundPicturePath
        ? ({
            name: client?.backgroundPictureName,
            path: client?.backgroundPicturePath,
            base64String: client?.backgroundPictureContent,
            type: client?.backgroundPictureMimeType,
          } as IFormattedFile)
        : null,
    };
  }, [client, sicCodeOptions]);

  const validationSchema = yup.object().shape({
    emailInvitationText: yup.string().min(2).max(4000).required(),
    messageToEmployees: yup.string().min(2).max(4000).required(),
    sicCode: yup.mixed().required(),
    welcomePageText: yup.string().min(2).max(4_000_000).required(),
    // paidByCompany: yup.string().required("Required"),
  });

  return (
    <Dialog
      modalType="alert"
      open={open}
      onOpenChange={(event, data) => setOpen(data.open)}
    >
      <StyledDialogSurface
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Formik
          enableReinitialize
          validateOnChange={false}
          validateOnBlur={false}
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={(values, actions) => {
            handleSubmit(values);
            actions.setSubmitting(false);
          }}
        >
          {({ dirty }) => {
            return (
              <Form>
                <DialogBody className={styles.content}>
                  <DialogTitle
                    action={
                      <DialogTrigger action="close">
                        <Button
                          appearance="subtle"
                          aria-label="close"
                          icon={<Dismiss24Regular />}
                        />
                      </DialogTrigger>
                    }
                  >
                    {t("hbh.editClient.label", {
                      client: client.clientName,
                    })}
                  </DialogTitle>

                  {updateInProgress ? <AppSpinner /> : null}
                  <DialogContent className={styles.content}>
                    <ClientFormItems />
                    <ClientTextEditor
                      defaultValue={client?.welcomePageText || ""}
                    />
                    <ManageClientAttachments client={client} />
                  </DialogContent>
                  <DialogActions>
                    <PrimaryButton
                      disabled={updateInProgress || !dirty || isReadOnlyUser}
                      style={{
                        paddingLeft: "25px",
                        paddingRight: "25px",
                      }}
                    >
                      {t("greco.save")}
                    </PrimaryButton>
                  </DialogActions>
                </DialogBody>
              </Form>
            );
          }}
        </Formik>
      </StyledDialogSurface>
    </Dialog>
  );
}
const StyledDialogSurface = styled(DialogSurface)`
  margin-left: 10px;
  margin-right: 10px;
  width: 95%;
  min-width: 95%;
  max-height: 98vh;
  padding: 15px;

  @media only screen and ${devices.md} {
    width: 970px;
    min-width: 970px;
    padding: 24px;

    margin-left: auto;
    margin-right: auto;
  }
`;
