import { AppSpinner } from "components/AppSpinner/AppSpinner";
import { FilteringCombobox } from "components/controls/FilteringCombobox";
import { useAppTheme } from "hooks/useAppTheme";
import { useAtom } from "jotai";
import { devices } from "library/constants";
import { useTranslation } from "react-i18next";
import { useGetClientsByCountry } from "state/queries/useGetClientsByCountry";
import { selectedCompanyState } from "store/UIHrPage";
import styled from "styled-components";
import { HrRefreshBtn } from "./HrRefreshBtn";

export function HubPageSection() {
  const { t } = useTranslation();
  const { palette } = useAppTheme();
  const [selectedCompany, setSelectedCompany] = useAtom(selectedCompanyState);

  const {
    data: companyOptions,
    isLoading: companyOptionsLoading,
    isRefetching: companyOptionsRefetching,
  } = useGetClientsByCountry({
    countryCode: 0,
  });

  if (companyOptionsLoading) return <AppSpinner />;

  return (
    <Container>
      <SelectItemsWrap>
        <HrRefreshBtn />
        <FilteringCombobox
          disabled={companyOptionsLoading || companyOptionsRefetching}
          appearance="outline"
          style={{
            width: "100%",
            background: palette.themeLighterAlt,
            borderColor: palette.neutralLight,
          }}
          placeholder={t("hbh.selectCompany.label")}
          error={selectedCompany?.value ? "" : t("hbh.companyIsRequired.label")}
          setFieldValue={(_, value) => {
            setSelectedCompany(value);
          }}
          value={selectedCompany as any}
          label={t("hbh.company.label")}
          noLabel
          noErrorSection
          options={companyOptions || []}
          name={"company"}
        />
        {/* <CompanySelect
          selectedCountry={selectedCountry}
          noLabel
          noErrorSection
        /> */}
      </SelectItemsWrap>
    </Container>
  );
}

const Container = styled.div`
  display: flex;

  // position: sticky;
  // top: 0;

  flex-direction: column;
  gap: 20px;
  @media only screen and ${devices.md} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const SelectItemsWrap = styled.div`
  width: 300px;
  gap: 10px;
  display: flex;
  flex-wrap: wrap;

  @media only screen and ${devices.md} {
    flex-direction: row;
    justify-content: space-between;
    width: 400px;
    flex-wrap: nowrap;
  }
`;
