import { PageSkeleton } from "components/Skeletons/PageSkeleton";
import { TableSkeleton } from "components/Skeletons/TableSkeleton";
import { PageContent } from "components/styled";
import { Subheader } from "components/Subheader/Subheader";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { EManageHrTabView } from "library/types";
import { useCallback, useEffect } from "react";
import { selectedHrTabState } from "store";
import {
  expandedBenefitsAtom,
  expandedEmployeesAtom,
  onlyActiveImplementationsState,
  searchEmployeeFilterValueState,
  selectedBenefitsAtom,
  selectedCompanyState,
  selectedEmployeesAtom,
} from "store/UIHrPage";
import {
  expandedBenefitsAtom as expandedBenefitsDashboardAtom,
  selectedBenefitsAtom as selectedBenefitsDashboardAtom,
} from "store/UIHrPageDashboard";

import useEmployeeData from "hooks/useEmployeeData";
import { useTranslation } from "react-i18next";
import { HrPageToolbar } from "./components/HrPageToolbar/HrPageToolbar";
import { ManagePageView } from "./components/ManagePageView/ManagePageView";
import { SummarySkeleton } from "./components/ManagePageView/SummaryView/SummarySkeleton";
import { useChatSessionId } from "./hooks/useChatSessionId";

// const austriaCountryCode = 834;

export function HrPage() {
  useChatSessionId();
  const { t } = useTranslation();
  const selectedCompany = useAtomValue(selectedCompanyState);

  const selectedTab = useAtomValue(selectedHrTabState);
  const onlyActiveImplementations = useAtomValue(
    onlyActiveImplementationsState
  );

  const [selectedBenefits, setSelectedBenefits] = useAtom(
    selectedBenefitsDashboardAtom
  );

  const [searchEmployeeFilterValue, setSearchEmployeeFilterValue] = useAtom(
    searchEmployeeFilterValueState
  );

  useEffect(() => {
    setSelectedBenefits(new Set([]));
  }, [selectedCompany]);

  const handleSearchEmployeeFilterValue = useCallback(
    (value) => {
      setSearchEmployeeFilterValue(value);
    },
    [setSearchEmployeeFilterValue]
  );

  const setSelectedItems = useSetAtom(selectedEmployeesAtom);
  const setExpandedItems = useSetAtom(expandedEmployeesAtom);
  const setSelectedPackages = useSetAtom(selectedBenefitsAtom);
  const setSelectedDashboardPackages = useSetAtom(
    selectedBenefitsDashboardAtom
  );
  const setExpandedPackages = useSetAtom(expandedBenefitsAtom);
  const setSelectedPackagesDashboard = useSetAtom(
    selectedBenefitsDashboardAtom
  );
  const setExpandedPackagesDashboard = useSetAtom(
    expandedBenefitsDashboardAtom
  );

  useEffect(() => {
    setSearchEmployeeFilterValue("");
    setSelectedItems(new Set<string>());
    setExpandedItems(new Set<string>());
    setSelectedPackages(new Set<string>());
    //setSelectedDashboardPackages(new Set<string>([]));
    setExpandedPackages(new Set<string>());
    setSelectedPackagesDashboard(new Set<string>());
    setExpandedPackagesDashboard(new Set<string>());
  }, [selectedCompany]);

  const Skeleton = (() => {
    if (selectedTab === EManageHrTabView.summary) {
      return <SummarySkeleton />;
    }
    if (selectedTab === EManageHrTabView.employees) {
      return <PageSkeleton isListView />;
    }
    if (selectedTab === EManageHrTabView.actionHistory) {
      return <TableSkeleton rowItems={10} />;
    }
    if (selectedTab === EManageHrTabView.benefits) {
      return <PageSkeleton isCardView />;
    }
  })();

  const { loadingEmployeeData, companyPackagesLoading, companyEmployees } =
    useEmployeeData({
      countryCode: selectedCompany?.countryCode || null,
      clientInternalNumberGOS: selectedCompany?.clientInternalNumberGos || "",
      enabled: !!selectedCompany,
      onlyActiveImplementations,
      searchEmployeeFilterValue,
      selectedTab,
    });

  if (loadingEmployeeData || companyPackagesLoading) return Skeleton;

  return (
    <>
      <Subheader
        searchFilterValue={searchEmployeeFilterValue}
        isHrPage
        showSearchFilter={selectedTab === EManageHrTabView.employees}
        items={companyEmployees || []}
        setSearchFilterValue={handleSearchEmployeeFilterValue}
        chatBotDisabled={!selectedCompany}
      />
      {selectedCompany && <HrPageToolbar />}
      <PageContent>
        {selectedCompany && (
          <>
            <ManagePageView />
          </>
        )}
      </PageContent>
    </>
  );
}
