import { Button } from "@fluentui/react-components";
import { ArrowClockwiseRegular } from "@fluentui/react-icons";
import { useInvalidateBenefitImplementations } from "state/queries/useGetBenefitImplementations";

export function CompanyImplementationRefreshBtn() {
  const invalidateBenefitImplementations =
    useInvalidateBenefitImplementations();

  const handleRefresh = () => {
    invalidateBenefitImplementations();
  };

  return (
    <Button
      onClick={handleRefresh}
      appearance="outline"
      icon={<ArrowClockwiseRegular />}
    />
  );
}
