import { SearchBoxField } from "components/controls/SearchBoxField";
import { useIsMobile } from "hooks/useIsMobile";
import { devices } from "library/constants";
import { IEmployeeView } from "library/types";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { ResultsScore } from "../ResultsScore/ResultsScore";
import CompanyPageSection from "./CompanyPageSection/CompanyPageSection";
import { CountryPageSection } from "./CountryPageSection/CountryPageSection";
import { HubPageSection } from "./HubPageSection/HubPageSection";
import { AdminPageSection } from "./AdminPageSection/AdminPageSection";
import { Flex } from "components/styled";
import { Button } from "@fluentui/react-components";
import { ChatIcon } from "components/ChatPanel/ChatIcon";
import { useSetAtom } from "jotai";
import { useAppTheme } from "hooks/useAppTheme";
import { chatPanelOpenAtom } from "store/UIHrPage";
// import { isListViewState } from "store/UIAdminPage";

type Props = {
  items: IEmployeeView[];
  setSearchFilterValue: (value: string) => void;
  disabled?: boolean;
  searchFilterValue: string;
  showSearchFilter?: boolean;
  isHrPage?: boolean;
  isCountryPage?: boolean;
  isCompanyPage?: boolean;
  isAdminPage?: boolean;
  chatBotDisabled?: boolean;
};

export function Subheader({
  items,
  setSearchFilterValue,
  disabled,
  searchFilterValue,
  showSearchFilter = true,
  isAdminPage,
  isCountryPage,
  isHrPage,
  isCompanyPage,
  chatBotDisabled,
}: Props) {
  const { palette } = useAppTheme();
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  const setChatPanelOpen = useSetAtom(chatPanelOpenAtom);

  const handleSearch = useCallback(
    (value) => {
      setSearchFilterValue(value.toLowerCase());
    },
    [setSearchFilterValue]
  );

  return (
    <Container $showSearchFilter={showSearchFilter}>
      <Wrap>
        {/* <Navigation /> */}
        {isAdminPage ? <AdminPageSection /> : null}
        {isHrPage ? <HubPageSection /> : null}
        {isCountryPage ? (
          <CountryPageSection setSearchFilterValue={setSearchFilterValue} />
        ) : null}

        {isCompanyPage ? <CompanyPageSection /> : null}

        <SearchBoxField
          style={{
            ...(!showSearchFilter && { display: "none" }),
          }}
          disabled={disabled}
          value={searchFilterValue}
          appearance="outline"
          placeholder={t("greco.form.searchPlaceholder")}
          onSearch={handleSearch}
          width={isMobile ? "300px" : "360px"}
        />
      </Wrap>
      {showSearchFilter || isHrPage ? (
        <Flex $align="center">
          {showSearchFilter && <ResultsScore score={items?.length || 0} />}
          {isHrPage && (
            <Button
              disabled={chatBotDisabled}
              size="large"
              style={{
                height: 44,
              }}
              onClick={() => {
                setChatPanelOpen(true);
              }}
              appearance="subtle"
              icon={
                <ChatIcon
                  style={{
                    width: "24px",
                    height: "24px",
                    ...(chatBotDisabled
                      ? {
                          color: palette.white, //disabled color
                          background: palette.neutralLight, //disabled color
                        }
                      : {}),
                  }}
                />
              }
            >
              {t("hbh.askQuestion.label")}
            </Button>
          )}
        </Flex>
      ) : null}
    </Container>
  );
}

const Container = styled.div<{ $showSearchFilter?: boolean }>`
  display: flex;
  width: 100%;
  padding: 10px;
  margin: auto;
  position: sticky;
  align-items: center;
  z-index: 99;
  top: 0px;
  height: ${(props) => (props.$showSearchFilter ? "auto" : "52px")};
  background-color: ${(props) => props.theme.palette.white};
  box-sizing: border-box;
  justify-content: space-between;
  gap: 5px;
  @media only screen and ${devices.md} {
    padding: 10px 30px;
  }
`;

const Wrap = styled.div`
  display: flex;
  gap: 5px;
  justify-content: flex-start;

  flex-wrap: wrap;
  @media only screen and ${devices.md} {
    justify-content: flex-start;
  }
`;
