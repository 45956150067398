import { Button, Spinner } from "@fluentui/react-components";
import {
  ArrowUndoFilled,
  OpenFilled,
  PeopleFilled,
  PeopleRegular,
} from "@fluentui/react-icons";
import { DeleteIcon, EditIcon, SaveIcon } from "@fluentui/react-icons-mdl2";
import { AppSpinner } from "components/AppSpinner/AppSpinner";
import { ConfirmationDialog } from "components/ConfirmationDialog/ConfirmationDialog";
import { PackageDialog } from "components/PackageDialog/PackageDialog";
import { Flex } from "components/styled";
import { useAppTheme } from "hooks/useAppTheme";
import { IBenefitImplementation, IBenefitPackage } from "library/types";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useDeleteBenefitImplementationPackage } from "state/queries/useDeleteBenefitImplementationPackage";
import { useUpdateBenefitImplementationPackage } from "state/queries/useUpdateBenefitImplementationPackage";
import styled from "styled-components";
import { IPackageRowItem } from "./shared";
import { LOCATION_STATES, ROUTES } from "library/constants";
import { useLocation, useNavigate } from "react-router-dom";

type Props = {
  item: IPackageRowItem;
  readOnly?: boolean;
  benefitImplementation?: IBenefitImplementation;
};

export function PackageActionCell({
  item,
  benefitImplementation,
  readOnly,
}: Props) {
  const { t } = useTranslation();
  const { palette } = useAppTheme();
  const navigate = useNavigate();
  const location = useLocation();

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

  const [open, setOpen] = useState(false);

  const { mutate: onDeletePackage, isLoading: deleteInProgress } =
    useDeleteBenefitImplementationPackage();

  const toggleDeleteConfirmationState = useCallback(() => {
    setDeleteConfirmationOpen((prev) => !prev);
  }, []);

  const {
    mutate: onUpdatePackage,
    data: updatedData,
    isLoading: updateInProgress,
  } = useUpdateBenefitImplementationPackage(false);
  const onDialogOpenChange = useCallback((state) => {
    setOpen(state);
  }, []);

  const handleDeletePackage = useCallback(() => {
    if (!item) return;
    onDeletePackage(
      {
        benefitCategoryTag: Number(item.benefitCategoryTag),
        benefitImplementationTag: Number(item.benefitImplementationTag),
        benefitPackageTag: Number(item.benefitPackageTag),
        clientInternalNumberGOS: Number(item.clientInternalNumberGos),
        countryCode: Number(item.countryCode),
        rowVersion: item.rowVersion || "",
      },
      {
        onSuccess: () => {
          toggleDeleteConfirmationState();
          setOpen(false);
          toast.success(t("hbh.packageDeletedSuccessfully.label"));
        },
      }
    );
  }, [item, onDeletePackage, toggleDeleteConfirmationState, t]);

  const loading = updateInProgress || deleteInProgress;

  return (
    <>
      <Container>
        {!item?.isEdited ? (
          <>
            <Button
              style={{
                color: palette.themePrimary,
              }}
              size="small"
              disabled={loading || readOnly}
              appearance="transparent"
              icon={<PeopleFilled />}
              title={t("hbh.openEmployees.label")}
              onClick={() => {
                navigate(
                  `${ROUTES.HR}/employees/${item?.benefitPackageTag}/${item?.benefitCategoryTag}/${item?.benefitImplementationTag}/${item?.countryCode}/${item?.clientInternalNumberGos}`,
                  {
                    state: {
                      [LOCATION_STATES.PREV_PATH_NAME]: location.pathname,
                    },
                  }
                );
              }}
            />
            <Button
              style={{
                color: palette.themePrimary,
              }}
              size="small"
              disabled={loading || readOnly}
              appearance="transparent"
              icon={<EditIcon />}
              onClick={() => {
                setOpen(true);
              }}
            />
            {open && (
              <PackageDialog
                benefitImplementation={
                  benefitImplementation as IBenefitImplementation
                }
                packageData={item}
                open={open}
                setOpen={onDialogOpenChange}
              />
            )}
            <Button
              style={{
                color: palette.redDark,
              }}
              appearance={"transparent"}
              disabled={loading || readOnly}
              icon={<DeleteIcon />}
              onClick={() => {
                setDeleteConfirmationOpen(true);
              }}
            />
          </>
        ) : updateInProgress ? (
          <>
            <Spinner size="extra-small" />
            <AppSpinner />
          </>
        ) : (
          <Flex width={"100%"} justify="center" gap={"2px"}>
            <Button
              disabled={loading || readOnly}
              style={{
                ...(!item.disabled ? { color: palette.themePrimary } : {}),
              }}
              icon={<SaveIcon />}
              appearance="transparent"
              size="small"
              onClick={() => {
                onUpdatePackage(
                  {
                    countryCode: Number(benefitImplementation?.countryCode),
                    clientInternalNumberGOS: Number(
                      benefitImplementation?.clientInternalNumberGos
                    ),
                    benefitCategoryTag: Number(
                      benefitImplementation?.benefitCategoryTag
                    ),
                    benefitImplementationTag: Number(
                      benefitImplementation?.benefitImplementationTag
                    ),
                    benefitPackageTag: Number(item.benefitPackageTag),

                    payload: {
                      ...item,

                      ...((item.coverPhoto?.base64String ||
                        item.coverPhoto?.path) && {
                        backgroundPictureContent:
                          item.coverPhoto?.base64String || null,
                        backgroundPictureName: item.coverPhoto?.name || null,
                        backgroundPicturePath: item.coverPhoto?.path || null,
                        backgroundPictureMimeType:
                          item.coverPhoto?.type || null,
                      }),
                      ...(!item.coverPhoto?.name && {
                        backgroundPictureContent: null,
                        backgroundPictureName: null,
                        backgroundPicturePath: null,
                        backgroundPictureMimeType: null,
                      }),
                    } as IBenefitPackage,
                  },
                  {
                    onSuccess: (data) => {
                      const updatedItem = {
                        ...data,
                        coverPhoto: {
                          name: data.backgroundPicturePath,
                          base64String: data.backgroundPictureContent,
                          type: data.backgroundPictureMimeType,
                        },
                        defaultState: {
                          ...data,
                          id: data.benefitPackageTag,
                          coverPhoto: {
                            name: data.backgroundPicturePath,
                            base64String: data.backgroundPictureContent,
                            type: data.backgroundPictureMimeType,
                          },
                        },
                        isEdited: false,
                        rowVersion: data.rowVersion,
                        id: data.benefitPackageTag,
                      };
                      item.updateState(updatedItem as IBenefitPackage);
                      toast.success(t("hbh.packageUpdatedSuccessfully.label"));
                    },
                  }
                );
              }}
            />
            <Button
              disabled={loading || readOnly}
              style={{
                color: palette.redDark,
              }}
              icon={<ArrowUndoFilled />}
              appearance="transparent"
              size="small"
              onClick={() => {
                const updatedRowItem = updatedData as IPackageRowItem;
                if (updatedRowItem) {
                  const updatedItem = {
                    ...updatedRowItem,
                    coverPhoto: {
                      name: updatedRowItem.backgroundPicturePath || null,
                      base64String:
                        updatedRowItem.backgroundPictureContent || null,
                      type: updatedRowItem.backgroundPictureMimeType || null,
                    },
                    defaultState: {
                      ...updatedRowItem,
                      coverPhoto: {
                        name: updatedRowItem.backgroundPicturePath,
                        base64String: updatedRowItem.backgroundPictureContent,
                        type: updatedRowItem.backgroundPictureMimeType,
                      },
                      id: updatedRowItem.benefitPackageTag,
                    },
                    isEdited: false,
                    id: updatedRowItem.benefitPackageTag,
                  };
                  item.updateState(updatedItem, true);
                  return;
                }
                const updatedItem = {
                  ...item.defaultState,
                  defaultState: item.defaultState,
                  isEdited: false,
                } as any;
                item.updateState(updatedItem, true);
              }}
            />
            <Button
              style={{
                color: palette.redDark,
              }}
              size="small"
              appearance={"transparent"}
              disabled={loading || readOnly}
              icon={<DeleteIcon />}
              onClick={() => {
                setDeleteConfirmationOpen(true);
              }}
            />
          </Flex>
        )}
      </Container>
      {deleteConfirmationOpen && (
        <ConfirmationDialog
          isOpen={deleteConfirmationOpen}
          toggleOpen={toggleDeleteConfirmationState}
          onConfirm={handleDeletePackage}
          description={t("hbh.deleteObject.label", {
            object: item?.name,
          })}
          confirmLabel={t("greco.delete")}
          title={`${t("hbh.deletePackage.label")}: ${item?.name} `}
        />
      )}
    </>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
`;
