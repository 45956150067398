import {
  Body1,
  Button,
  CardHeader,
  CardPreview,
  TagGroup,
  Text,
} from "@fluentui/react-components";
import {
  AddFilled,
  ArrowExportFilled,
  ArrowSyncCheckmark24Regular,
  CalendarClockRegular,
  CameraArrowUp24Regular,
  Certificate24Filled,
  ClipboardEditRegular,
  DocumentCheckmarkRegular,
  DocumentDismissRegular,
  Gift24Regular,
  OpenFilled,
  PeopleFilled,
  PersonRegular,
  Ribbon20Regular,
  Ribbon24Filled,
  Rocket24Regular,
} from "@fluentui/react-icons";
import { useSingleAndDoubleClick } from "hooks/useSingleAndDoubleClick";

import { DeleteIcon } from "@fluentui/react-icons-mdl2";
import { ButtonWithTooltip } from "components/ButtonWithTooltip/ButtonWithTooltip";
import { Flex, StyledTooltip } from "components/styled";
import { useAppTheme } from "hooks/useAppTheme";
import { LOCATION_STATES, ROUTES } from "library/constants";
import {
  IBenefitCategory,
  IBenefitImplementation,
  IBenefitPackage,
  IFormattedFile,
} from "library/types";
import {
  extractFileNameFromUrl,
  formatDateString,
  formatFile,
  genUUID,
  getExtensionFromUrl,
} from "library/utils";
import { useCallback, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import PlaceholderImg from "../../../assets/download2.png";
import AgeLimitInfo from "./AgeLimitInfo";
import {
  AddWrap,
  Desc,
  StyledCard,
  StyledCardFooter,
  StyledTag,
  UploadImageWrap,
} from "./style";
import { ManageImagesDialog } from "pages/AdminPage/components/ManageImages/ManageImagesDialog";

type Props = {
  type: "category" | "implementation" | "package";
  benefitCategory?: IBenefitCategory;
  benefitPackage?: IBenefitPackage;
  benefitImplementation?: IBenefitImplementation;
  hasImplementationActions?: boolean;
  onManageDocuments?: (
    item: IBenefitCategory | IBenefitImplementation | IBenefitPackage
  ) => void;
  onEdit?: (
    item: IBenefitCategory | IBenefitImplementation | IBenefitPackage
  ) => void;
  onAddNew?: (
    item: IBenefitCategory | IBenefitImplementation | IBenefitPackage
  ) => void;
  onDelete?: (
    item: IBenefitCategory | IBenefitImplementation | IBenefitPackage
  ) => void;

  onUpdatePhoto?: (
    file: IFormattedFile | null,
    item?: IBenefitCategory | IBenefitImplementation | IBenefitPackage
  ) => void;
  previewMode?: boolean;
  // tags?: [
  //   {
  //     label: string;
  //     value: string;
  //   }
  // ];
};

export const BenefitCardNew = ({
  benefitCategory,
  onEdit,
  onAddNew,
  onDelete,
  onManageDocuments,
  benefitPackage,
  type,
  benefitImplementation,
  hasImplementationActions,
  previewMode,
  onUpdatePhoto,
}: Props) => {
  const { t } = useTranslation();
  const location = useLocation();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const [selectImageOpen, setSelectImageOpen] = useState(false);
  const theme = useAppTheme();
  const navigate = useNavigate();

  const onOpenBenefitImplementation = () => {
    navigate(
      `${ROUTES.COMPANY_PROFILE}/${benefitCategory?.benefitCategoryTag}/${benefitCategory?.countryCode}/${benefitCategory?.clientInternalNumberGos}`,
      {
        state: { [LOCATION_STATES.PREV_PATH_NAME]: location.pathname },
      }
    );
  };

  const onOpenHRBenefitPackages = () => {
    navigate(
      `${ROUTES.HR}/packages/${benefitImplementation?.benefitCategoryTag}/${benefitImplementation?.benefitImplementationTag}/${benefitImplementation?.countryCode}/${benefitImplementation?.clientInternalNumberGos}`,
      {
        state: { [LOCATION_STATES.PREV_PATH_NAME]: location.pathname },
      }
    );
  };

  const onOpenBenefitPackages = () => {
    navigate(
      `${ROUTES.COMPANY_PROFILE}/${benefitImplementation?.benefitCategoryTag}/${benefitImplementation?.benefitImplementationTag}/${benefitImplementation?.countryCode}/${benefitImplementation?.clientInternalNumberGos}`,
      {
        state: { [LOCATION_STATES.PREV_PATH_NAME]: location.pathname },
      }
    );
  };

  const onOpenBenefitPackageEmployeesImplementation = () => {
    navigate(
      `${ROUTES.HR}/employees/${benefitPackage?.benefitPackageTag}/${benefitPackage?.benefitCategoryTag}/${benefitPackage?.benefitImplementationTag}/${benefitPackage?.countryCode}/${benefitPackage?.clientInternalNumberGos}`,
      {
        state: { [LOCATION_STATES.PREV_PATH_NAME]: location.pathname },
      }
    );
  };

  const handleSelectImageOpenState = useCallback((state) => {
    setSelectImageOpen(state);
  }, []);

  const handleUpdatePhoto = async (_, image) => {
    const fileName = extractFileNameFromUrl(image);
    const imageType = getExtensionFromUrl(image);
    const formattedFile = {
      name: fileName,
      base64String: "",
      path: image,
      type: imageType,
    };

    const item = (() => {
      if (type === "category") return benefitCategory;
      if (type === "implementation") return benefitImplementation;
      if (type === "package") return benefitPackage;
      return;
    })();

    onUpdatePhoto && onUpdatePhoto(formattedFile, item);
  };

  const config = useMemo(() => {
    let item;
    let showOpenButton = false;
    let showAddButton = false;
    let openAction;
    let openIcon = <OpenFilled />;
    let addNewAction;
    let deleteAction;
    let editAction;
    let manageDocumentsAction;
    let openActionLabel = "";
    let addNewLabel = "";
    let autoEnrollmentIcon;
    let isUpgradeableIcon;
    let requiresEnrollmentApprovalIcon;
    let requiresCancellationApprovalIcon;
    let tagGroup: {
      label: string;
      value?: string;
      icon?: JSX.Element;
    }[] = [];
    let TitleIcon = Certificate24Filled;

    if (type === "category") {
      item = benefitCategory;
      showOpenButton = !!hasImplementationActions;
      showAddButton = !!hasImplementationActions && !previewMode;
      openAction = hasImplementationActions
        ? onOpenBenefitImplementation
        : () => undefined;
      openActionLabel = t("hbh.openBenefits.label");
      addNewLabel = t("hbh.addNewImplementation.label");
      editAction = () => {
        onEdit && onEdit(benefitCategory as IBenefitCategory);
      };
      addNewAction = () => {
        onAddNew && onAddNew(benefitCategory as IBenefitCategory);
      };
      deleteAction = () => {
        onDelete && onDelete(benefitCategory as IBenefitCategory);
      };
      manageDocumentsAction = () => {
        onManageDocuments &&
          onManageDocuments(benefitCategory as IBenefitCategory);
      };
      TitleIcon = Certificate24Filled;
      tagGroup = hasImplementationActions
        ? [
            {
              label: "",
              value: "",
              icon: (
                <ButtonWithTooltip
                  tooltipContent={t("hbh.implementedBenefitsNo.label")}
                  icon={<Ribbon20Regular />}
                >
                  {benefitCategory?.implementationsCount?.toString()}
                </ButtonWithTooltip>
              ),
            },
          ]
        : [];
    }
    if (type === "implementation") {
      item = benefitImplementation;
      showOpenButton = true;
      showAddButton = !!onAddNew && !previewMode;

      openAction = location.pathname.includes(ROUTES.HR)
        ? onOpenHRBenefitPackages
        : onOpenBenefitPackages;
      openActionLabel = t("hbh.openPackages.label");
      addNewLabel = t("hbh.addNewPackage.label");

      editAction = () => {
        onEdit && onEdit(benefitImplementation as IBenefitImplementation);
      };
      addNewAction = () => {
        onAddNew && onAddNew(benefitImplementation as IBenefitImplementation);
      };
      deleteAction = () => {
        onDelete && onDelete(benefitImplementation as IBenefitImplementation);
      };
      manageDocumentsAction = () => {
        onManageDocuments &&
          onManageDocuments(benefitImplementation as IBenefitImplementation);
      };
      TitleIcon = Ribbon24Filled;

      tagGroup = [
        {
          label: "",
          value: "",
          icon: (
            <ButtonWithTooltip
              tooltipContent={`${t("hbh.packagesNo.label")} - ${
                benefitImplementation?.packagesCount
              }`}
              icon={<Gift24Regular />}
            >
              {`${benefitImplementation?.packagesCount}`}
            </ButtonWithTooltip>
          ),
        },
        {
          label: "",
          value: "",
          icon: (
            <ButtonWithTooltip
              tooltipContent={`${t("hbh.validFrom.label")}: ${formatDateString(
                benefitImplementation?.validFrom
              )} - ${t("hbh.validTo.label")}: ${formatDateString(
                benefitImplementation?.validTo
              )}`}
              icon={<CalendarClockRegular />}
            >
              {`${formatDateString(
                benefitImplementation?.validFrom
              )} - ${formatDateString(benefitImplementation?.validTo)}`}
            </ButtonWithTooltip>
          ),
        },
      ];
    }
    if (type === "package") {
      item = benefitPackage;
      showOpenButton = false;
      showAddButton = false;

      if (location.pathname.includes(ROUTES.HR)) {
        showOpenButton = true;
        openAction = onOpenBenefitPackageEmployeesImplementation;
        openActionLabel = t("hbh.openEmployees.label");
        openIcon = <PeopleFilled />;
      }
      editAction = () => {
        onEdit && onEdit(benefitPackage as IBenefitPackage);
      };
      manageDocumentsAction = () => {
        onManageDocuments &&
          onManageDocuments(benefitPackage as IBenefitPackage);
      };
      addNewAction = () => {
        onAddNew && onAddNew(benefitPackage as IBenefitPackage);
      };
      deleteAction = () => {
        onDelete && onDelete(benefitPackage as IBenefitPackage);
      };
      TitleIcon = Gift24Regular;
      autoEnrollmentIcon = benefitPackage?.isAutoEnrollment ? (
        <ButtonWithTooltip
          tooltipContent={t("hbh.packageHasAutoenrollment.label")}
          icon={
            <ArrowSyncCheckmark24Regular
              style={{
                color: theme.palette.green,
              }}
            />
          }
        />
      ) : null;
      isUpgradeableIcon = benefitPackage?.isUpgradeable ? (
        <ButtonWithTooltip
          tooltipContent={t("hbh.isUpgradeableExplanation.label")}
          icon={
            <Rocket24Regular
              style={{
                color: theme.palette.green,
              }}
            />
          }
        />
      ) : null;
      requiresEnrollmentApprovalIcon =
        benefitPackage?.requiresEnrollmentApproval ? (
          <ButtonWithTooltip
            tooltipContent={t("hbh.requiresEnrollmentApproval.label")}
            icon={
              <DocumentCheckmarkRegular
                style={{
                  color: theme.palette.greenDark,
                }}
              />
            }
          />
        ) : null;
      requiresCancellationApprovalIcon =
        benefitPackage?.requiresCancellationApproval ? (
          <ButtonWithTooltip
            tooltipContent={t("hbh.requiresCancellationApproval.label")}
            icon={
              <DocumentDismissRegular
                style={{
                  color: theme.palette.redDark,
                }}
              />
            }
          />
        ) : null;

      tagGroup = [
        {
          label: "",
          value: "",
          icon: (
            <ButtonWithTooltip
              tooltipContent={t("hbh.numberOfEnrolledEligibles.label")}
              icon={<PersonRegular />}
            >
              {`${benefitPackage?.enrollmentsCount}/${benefitPackage?.eligibilitiesCount}`}
            </ButtonWithTooltip>
          ),
        },
      ];
    }
    return {
      item,
      showOpenButton,
      openAction,
      openIcon,
      editAction,
      openActionLabel,
      TitleIcon,
      manageDocumentsAction,
      tagGroup,
      addNewAction,
      addNewLabel,
      showAddButton,
      autoEnrollmentIcon,
      deleteAction,
      isUpgradeableIcon,
      requiresEnrollmentApprovalIcon,
      requiresCancellationApprovalIcon,
    };
  }, [
    benefitCategory,
    benefitImplementation,
    benefitPackage,
    hasImplementationActions,
    theme.palette.green,
    type,
  ]);

  const onImageClick = useSingleAndDoubleClick(
    () => {
      return;
    },
    () => {
      if (!config.openAction) return;
      config.openAction();
    }
  );

  const showActionSection =
    (hasImplementationActions ||
      config.editAction ||
      config.autoEnrollmentIcon ||
      config.manageDocumentsAction ||
      config.editAction) &&
    !previewMode;

  return (
    <>
      <StyledCard>
        <CardPreview
          style={{
            height: "250px",
            position: "relative",
            margin: 0,
            background: theme.palette.white,
          }}
        >
          {config.showAddButton ? (
            <AddWrap>
              <ButtonWithTooltip
                tooltipContent={config.addNewLabel}
                handleClick={config.addNewAction}
                icon={<AddFilled />}
              />
            </AddWrap>
          ) : null}
          <img
            src={config.item?.backgroundPicturePath || PlaceholderImg}
            alt={config.item?.name}
            onClick={onImageClick}
            loading="lazy"
            style={{
              width: "100%",
              cursor: "pointer",
              objectFit: "scale-down",
              // objectFit: "cover",
              // objectFit: "fill",
              // height: "250px",
            }}
          />
          {!previewMode && (
            <UploadImageWrap>
              <CameraArrowUp24Regular
                onClick={() => {
                  // if (!fileInputRef.current) return;
                  // fileInputRef.current.click();
                  setSelectImageOpen(true);
                }}
              />

              {/* <input
                ref={fileInputRef}
                onChange={handleUpdatePhoto}
                type="file"
                accept="*"
                hidden
              /> */}
            </UploadImageWrap>
          )}
          {/* {config.autoEnrollmentIcon && (
          <AutoEnrollmentWrap>{config.autoEnrollmentIcon}</AutoEnrollmentWrap>
        )} */}
        </CardPreview>

        <CardHeader
          style={{
            padding: "5px 12px ",
          }}
          image={
            <config.TitleIcon
              style={{
                color: theme.palette.yellowDark,
              }}
            />
          }
          header={
            <Body1>
              <StyledTooltip
                content={config.item?.name || ""}
                relationship="description"
              >
                <Text
                  block
                  truncate
                  weight="bold"
                  style={{
                    maxWidth: "250px",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                  }}
                >
                  {config.item?.name}{" "}
                  <AgeLimitInfo
                    ageLimitLower={config.item?.ageLimitLower}
                    ageLimitUpper={config.item?.ageLimitUpper}
                  />
                </Text>
              </StyledTooltip>
            </Body1>
          }
        />

        <StyledTooltip
          relationship="label"
          content={{
            children: config.item?.description,
          }}
        >
          <Desc>{config.item?.description}</Desc>
        </StyledTooltip>
        {config.tagGroup?.length ? (
          <TagGroup
            aria-label="Simple tag group with Tag"
            role="list"
            style={{
              display: "flex",
              gap: "7px",
              height: "auto",
              padding: "5px",
            }}
          >
            {/* <TagList> */}
            {config.tagGroup.map((tag) => {
              return (
                <StyledTag
                  appearance="filled"
                  role="listitem"
                  key={genUUID()}
                  media={tag.icon}
                  shape="circular"
                >
                  {tag.label && <Text weight="semibold">{tag.label}:</Text>}
                  {tag.value && <Text>{tag.value}</Text>}
                </StyledTag>
              );
            })}
          </TagGroup>
        ) : null}
        {showActionSection && (
          <StyledCardFooter>
            <Flex>
              {onDelete ? (
                <Button
                  size="small"
                  style={{
                    color: theme.palette.redDark,
                  }}
                  appearance="transparent"
                  icon={<DeleteIcon />}
                  onClick={() => {
                    config.deleteAction(config.item);
                  }}
                />
              ) : null}

              {config.autoEnrollmentIcon ?? null}
              {config.isUpgradeableIcon ?? null}
              {config.requiresEnrollmentApprovalIcon ?? null}
              {config.requiresCancellationApprovalIcon ?? null}
            </Flex>
            <div>
              {config.showOpenButton && (
                <ButtonWithTooltip
                  onClick={config.openAction}
                  tooltipContent={config.openActionLabel}
                  icon={config.openIcon}
                />
              )}

              {onManageDocuments && (
                <ButtonWithTooltip
                  onClick={config.manageDocumentsAction}
                  icon={<ArrowExportFilled />}
                  tooltipContent={t("hbh.manageDocuments.label")}
                />
              )}
              {onEdit && (
                <ButtonWithTooltip
                  tooltipContent={t("hbh.edit.label")}
                  handleClick={config.editAction}
                  icon={<ClipboardEditRegular />}
                />
              )}
            </div>
          </StyledCardFooter>
        )}
      </StyledCard>
      {selectImageOpen && (
        <ManageImagesDialog
          onSelectImage={handleUpdatePhoto}
          open={selectImageOpen}
          setOpen={handleSelectImageOpenState}
        />
      )}
    </>
  );
};
