import { OnlyActiveSwitch } from "components/OnlyActiveSwitch/OnlyActiveSwitch";
import { CardListSkeleton } from "components/Skeletons/CardListSkeleton";
import { useRoleType } from "hooks/useRoleType";
import { useSelectedViewTab } from "hooks/useSelectedViewTab";
import { useAtom, useAtomValue } from "jotai";
import { EBenefitTab } from "library/types";
import { ImplementationCardList } from "pages/CompanyBenefitImplementationPage/components/ManageBenefitImplementationsView/ImplementationCardList";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useGetBenefitImplementations } from "state/queries/useGetBenefitImplementations";
import {
  onlyActiveImplementationsState,
  selectedCompanyState,
} from "store/UIHrPage";
import styled from "styled-components";
import { HrImplementationsTable } from "./HrImplementationsTable";
import { TableSkeleton } from "components/Skeletons/TableSkeleton";

export function ImplementationsHrView() {
  const { t } = useTranslation();
  const selectedCompany = useAtomValue(selectedCompanyState);
  const selectedBenefitTab = useSelectedViewTab();

  const roles = useRoleType();
  const isReadOnlyUser = roles?.hasReadOnlyRole;
  const hasHrRole = roles?.hasHrRole;

  const [onlyActiveImplementations, setOnlyActiveImplementation] = useAtom(
    onlyActiveImplementationsState
  );

  const {
    data: implementedBenefits,
    isLoading: implementationLoading,
    isRefetching: implementationRefetching,
  } = useGetBenefitImplementations({
    countryCode: Number(selectedCompany?.countryCode),
    clientInternalNumberGOS: Number(selectedCompany?.clientInternalNumberGos),
    benefitCategoryTag: 0,
    onlyActive: onlyActiveImplementations,
  });

  const onSetOnlyActiveImplementation = useCallback(
    (value) => {
      setOnlyActiveImplementation(value);
    },
    [setOnlyActiveImplementation]
  );

  const loading =
    implementationLoading || !implementedBenefits || implementationRefetching;

  const isListBenefitTabView = selectedBenefitTab === EBenefitTab.list;
  const LoadingSkeleton = isListBenefitTabView ? (
    <TableSkeleton />
  ) : (
    <CardListSkeleton cardNumber={10} />
  );

  return (
    <>
      {loading ? (
        LoadingSkeleton
      ) : (
        <>
          <OnlyActiveSwitch
            value={onlyActiveImplementations}
            setValue={onSetOnlyActiveImplementation}
          />
          <div
            style={{
              marginTop: "10px",
            }}
          >
            {isListBenefitTabView ? (
              <HrImplementationsTable
                implementedBenefits={implementedBenefits}
                previewMode={hasHrRole || isReadOnlyUser}
              />
            ) : (
              <ImplementationCardList
                withPagination={implementedBenefits?.length > 50}
                benefitImplementations={implementedBenefits}
                previewMode={hasHrRole || isReadOnlyUser}
              />
            )}
          </div>
        </>
      )}
    </>
  );
}

const TableWrap = styled.div`
  margin-top: 10px;
  table {
    td:nth-of-type(3),
    th:nth-of-type(3) {
      position: sticky;
      left: 320px;
      z-index: 1;
      background: inherit;
      box-shadow: 16px 0px 32px 0px rgba(5, 54, 69, 0.06);
    }
    .selected-row {
      background-color: ${({ theme }) => theme.palette.themeLighter} !important;
    }
  }
`;
