import {
  AppsListDetailRegular,
  AppsListRegular,
  CardUiRegular,
  ImageRegular,
  ImageStackRegular,
  ImageTableRegular,
} from "@fluentui/react-icons";
import { EImageView, SelectItem } from "library/types";
import { useTranslation } from "react-i18next";

export function useGetImageViewOptions() {
  const { t } = useTranslation();
  const imageViewOptions = [
    {
      text: t("hbh.list.label"),
      value: EImageView.list,
      Icon: <AppsListRegular fontSize={24} />,
    },
    {
      text: t("hbh.details.label"),
      value: EImageView.details,
      Icon: <AppsListDetailRegular fontSize={24} />,
    },
    {
      text: t("hbh.tiles.label"),

      label: "Tiles",
      value: EImageView.tiles,
      Icon: <CardUiRegular fontSize={24} />,
    },
    {
      text: t("hbh.largeImages.label"),

      value: EImageView.largeImages,
      Icon: <ImageRegular fontSize={24} />,
    },
    {
      text: t("hbh.mediumImages.label"),

      value: EImageView.mediumImages,
      Icon: <ImageStackRegular fontSize={24} />,
    },
    {
      text: t("hbh.smallImages.label"),

      value: EImageView.smallImages,
      Icon: <ImageTableRegular fontSize={24} />,
    },
  ] as SelectItem[];

  return imageViewOptions;
}
