import { Text } from "@fluentui/react-components";
import { SquareFilled } from "@fluentui/react-icons";
import { AppSpinner } from "components/AppSpinner/AppSpinner";
import { Flex } from "components/styled";
import { useGetUserAppTargetSetting } from "hooks/useGetUserAppTargetSetting";
import { APP_ID, APP_SETTING_ID } from "library/constants";
import { EAppSettings } from "library/types";
import { useTranslation } from "react-i18next";
import { useAddAppSetting } from "state/queries/useAddAppSetting";
import { useUpdateAppSettings } from "state/queries/useUpdateAppSettings";
import styled from "styled-components";
import { allThemes } from "theme";
import { styledGoldDarkTheme, styledGoldTheme } from "theme/goldTheme";
import { darkStyledTheme, styledTheme } from "theme/mainTheme";
import { darkStyledPurpleTheme, styledPurpleTheme } from "theme/purpleTheme";
import { EThemeMode, EThemeName, IStyledTheme } from "theme/types";

export function ThemeSelector() {
  const { t } = useTranslation();

  const darkModeAppSetting = useGetUserAppTargetSetting({
    targetSettingName: EAppSettings.darkMode,
  });

  const themeAppSetting = useGetUserAppTargetSetting({
    targetSettingName: EAppSettings.theme,
  });
  const selectedThemeName = themeAppSetting?.userAppSettingValue;

  const { mutate: onAddAppSetting, isLoading: addSettingsInProgress } =
    useAddAppSetting();
  const { mutate: onUpdateAppSetting, isLoading: updateSettingsInProgress } =
    useUpdateAppSettings();

  const darkMode = darkModeAppSetting?.userAppSettingValue === "true";

  const mainTheme = darkMode ? darkStyledTheme : styledTheme;
  const purpleTheme = darkMode ? darkStyledPurpleTheme : styledPurpleTheme;
  const goldTheme = darkMode ? styledGoldDarkTheme : styledGoldTheme;

  const theme = allThemes[selectedThemeName || EThemeName.main][
    darkMode ? EThemeMode.dark : EThemeMode.light
  ] as IStyledTheme;

  const onSelectTheme = (theme) => {
    if (themeAppSetting) {
      onUpdateAppSetting({
        payload: [
          {
            ...themeAppSetting,
            userAppSettingValue: theme,
          },
        ] as any,
      });
    } else {
      onAddAppSetting({
        payload: [
          {
            applicationCodeId: APP_ID,
            userAppSettingValue: theme,
            appSettingCodeId: APP_SETTING_ID,
            userAppSettingName: EAppSettings.theme,
            isDefault: false,
          },
        ],
      });
    }
  };

  return (
    <Container>
      {(addSettingsInProgress || updateSettingsInProgress) && <AppSpinner />}
      <Text>{t("hbh.themeSelector.label")}</Text>

      <Flex gap={"10px"}>
        <Wrap
          selectedColor={
            selectedThemeName === EThemeName.main
              ? theme.palette.themePrimary
              : ""
          }
        >
          <SquareFilled
            onClick={() => {
              if (selectedThemeName === EThemeName.main) return;
              onSelectTheme(EThemeName.main);
            }}
            style={{
              color: mainTheme.palette.themePrimary,
              width: "30px",
              height: "30px",
            }}
          />
        </Wrap>
        <Wrap
          selectedColor={
            selectedThemeName === EThemeName.purple
              ? theme.palette.themePrimary
              : ""
          }
        >
          <SquareFilled
            onClick={() => {
              if (selectedThemeName === EThemeName.purple) return;
              onSelectTheme(EThemeName.purple);
            }}
            style={{
              color: purpleTheme.palette.themePrimary,
              width: "30px",
              height: "30px",
            }}
          />
        </Wrap>
        <Wrap
          selectedColor={
            selectedThemeName === EThemeName.gold
              ? theme.palette.themePrimary
              : ""
          }
        >
          <SquareFilled
            onClick={() => {
              if (selectedThemeName === EThemeName.gold) return;

              onSelectTheme(EThemeName.gold);
            }}
            style={{
              color: goldTheme.palette.themePrimary,
              width: "30px",
              height: "30px",
            }}
          />
        </Wrap>
      </Flex>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Wrap = styled.div<{ selectedColor?: string }>`
  border: ${({ theme, selectedColor }) =>
    selectedColor
      ? `2px solid ${selectedColor}`
      : `1px solid ${theme.palette.neutralLight}`};
  cursor: pointer;
  display: flex;
  justify-content: center;
  background: ${({ theme }) => theme.palette.white};
  border-radius: 4px;
`;
