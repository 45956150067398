import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  makeStyles,
} from "@fluentui/react-components";
import { WarningIcon } from "@fluentui/react-icons-mdl2";
import { RemoveButton } from "components/styled";
import { useAppTheme } from "hooks/useAppTheme";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { CSSProperties } from "styled-components";

type Props = {
  isOpen: boolean;
  title: string;
  description: string;
  dismissLabel?: string;
  confirmLabel?: string;
  isDisabled?: boolean;
  onConfirm: (setResult) => void;
  toggleOpen: () => void;
  content?: React.JSX.Element;
  style?: CSSProperties;
};

export const ConfirmationDeleteDialog = ({
  title,
  description,
  isOpen,
  dismissLabel,
  onConfirm,
  confirmLabel,
  isDisabled = false,
  content,
  toggleOpen,
  style = {},
}: Props) => {
  const [operationResult, setOperationResult] = useState<any>(null);
  const [showOperationResult, setShowOperationResult] = useState(false);

  const toggleOpenInternal = () => {
    setOperationResult(null);
    setShowOperationResult(false);
    toggleOpen();
  };

  const theme = useAppTheme();
  const styles = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog open={isOpen}>
      <DialogSurface style={style}>
        <DialogBody>
          <DialogTitle
            style={{
              display: "flex",
              alignItems: "center",
              color: theme.palette.redDark,
              gap: "5px",
            }}
          >
            <WarningIcon />
            {title}
          </DialogTitle>
          <DialogContent className={styles.content}>
            {showOperationResult ? (
              <code
                dangerouslySetInnerHTML={{
                  __html: operationResult,
                }}
              ></code>
            ) : operationResult ? null : (
              <>
                {description}
                {content}
              </>
            )}
          </DialogContent>
          <DialogActions>
            <DialogTrigger disableButtonEnhancement>
              <Button appearance="secondary" onClick={toggleOpenInternal}>
                {dismissLabel || "Close"}
              </Button>
            </DialogTrigger>
            {!showOperationResult && (
              <RemoveButton
                onClick={() => {
                  if (operationResult) {
                    setShowOperationResult(true);
                  } else {
                    onConfirm(setOperationResult);
                  }
                }}
                disabled={isDisabled}
              >
                {operationResult
                  ? t("hbh.dialog.showResults.label")
                  : confirmLabel || "Confirm"}
              </RemoveButton>
            )}
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};
const useStyles = makeStyles({
  body: {
    display: "flex",
    flexDirection: "column",
    width: "550px",
  },
  content: {
    maxHeight: "90vh",
    scrollbarWidth: "thin",
  },
});
