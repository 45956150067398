import { UploadFile } from "components/BenefitCategoryDialog/UploadFile";
import DatePickerField from "components/controls/DatePickerField";
import InputField from "components/controls/InputField";
import { Grid } from "components/styled";
import { subYears } from "date-fns";
import { useFormikContext } from "formik";
import { useAppTheme } from "hooks/useAppTheme";
import { useTranslation } from "react-i18next";

type Props = {
  isDependent?: boolean;
  isAddMode?: boolean;
  needAttentionFields?: Record<string, boolean>;
};

export function MainFields({
  isDependent,
  isAddMode,
  needAttentionFields,
}: Props) {
  const theme = useAppTheme();
  const { t } = useTranslation();
  const { values, setFieldValue, errors } = useFormikContext<FormData>();

  const getInputStyle = (fieldName) => {
    return {
      ...(needAttentionFields?.[fieldName]
        ? {
            background: theme.palette.yellow,
          }
        : {}),
    };
  };
  return (
    <Grid>
      <InputField
        required
        label={t("hbh.firstName.label")}
        name="firstName"
        value={values["firstName"]}
        setFieldValue={setFieldValue}
        error={errors["firstName"]}
        style={getInputStyle("firstName")}
      />
      <InputField
        required
        label={t("hbh.lastName.label")}
        name="lastName"
        value={values["lastName"]}
        setFieldValue={setFieldValue}
        error={errors["lastName"]}
        style={getInputStyle("lastName")}
      />
      <InputField
        label={t("hbh.middleName.label")}
        name="middleName"
        value={values["middleName"]}
        setFieldValue={setFieldValue}
        error={errors["middleName"]}
        style={getInputStyle("middleName")}
      />

      <InputField
        required={isDependent ? false : true}
        label={t("hbh.email.label")}
        // disabled={(() => {
        //   if (isDependent) {
        //     return false;
        //   }
        //   return !isAddMode;
        // })()}
        name="emailAddress"
        value={values["emailAddress"]}
        setFieldValue={setFieldValue}
        error={errors["emailAddress"]}
        style={getInputStyle("emailAddress")}
      />
      <InputField
        required={isDependent ? false : true}
        name="mobilePhoneNumber"
        label={t("hbh.mobilePhoneNumber.label")}
        value={values["mobilePhoneNumber"]}
        setFieldValue={setFieldValue}
        error={errors["mobilePhoneNumber"]}
        style={getInputStyle("mobilePhoneNumber")}
      />

      <InputField
        name="phoneNumber"
        label={t("hbh.phone.label")}
        value={values["phoneNumber"]}
        setFieldValue={setFieldValue}
        error={errors["phoneNumber"]}
        style={getInputStyle("phoneNumber")}
      />

      {isDependent ? null : (
        <DatePickerField
          required
          name="dateOfEmployment"
          label={t("hbh.employmentDate.label")}
          value={values["dateOfEmployment"]}
          setFieldValue={setFieldValue}
          error={errors["dateOfEmployment"]}
          style={getInputStyle("dateOfEmployment")}
        />
      )}
      <DatePickerField
        required
        maxDate={isDependent ? undefined : subYears(new Date(), 16)}
        name="dateOfBirth"
        value={values["dateOfBirth"]}
        setFieldValue={setFieldValue}
        label={t("hbh.dateOfBirth.label")}
        error={errors["dateOfBirth"]}
        style={getInputStyle("dateOfBirth")}
      />
      <UploadFile
        key={
          values[isDependent ? "dependentCoverPhoto" : "employeeCoverPhoto"]
            ?.path
        }
        setFieldValue={setFieldValue}
        fieldName={isDependent ? "dependentCoverPhoto" : "employeeCoverPhoto"}
        defaultValue={
          values[isDependent ? "dependentCoverPhoto" : "employeeCoverPhoto"]
        }
        label={t("hbh.chooseCoverPicture.label")}
      />
    </Grid>
  );
}
