import styled from "styled-components";

import {
  Button,
  Dialog,
  DialogActions,
  DialogSurface,
  DialogTrigger,
} from "@fluentui/react-components";
import parse from "html-react-parser";
import { devices } from "library/constants";
import { useCallback, useState } from "react";

import { Dismiss24Regular, EyeRegular } from "@fluentui/react-icons";

type Props = {
  html: string;
};

export function ClientLandingPagePreview({ html }: Props) {
  const [previewLandingPage, setPreviewLandingPage] = useState(false);

  const handleOpenDialog = useCallback(() => {
    setPreviewLandingPage(true);
  }, []);

  const options = {
    replace({ attribs, children }) {
      if (!attribs) {
        return;
      }

      if (attribs.src) {
        return (
          <img
            src={attribs.src}
            style={{
              maxWidth: "100%",
            }}
            alt="some-text"
          />
        );
      }
    },
  } as any;

  return (
    <Dialog
      modalType="alert"
      open={previewLandingPage}
      onOpenChange={(event, data) => setPreviewLandingPage(data.open)}
    >
      <DialogTrigger>
        <Button
          icon={<EyeRegular />}
          appearance="transparent"
          onClick={handleOpenDialog}
        >
          Preview
        </Button>
      </DialogTrigger>

      <StyledDialogSurface
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Content className="ql-editor">{parse(html)}</Content>
        <DialogActions>
          <DialogTrigger>
            <Button icon={<Dismiss24Regular />} appearance="secondary">
              Close
            </Button>
          </DialogTrigger>
        </DialogActions>
      </StyledDialogSurface>
    </Dialog>
  );
}

const Content = styled.div`
  max-width: 900px;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 20px;
  padding-top: 5vh;

  @media only screen and ${devices.md} {
    padding-top: 10vh;
  }
`;

const StyledDialogSurface = styled(DialogSurface)`
  margin-left: 10px;
  margin-right: 10px;
  width: 95%;
  min-width: 95%;
  max-height: 98vh;
  padding: 15px;
  overflow-y: auto;
  @media only screen and ${devices.md} {
    width: 1200px;
    min-width: 1200px;
    padding: 24px;
    margin-left: auto;
    margin-right: auto;
  }
`;
