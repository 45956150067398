import useNotificationHandler from "hooks/useNotificationHandler";
import { MUTATION_KEYS } from "library/shared";
import { useMutation } from "react-query";
import api from "service/api";
import { useInvalidateUserAppSettings } from "./useGetUserAppSettings";

type Params = {
  payload: {
    emailList: string[];
    countryCode: number;
    clientInternalNumberGos: number;
  };
};

const resendEmployeeInvitation = async ({ payload }: Params) => {
  const response = await api.patch(
    `/employee/resendinvitationemail/${payload.countryCode}/${payload.clientInternalNumberGos}`,
    payload.emailList
  );

  return response.data as string;
};

export function useResendEmployeeInvitation(refetch = true) {
  const invalidateUserAppSettings = useInvalidateUserAppSettings();
  const { handleAddAxiosErrorNotification } = useNotificationHandler();

  return useMutation(resendEmployeeInvitation, {
    mutationKey: MUTATION_KEYS.resendEmployeeInvitation,
    onSuccess: () => {
      if (refetch) {
        invalidateUserAppSettings();
      }
    },
    onError: (error) => {
      // handle error
      handleAddAxiosErrorNotification(error);
    },
  });
}
