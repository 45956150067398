import { Divider } from "@fluentui/react-components";
import { TableSkeleton } from "components/Skeletons/TableSkeleton";
import { useAtomValue } from "jotai";
import { devices } from "library/constants";
import { IUserAssignment } from "library/types";
import { useCallback, useState } from "react";
import { useGetUserAssignments } from "state/queries/useGetUserAssignments";
import {
  searchUserInputOptionAtom,
  searchUserInputValueAtom,
  selectedCompanyState,
} from "store/UIHrPage";
import styled from "styled-components";
import { ManageUserAssignment } from "./ManageUserAssignment";
import { SelectUser } from "./SelectUser";
import { UserAssignmentList } from "./UserAssignmentList";

export function UserAssignments() {
  const selectedCompany = useAtomValue(selectedCompanyState);
  const [selectedUser, setSelectedUser] = useState<IUserAssignment | null>(
    null
  );
  const searchUserOption = useAtomValue(searchUserInputOptionAtom);

  const searchUserInputValue = useAtomValue(searchUserInputValueAtom);

  const { data: userAssignmentList, isRefetching } = useGetUserAssignments({
    countryCode: selectedCompany?.countryCode || null,
    clientInternalNumberGOS: selectedCompany?.clientInternalNumberGos || "",
    enabled: !!selectedCompany,
  });

  const onSelectUser = useCallback((user) => {
    setSelectedUser(user);
  }, []);

  if (!userAssignmentList || isRefetching)
    return <TableSkeleton rowItems={10} />;

  const filteredUserAssignmentList = userAssignmentList.filter((item) => {
    return item.userName
      ?.toLowerCase()
      .includes(
        searchUserOption?.text?.toLowerCase() ||
          searchUserInputValue.toLowerCase() ||
          ""
      );
  });
  return (
    <div>
      <AddNewUserWrap>
        <SelectUser
          onSelectUser={onSelectUser}
          selectedUser={selectedUser}
          userAssignmentList={userAssignmentList}
        />
        {selectedUser && (
          <ManageUserAssignment
            onSelectUser={onSelectUser}
            selectedUser={selectedUser}
          />
        )}
      </AddNewUserWrap>
      <Divider />

      <UserAssignmentList data={filteredUserAssignmentList} />
    </div>
  );
}

const AddNewUserWrap = styled("div")`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;

  @media only screen and ${devices.md} {
    flex-direction: row;
  }
`;
