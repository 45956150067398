import { Text } from "@fluentui/react-components";
import { EditableTable } from "components/EditableTable/EditableTable";
import { columnNames } from "library/constants";
import { IBenefitCategory, IBenefitImplementation } from "library/types";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import { useRoleType } from "hooks/useRoleType";
import {
  getImplementedBenefitsColumnsDef,
  defaultColumnSizingOptions,
} from "components/columns/implementationColumnsConfig";

type Props = {
  benefitCategory: IBenefitCategory;
  implementedBenefits: IBenefitImplementation[];
};

export function ImplementedBenefits({
  implementedBenefits,
  benefitCategory,
}: Props) {
  const { t } = useTranslation();
  const params = useParams();
  const categoryId = params.categoryId;
  const countryCode = params.countryCode;
  const clientInternalNumberGOS = params.clientInternalNumberGOS;

  const roles = useRoleType();
  const isReadOnlyUser = roles?.hasReadOnlyRole;

  const columns = getImplementedBenefitsColumnsDef({
    countryCode: Number(countryCode),
    clientInternalNumberGOS: Number(clientInternalNumberGOS),
    benefitCategoryTag: Number(categoryId),
    benefitCategory: benefitCategory,
    hasSelection: true,
    isReadOnlyUser,
    t,
  });

  const benefitImplementationsTableItems = useMemo(() => {
    return implementedBenefits
      ?.map((benefit) => {
        return {
          ...benefit,
          coverPhoto: {
            name: benefit.backgroundPicturePath,
            base64String: benefit.backgroundPictureContent,
            type: benefit.backgroundPictureMimeType,
          },
          // benefitCategoryName: benefitCategory?.name,
          id: benefit.benefitImplementationTag,
          defaultState: {
            ...benefit,
            coverPhoto: {
              name: benefit.backgroundPicturePath,
              base64String: benefit.backgroundPictureContent,
              type: benefit.backgroundPictureMimeType,
            },
            // benefitCategoryName: benefitCategory?.name,
            id: benefit.benefitImplementationTag,
          },
        };
      })
      .sort(
        (a, b) =>
          Number(a.benefitImplementationTag) -
          Number(b.benefitImplementationTag)
      );
  }, [implementedBenefits]);

  return (
    <div>
      {implementedBenefits?.length ? (
        <TableWrap>
          <EditableTable
            defaultColumnSizingOptions={defaultColumnSizingOptions}
            defaultItems={benefitImplementationsTableItems}
            columns={columns}
            columnNames={columnNames}
            selectionMode={"single"}
          />
        </TableWrap>
      ) : (
        <Text>
          {t("hbh.noImplementedBenefits.label", {
            category: benefitCategory?.name,
          })}
        </Text>
      )}
    </div>
  );
}

const TableWrap = styled.div`
  margin-top: 10px;
  table {
    td:nth-of-type(3),
    th:nth-of-type(3) {
      position: sticky;
      left: 320px;
      z-index: 1;
      background: inherit;
      box-shadow: 16px 0px 32px 0px rgba(5, 54, 69, 0.06);
    }
    .selected-row {
      background-color: ${({ theme }) => theme.palette.themeLighter} !important;
    }
  }
`;
