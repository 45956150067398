import { useAtomValue } from "jotai";
import { useMemo } from "react";
import { useGetBenefitImplementations } from "state/queries/useGetBenefitImplementations";
import {
  onlyActiveImplementationsState,
  selectedCompanyState,
} from "store/UIHrPage";

export function useAllUserDocumentsForChatbot(): string[] | null {
  const selectedCompany = useAtomValue(selectedCompanyState);

  const onlyActiveImplementations = useAtomValue(
    onlyActiveImplementationsState
  );
  const {
    data: implementedBenefits,
    isLoading: implementationLoading,
    isRefetching: implementationRefetching,
  } = useGetBenefitImplementations({
    enabled: !!selectedCompany,
    countryCode: Number(selectedCompany?.countryCode),
    clientInternalNumberGOS: Number(selectedCompany?.clientInternalNumberGos),
    benefitCategoryTag: 0,
    onlyActive: onlyActiveImplementations,
  });

  const allDocuments = useMemo(() => {
    const documents = [] as any;
    if (!implementedBenefits || !selectedCompany) return null;
    implementedBenefits.forEach((benefit) => {
      const benefitDocuments = benefit?.attachments || [];
      benefitDocuments.forEach((document) => {
        if (documents.includes(document.attachmentPath)) return;

        documents.push(document.attachmentPath);
      });
    });
    (selectedCompany?.attachments || []).forEach((attachment) => {
      if (documents.includes(attachment.attachmentPath)) return;

      documents.push(attachment.attachmentPath);
    });
    //TODO documents from packages
    return documents;
  }, [implementedBenefits, selectedCompany]);

  if (!selectedCompany || !implementedBenefits) return null;

  return allDocuments;
}
