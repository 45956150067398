import { Button } from "@fluentui/react-components";
import { ArrowClockwiseRegular } from "@fluentui/react-icons";
import { useInvalidateBenefitImplementationPackages } from "state/queries/useGetBenefitImplementationPackages";

export function CompanyPackagesRefreshBtn() {
  const invalidateBenefitImplementationPackages =
    useInvalidateBenefitImplementationPackages();

  const handleRefresh = () => {
    invalidateBenefitImplementationPackages();
  };

  return (
    <Button
      onClick={handleRefresh}
      appearance="outline"
      icon={<ArrowClockwiseRegular />}
    />
  );
}
