import { EditableTable } from "components/EditableTable/EditableTable";
import { useAtomValue, useSetAtom } from "jotai";
import { columnNames } from "library/constants";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  companyEmployeeViewsAtom,
  selectedTableViewEmployeesAtom,
} from "store/UIHrPage";
import styled from "styled-components";
import {
  defaultColumnSizingOptions,
  getEnrolledEmployeesTableColumns,
} from "./columns";
import { IEmployeeView } from "library/types";
import { SubrowComponent } from "./SubrowComponent/SubrowComponent";

type Props = {
  companyEmployees: IEmployeeView[] | undefined;
};

export function EmployeesTable({ companyEmployees }: Props) {
  const { t } = useTranslation();
  const setSelectedEmployees = useSetAtom(selectedTableViewEmployeesAtom);

  const tableItems = useMemo(() => {
    if (!companyEmployees) return [];
    return companyEmployees
      ?.map((item) => {
        return {
          ...item,
          id: item.employeeLoginEmailAddress,
          // expanded: true,
          showSubrow: item.enrollments?.length || item.dependentList?.length,
        };
      })
      .sort((a, b) => (a?.firstName || "").localeCompare(b?.firstName || ""));
  }, [companyEmployees]);

  const columns = getEnrolledEmployeesTableColumns({
    t,
  });

  const handleAction = useCallback((data) => {
    const selectedRows = data
      .filter((item: any) => {
        return item.selected;
      })
      .map((row) => row.item);
    setSelectedEmployees(selectedRows);
  }, []);

  return (
    <div>
      <TableWrap>
        <EditableTable
          withPagination={false}
          SubrowComponent={SubrowComponent}
          defaultColumnSizingOptions={defaultColumnSizingOptions}
          defaultItems={tableItems}
          columns={columns}
          columnNames={columnNames}
          tableDataAction={handleAction}
          onRowClick={(row) => {
            // if (selectedItem?.id !== row?.id) {
            row.updateAllState({
              expanded: false,
            });
            row.updateState({
              ...row,
              expanded: !row.expanded,
              selected: !row.selected,
            });
            // setSelectedItem(row);
            // }
          }}
          selectionMode={"multiselect"}
        />
      </TableWrap>
    </div>
  );
}

const TableWrap = styled.div`
  margin-top: 10px;
  table {
    td:nth-of-type(2),
    th:nth-of-type(2) {
      position: sticky;
      left: 60px;
      z-index: 1;
      background: inherit;
      box-shadow: 16px 0px 32px 0px rgba(5, 54, 69, 0.06);
    }
    td:nth-of-type(3),
    th:nth-of-type(3) {
      position: sticky;
      left: 370px;
      z-index: 1;
      background: inherit;
      box-shadow: 16px 0px 32px 0px rgba(5, 54, 69, 0.06);
    }
    .selected-row:not(.sub-component-row) {
      background-color: ${({ theme }) => theme.palette.themeLighter} !important;
    }
    .sub-component-row {
      background-color: ${({ theme }) => theme.palette.white} !important;
    }

    .with-subrow {
      border: 3px solid ${(props) => props.theme.palette.themeLighter};
      border-bottom: none;
      background-color: ${({ theme }) => theme.palette.white} !important;
    }

    // .selected-row {
    //   background-color: ${({ theme }) =>
      theme.palette.themeLighter} !important;
    // }
  }
`;
