import { SearchBoxField } from "components/controls/SearchBoxField";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { Text } from "@fluentui/react-components";

import {
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  Tooltip,
} from "@fluentui/react-components";
import { useGetImageViewOptions } from "hooks/useGetImageViewOptions";
import { useAtom } from "jotai";
import { devices } from "library/constants";
import { selectImagesViewState } from "store/UIAdminPage";

type Props = {
  disabled: boolean;
  searchValue: string;
  handleSearch: (value: string) => void;
};

export function ManageImagesToolbar({
  disabled,
  searchValue,
  handleSearch,
}: Props) {
  const { t } = useTranslation();
  const [viewValue, setViewValue] = useAtom(selectImagesViewState);
  const imageViewOptions = useGetImageViewOptions();
  const imageViewValue = viewValue || imageViewOptions[0];

  return (
    <Container>
      <SearchBoxField
        style={{
          // ...(!showSearchFilter && { display: "none" }),
          height: "fit-content",
        }}
        disabled={disabled}
        value={searchValue}
        appearance="outline"
        placeholder={t("greco.form.searchPlaceholder")}
        onSearch={handleSearch}
        width={"300px"}
      />

      <Menu>
        <MenuTrigger disableButtonEnhancement>
          <Tooltip content="Small with  icon only" relationship="label">
            <MenuButton
              icon={imageViewValue?.Icon}
              size="medium"
              appearance="secondary"
            >
              <MenuButtonText>{imageViewValue?.text}</MenuButtonText>
            </MenuButton>
          </Tooltip>
        </MenuTrigger>

        <MenuPopover>
          <MenuList>
            {imageViewOptions.map((view) => {
              return (
                <MenuItem key={view.value} onClick={() => setViewValue(view)}>
                  <MenuItemContent
                    $selected={view.value === imageViewValue?.value}
                  >
                    {view.Icon}
                    <Text weight="semibold">{view.text}</Text>
                  </MenuItemContent>
                </MenuItem>
              );
            })}
          </MenuList>
        </MenuPopover>
      </Menu>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  width: 100%;
  // justify-content: space-between;
  gap: 10px;
`;
const MenuButtonText = styled(Text)`
  display: none;
  @media only screen and ${devices.md} {
    display: block;
  }
`;
const MenuItemContent = styled.div<{ $selected?: boolean }>`
  display: flex;
  align-items: center;
  gap: 10px;
  color: ${({ $selected, theme }) =>
    $selected ? theme.palette.themePrimary : "inherit"};
`;
