import { PageSkeleton } from "components/Skeletons/PageSkeleton";
import { PageContent } from "components/styled";
import { useTaxonomyOptions } from "hooks/useTaxonomyOptions";
import { useAtom, useAtomValue, useSetAtom } from "jotai";
import { ETaxonomy } from "library/types";
import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetBenefitImplementation } from "state/queries/useGetBenefitImplementation";
import { useGetBenefitImplementationPackages } from "state/queries/useGetBenefitImplementationPackages";
import { getClients } from "state/queries/useGetClients";
import {
  filteredBenefitImplementationState,
  filteredPackagesState,
  packagesSearchFilterValueState,
  selectedCompanyState,
  selectedCountryState,
} from "store/UICompanyPage";
import { CompanyPackagesToolbar } from "./components/CompanyPackagesToolbar/CompanyPackagesToolbar";
import { EmployeeView } from "pages/HrPage/components/ManagePageView/EmployeesView/EmployeeView";
import {
  companyEmployeeViewsAtom,
  onlyActiveImplementationsState,
  selectedCompanyState as selectedCompanyStateHr,
} from "store/UIHrPage";
import { CompanyPackageEmployeesSubheader } from "./components/CompanyPackageEmployeesSubheader/CompanyPackageEmployeesSubheader";
import { useGetCompanyEmployeeViews } from "state/queries/useGetCompanyEmployeeViews";
import useEmployeeData from "hooks/useEmployeeData";
import { useGetBenefitImplementations } from "state/queries/useGetBenefitImplementations";

export function CompanyPackageEmployeesPage() {
  const params = useParams();
  const implementationId = params.implementationId;
  const categoryId = params.categoryId;
  const packageId = params.packageId;
  const countryCode = params.countryCode;
  const clientInternalNumberGOS = params.clientInternalNumberGOS;

  const countryOptions = useTaxonomyOptions(ETaxonomy.Country);

  const setSelectedCompany = useSetAtom(selectedCompanyState);
  const setSelectedCompanyHr = useSetAtom(selectedCompanyStateHr);
  const setSelectedCountry = useSetAtom(selectedCountryState);

  const [searchFilterValue, setSearchFilterValue] = useAtom(
    packagesSearchFilterValueState
  );

  const [filteredPackages, setFilteredPackages] = useAtom(
    filteredPackagesState
  );

  const selectedPackage = filteredPackages?.find(
    (el) => el.benefitPackageTag === Number(packageId)
  );
  const [filteredBenefitImplementation, setFilteredBenefitImplementation] =
    useAtom(filteredBenefitImplementationState);

  const selectedBenefitImplementation = filteredBenefitImplementation?.find(
    (el) => el.benefitImplementationTag === Number(implementationId)
  );
  const [onlyActiveImplementations, setOnlyActiveImplementations] = useAtom(
    onlyActiveImplementationsState
  );

  const { companyEmployees } = useEmployeeData({
    countryCode,
    clientInternalNumberGOS,
    enabled: clientInternalNumberGOS !== undefined,
    onlyActiveImplementations,
    searchEmployeeFilterValue: "",
    selectedTab: "",
  });

  const [onlyEnrolledEmployees, setOnlyEnrolledEmployees] = useState(true);

  const getAndSetSelectedCompany = async () => {
    const clients = await getClients({
      countryCode: countryCode || "",
      letters: clientInternalNumberGOS,
    });
    const company = clients?.find(
      (client) => client.internalNumber === Number(clientInternalNumberGOS)
    );
    setSelectedCompany({
      label: company?.name?.toString(),
      value: company?.id?.toString(),
      ...company,
    });
    setSelectedCompanyHr({
      label: company?.name?.toString(),
      value: company?.id?.toString(),
      countryCode: company?.countryCodeId,
      clientInternalNumberGos: company?.internalNumber,
      ...company,
    });
  };

  useEffect(() => {
    if (countryCode && clientInternalNumberGOS) {
      getAndSetSelectedCompany();
    }
  }, [countryCode, clientInternalNumberGOS]);

  const {
    data: implementedBenefits,
    isLoading: implementationLoading,
    isRefetching: implementationRefetching,
  } = useGetBenefitImplementations({
    countryCode: Number(countryCode),
    clientInternalNumberGOS: Number(clientInternalNumberGOS),
    benefitCategoryTag: Number(categoryId),
    onlyActive: onlyActiveImplementations,
  });

  useEffect(() => {
    if (!implementedBenefits) return;
    setFilteredBenefitImplementation(
      implementedBenefits?.filter((item) =>
        item?.name?.toLowerCase().includes(searchFilterValue)
      )
    );
  }, [
    implementedBenefits,
    searchFilterValue,
    setFilteredBenefitImplementation,
  ]);

  const filteredCompanyEmployees = onlyEnrolledEmployees
    ? companyEmployees
        ?.filter((employee) => {
          return (
            employee.enrollments?.some(
              (implementation) =>
                implementation.benefitPackageTag === Number(packageId)
            ) ||
            employee.dependentList?.some((dependent) => {
              return dependent.enrollments?.some(
                (implementation) =>
                  implementation.benefitPackageTag === Number(packageId)
              );
            })
          );
        })
        .map((employee) => {
          return {
            ...employee,
            enrollments: employee.enrollments?.filter(
              (implementation) =>
                implementation.benefitPackageTag === Number(packageId)
            ),
            dependentList: employee.dependentList?.map((dependent) => {
              return {
                ...dependent,
                enrollments: dependent.enrollments?.filter(
                  (implementation) =>
                    implementation.benefitPackageTag === Number(packageId)
                ),
              };
            }),
          };
        })
    : companyEmployees?.map((employee) => {
        return {
          ...employee,
          enrollments: employee.enrollments?.filter(
            (implementation) =>
              implementation.benefitPackageTag === Number(packageId)
          ),
          dependentList: employee.dependentList?.map((dependent) => {
            return {
              ...dependent,
              enrollments: dependent.enrollments?.filter(
                (implementation) =>
                  implementation.benefitPackageTag === Number(packageId)
              ),
            };
          }),
        };
      });

  const selectedCountry = countryOptions.find(
    (option) => option.value === Number(countryCode)
  );
  const {
    data: benefitImplementation,
    isLoading: benefitImplementationLoading,
  } = useGetBenefitImplementation({
    benefitCategoryTag: Number(categoryId),
    countryCode: Number(countryCode),
    benefitImplementationTag: Number(implementationId),
    clientInternalNumberGOS: Number(clientInternalNumberGOS),
  });

  const { data: packageList, isLoading: packagesLoading } =
    useGetBenefitImplementationPackages({
      countryCode: Number(countryCode),

      clientInternalNumberGOS: Number(clientInternalNumberGOS),
      benefitCategoryTag: Number(categoryId),
      benefitImplementationTag: Number(implementationId),
    });

  const handleSearchFilterValue = useCallback(
    (value) => {
      setSearchFilterValue(value);
    },
    [setSearchFilterValue]
  );
  useEffect(() => {
    if (selectedCountry) {
      setSelectedCountry(selectedCountry);
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (!packageList) return;
    setFilteredPackages(
      packageList?.filter((item) =>
        item?.name?.toLowerCase().includes(searchFilterValue)
      )
    );
  }, [packageList, searchFilterValue, setFilteredPackages]);

  const isLoading = benefitImplementationLoading || packagesLoading;

  if (isLoading || !benefitImplementation || !packageList)
    return <PageSkeleton />;

  return (
    <>
      <CompanyPackageEmployeesSubheader
        disabled={!packageList?.length}
        items={filteredCompanyEmployees || []}
        benefitImplementation={benefitImplementation}
        searchFilterValue={searchFilterValue}
        setSearchFilterValue={handleSearchFilterValue}
      />
      <CompanyPackagesToolbar benefitImplementation={benefitImplementation} />
      <PageContent>
        <h2>
          {selectedBenefitImplementation?.name} &raquo; {selectedPackage?.name}
        </h2>
        <EmployeeView
          onlyActiveImplementations={onlyActiveImplementations}
          setOnlyActiveImplementation={setOnlyActiveImplementations}
          companyEmployees={filteredCompanyEmployees}
          onlyEnrolledEmployees={onlyEnrolledEmployees}
          setOnlyEnrolledEmployees={setOnlyEnrolledEmployees}
        />
      </PageContent>
    </>
  );
}
