import {
  TableCellLayout,
  TableColumnSizingOptions,
  TableSelectionCell,
  createTableColumn,
} from "@fluentui/react-components";
import { columnNames } from "library/constants";
import { EmployeeRowItem } from "library/types";
import { formatDateString } from "library/utils";
import { EmployeeActions } from "./EmployeeActions/EmployeeActions";
import { EmployeeWithDependentsCell } from "./EmployeeWithDependentsCell/EmployeeWithDependentsCell";
import { TFunction } from "i18next";

type IHeaderItem =
  | {
      toggleAllRows: (e: any) => void;
      allRowsSelected: boolean;
      selectionMode: "single" | "multiselect";
      someRowsSelected: boolean;
      toggleAllKeydown: (e: any) => void;
      updateAllState: (e: any) => void;
    }
  | any;

export const getEnrolledEmployeesTableColumns = ({
  t,
}: {
  t: TFunction<"translation", undefined>;
}) => [
  createTableColumn({
    columnId: "select",
    renderHeaderCell: (item: IHeaderItem) => {
      if (item.selectionMode === "multiselect") {
        return (
          <TableSelectionCell
            aria-sort="none"
            data-name="select"
            checked={
              item.allRowsSelected
                ? true
                : item.someRowsSelected
                ? "mixed"
                : false
            }
            onClick={(e) => {
              if (item.selectionMode === "multiselect") {
                item.toggleAllRows(e);
                item.updateAllState({
                  expanded: false,
                });
              }
            }}
            onKeyDown={item.toggleAllKeydown}
            checkboxIndicator={{ "aria-label": "Select all rows " }}
          />
        );
      }
      if (item.selectionMode === "single") {
        return <TableSelectionCell type="radio" invisible />;
      }
      return <></>;
    },
    renderCell: (item: EmployeeRowItem) => {
      if (!item.selectionMode) return <></>;
      if (item.selectionMode === "multiselect")
        return (
          <TableSelectionCell
            onClick={() => {
              item.updateState({
                ...item,
                expanded: item.selected ? true : false,
              });
            }}
            checked={item.selected}
            checkboxIndicator={{ "aria-label": "Select row" }}
          />
        );
      if (item.selectionMode === "single") {
        return (
          <TableSelectionCell
            checked={item.selected}
            type="radio"
            radioIndicator={{ "aria-label": "Select row" }}
          />
        );
      }
    },

    compare: (a, b) => {
      return (a?.selected ? 1 : 0) - (b?.selected ? 1 : 0);
    },
  }),

  createTableColumn({
    columnId: "employees",
    renderHeaderCell: () => <>{t("hbh.name.label")}</>,
    renderCell: (item: EmployeeRowItem) => {
      return <EmployeeWithDependentsCell item={item} />;
    },

    compare: (a, b) => {
      const fullNameA = `${a?.firstName} ${a?.lastName}`;
      const fullNameB = `${b?.firstName} ${b?.lastName}`;
      return (fullNameA || "").localeCompare(fullNameB || "");
    },
  }),
  createTableColumn({
    columnId: "email",
    renderHeaderCell: () => <>{t("hbh.email.label")}</>,
    renderCell: (item: EmployeeRowItem) => {
      const email = item?.employeeLoginEmailAddress;
      return <TableCellLayout truncate>{email}</TableCellLayout>;
    },

    compare: (a, b) => {
      return (a?.employeeLoginEmailAddress || "").localeCompare(
        b?.employeeLoginEmailAddress || ""
      );
    },
  }),
  createTableColumn({
    columnId: "dateOfEmployment",
    renderHeaderCell: () => <>{t("hbh.dateOfEmployment.label")}</>,
    renderCell: (item: EmployeeRowItem) => {
      return (
        <TableCellLayout truncate>
          {formatDateString(item?.dateOfEmployment || "")}
        </TableCellLayout>
      );
    },

    compare: (a, b) => {
      return (a?.dateOfEmployment || "").localeCompare(
        b?.dateOfEmployment || ""
      );
    },
  }),
  createTableColumn({
    columnId: "organizationUnit",
    renderHeaderCell: () => <>{t("hbh.orgUnitId.label")}</>,
    renderCell: (item: EmployeeRowItem) => {
      return <TableCellLayout truncate>{item.orgUnitId}</TableCellLayout>;
    },

    compare: (a, b) => {
      return (a?.dateOfEmployment || "").localeCompare(
        b?.dateOfEmployment || ""
      );
    },
  }),
  createTableColumn({
    columnId: "dependents",
    renderHeaderCell: () => <>{t("hbh.dependents.label")}</>,
    renderCell: (item: EmployeeRowItem) => {
      return (
        <TableCellLayout truncate>{item.dependents?.length}</TableCellLayout>
      );
    },

    compare: (a, b) => {
      return (a?.dateOfEmployment || "").localeCompare(
        b?.dateOfEmployment || ""
      );
    },
  }),
  createTableColumn({
    columnId: "enrollments",
    renderHeaderCell: () => <>{t("hbh.enrollments.label")}</>,
    renderCell: (item: EmployeeRowItem) => {
      return (
        <TableCellLayout truncate>{item.enrollments?.length} </TableCellLayout>
      );
    },

    compare: (a, b) => {
      return (a?.emailAddress || "").localeCompare(b?.emailAddress || "");
    },
  }),

  createTableColumn({
    columnId: "actionsCell",
    renderHeaderCell: () => <>{t("hbh.actions.label")}</>,
    renderCell: (item: EmployeeRowItem) => {
      return <EmployeeActions employeeData={item} />;
    },

    // compare: (a, b) => {
    //   return a.enrollmentProcessNeeded - b.enrollmentProcessNeeded;
    // },
  }),
];

export const defaultColumnSizingOptions: TableColumnSizingOptions = {
  id: {
    idealWidth: 40,
    minWidth: 40,
    defaultWidth: 40,
  },
  select: {
    idealWidth: 40,
    minWidth: 40,
    defaultWidth: 40,
    autoFitColumns: false,
  },
  employees: {
    idealWidth: 300,
    minWidth: 300,
    defaultWidth: 300,
  },
  email: {
    idealWidth: 200,
    minWidth: 200,
    defaultWidth: 200,
  },
  dateOfEmployment: {
    idealWidth: 150,
    minWidth: 150,
    defaultWidth: 150,
  },
  organizationUnit: {
    idealWidth: 170,
    minWidth: 170,
    defaultWidth: 170,
  },
  dependents: {
    idealWidth: 125,
    minWidth: 125,
    defaultWidth: 125,
  },
  enrollments: {
    idealWidth: 125,
    minWidth: 125,
    defaultWidth: 125,
  },

  actionsCell: {
    minWidth: 95,
    idealWidth: 95,
    defaultWidth: 95,
  },
};
