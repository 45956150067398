import { tokens } from "@fluentui/react-components";
import FormFieldLabel from "components/FormFieldLabel/FormFieldLabel";
import { FormFieldContainer } from "components/styled";
import debounce from "debounce-promise";
import { useFormikContext } from "formik";
import { useAppTheme } from "hooks/useAppTheme";
import { formatDateString } from "library/utils";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import AsyncSelect from "react-select/async";
import { getPolicies } from "state/queries/useGetPolicies";

export function SelectPolicy({ countryCode, clientInternalNumberGos }) {
  const theme = useAppTheme();
  const { t } = useTranslation();
  const { setFieldValue } = useFormikContext();
  // const selectedCompany = useAtomValue(selectedCompanyState);
  const [selectedPolicy, setSelectedPolicy] = useState(null);
  // const selectedCountry = useAtomValue(selectedCountryState);

  const onChange = (option, actionMeta) => {
    setSelectedPolicy(option);

    if (actionMeta.action !== "select-option") return;

    setFieldValue("policyOrContractNumber", option?.policyNumber || "");
    setFieldValue("carrierName", option?.insurerName || "");
    setFieldValue(
      "carrierInternalNumberGos",
      option?.internalInsurerNumber || ""
    );
    setFieldValue("validFrom", option?.validFrom || "");
    setFieldValue("validTo", option?.expiryDate || "");
  };

  return (
    <FormFieldContainer>
      <FormFieldLabel label={t("hbh.selectPolicy.label")} required={false} />

      <AsyncSelect
        cacheOptions
        placeholder={t("greco.form.searchPlaceholder")}
        loadingMessage={() => t("greco.loading")}
        noOptionsMessage={() => t("greco.noResults")}
        // onChange={onChange}
        defaultOptions={false}
        isClearable={true}
        onChange={onChange}
        value={selectedPolicy}
        // value={selectedCompany as any}
        loadOptions={debounce(async (input: string) => {
          const trimmedFilter = input.trim();
          if (trimmedFilter.length === 0) return [];
          const policies = await getPolicies({
            countryCode: countryCode || null,
            letters: trimmedFilter,
            clientInternalNumberGos: clientInternalNumberGos || "",
          });
          console.log(policies, "policies");

          const options = (policies || [])?.map((option) => {
            return {
              label: `${option?.policyName} (${
                option.policyNumber
              }) - ${formatDateString(option.validFrom)} - ${
                formatDateString(option.expiryDate) || "N/A"
              }`,
              value: option?.internalPolicyNumber?.toString(),
              ...option,
            };
          });

          return options;
        }, 500)}
        name={"policy"}
        styles={{
          container: (baseStyles) => ({
            ...baseStyles,
            position: "relative",
          }),
          control: (baseStyles, state) => ({
            ...baseStyles,
            background: "transparent",
            border: "none",
            borderRadius: "0",
            height: "32px !important",
            minHeight: "32px !important",
            borderBottom: state.isFocused
              ? `2px solid ${theme.palette.themePrimary} !important`
              : `1px solid ${tokens.colorNeutralStrokeAccessible} !important`,
            boxShadow: "none",
            ...(state.isDisabled && {
              borderBottom: `1px solid ${tokens.colorNeutralStrokeAccessible} !important`,
            }),
          }),
          menu: (p) => ({
            ...p,
            background: theme.palette.neutralLighter,
          }),
          option: (p, state) => ({
            ...p,
            background:
              state.isSelected || state.isFocused
                ? theme.palette.neutralLighter
                : theme.palette.white,
            "&:hover": {
              background: theme.palette.neutralLighter,
            },
            color: theme.palette.black,
            fontSize: "14px",
            fontWeight: 400,
          }),
          input: (p) => ({
            ...p,
            margin: "4px",
            color: (function () {
              return theme.palette.black;
            })(),
          }),
          valueContainer: (p, state) => ({
            ...p,
            padding: "1px 8px !important",
          }),
          placeholder: (p, state) => ({
            ...p,
            padding: "1px 8px !important",
          }),
          singleValue: (p, state) => ({
            ...p,
            fontSize: "14px",
            marginLeft: "5px",
            fontWeight: 400,
            color: theme.palette.black,
          }),
          dropdownIndicator: (p, state) => ({
            ...p,
            ...(state.isDisabled && { visibility: "hidden" }),
          }),

          indicatorSeparator: (p, state) => ({
            ...p,
            visibility: "hidden",
          }),
          menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
            overflow: "visible",
          }),
        }}
      />
    </FormFieldContainer>
  );
}
