import { tokens } from "@fluentui/react-components";
import FormFieldLabel from "components/FormFieldLabel/FormFieldLabel";
import { FormFieldContainer } from "components/styled";
import debounce from "debounce-promise";
import { useFormikContext } from "formik";
import { useAppTheme } from "hooks/useAppTheme";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import AsyncSelect from "react-select/async";
import { getInsurers } from "state/queries/useGetInsurers";

export function SelectInsurer({ countryCode }) {
  const theme = useAppTheme();
  const { t } = useTranslation();
  const { setFieldValue } = useFormikContext();
  const [selectedInsurer, setSelectedInsurer] = useState(null);

  const onChange = (option, actionMeta) => {
    setSelectedInsurer(option);
    if (actionMeta.action !== "select-option") return;

    setFieldValue("carrierName", option?.name || "");
    setFieldValue("carrierInternalNumberGos", option?.internalNumber || "");
  };

  return (
    <FormFieldContainer>
      <FormFieldLabel label={t("hbh.selectInsurer.label")} required={false} />

      <AsyncSelect
        cacheOptions
        placeholder={t("greco.form.searchPlaceholder")}
        loadingMessage={() => t("greco.loading")}
        noOptionsMessage={() => t("greco.noResults")}
        // onChange={onChange}
        defaultOptions={false}
        isClearable={true}
        onChange={onChange}
        value={selectedInsurer}
        // value={selectedCompany as any}
        loadOptions={debounce(async (input: string) => {
          const trimmedFilter = input.trim();
          if (trimmedFilter.length === 0) return [];
          const insurers = await getInsurers({
            countryCode: countryCode || null,
            letters: trimmedFilter,
          });
          const options = (insurers || [])?.map((option) => {
            return {
              label: option?.name?.toString(),
              value: option?.id?.toString(),
              ...option,
            };
          });

          return options;
        }, 500)}
        name={"insurer"}
        styles={{
          container: (baseStyles) => ({
            ...baseStyles,
            position: "relative",
          }),
          control: (baseStyles, state) => ({
            ...baseStyles,
            background: "transparent",
            border: "none",
            borderRadius: "0",
            height: "32px !important",
            minHeight: "32px !important",
            borderBottom: state.isFocused
              ? `2px solid ${theme.palette.themePrimary} !important`
              : `1px solid ${tokens.colorNeutralStrokeAccessible} !important`,
            boxShadow: "none",
            ...(state.isDisabled && {
              borderBottom: `1px solid ${tokens.colorNeutralStrokeAccessible} !important`,
            }),
          }),
          menu: (p) => ({
            ...p,
            background: theme.palette.neutralLighter,
          }),
          option: (p, state) => ({
            ...p,
            background:
              state.isSelected || state.isFocused
                ? theme.palette.neutralLighter
                : theme.palette.white,
            "&:hover": {
              background: theme.palette.neutralLighter,
            },
            color: theme.palette.black,
            fontSize: "14px",
            fontWeight: 400,
          }),
          input: (p) => ({
            ...p,
            margin: "4px",
            color: (function () {
              return theme.palette.black;
            })(),
          }),
          valueContainer: (p, state) => ({
            ...p,
            padding: "1px 8px !important",
          }),
          placeholder: (p, state) => ({
            ...p,
            padding: "1px 8px !important",
          }),
          singleValue: (p, state) => ({
            ...p,
            fontSize: "14px",
            marginLeft: "5px",
            fontWeight: 400,
            color: theme.palette.black,
          }),
          dropdownIndicator: (p, state) => ({
            ...p,
            ...(state.isDisabled && { visibility: "hidden" }),
          }),

          indicatorSeparator: (p, state) => ({
            ...p,
            visibility: "hidden",
          }),
          menuPortal: (base) => ({
            ...base,
            zIndex: 9999,
            overflow: "visible",
          }),
        }}
      />
    </FormFieldContainer>
  );
}
