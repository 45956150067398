import { SectionsWrapper } from "components/styled";
import { useAtomValue } from "jotai";

import { selectedCompanyState } from "store/UIHrPage";
import BenefitsSection from "./BenefitsSection";
import EmployeeSection from "./EmployeeSection";
import { useIsFetching } from "react-query";
import { QUERY_KEYS } from "library/shared";
import { AppSpinner } from "components/AppSpinner/AppSpinner";

const BulkManagementView = () => {
  const selectedCompany = useAtomValue(selectedCompanyState);

  const isFetchingCompanyEmployeeViews = useIsFetching({
    queryKey: QUERY_KEYS.companyEmployeeViews,
  });

  return (
    <>
      {isFetchingCompanyEmployeeViews ? <AppSpinner /> : null}

      {selectedCompany && (
        <>
          <SectionsWrapper>
            <EmployeeSection />
            <BenefitsSection />
          </SectionsWrapper>
        </>
      )}
    </>
  );
};

export default BulkManagementView;
