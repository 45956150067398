import { Button } from "@fluentui/react-components";
import { PersonEdit24Regular, SendPersonRegular } from "@fluentui/react-icons";
import { ActionButton, StyledTooltip } from "components/styled";
import { useAppTheme } from "hooks/useAppTheme";
import { useRoleType } from "hooks/useRoleType";
import { EmployeeRowItem, IEmployeeView } from "library/types";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useResendEmployeeInvitation } from "state/queries/useResendEmployeeInvitation";
import styled from "styled-components";
import { DeletePackageUser } from "./DeletePackageUser";
import { EmployeeEligibility } from "./EmployeeEligibility/EmployeeEligibility";
import { ManageEmployee } from "./ManageEmployee/ManageEmployee";
import { EmployeeDependentsDialog } from "./ManageEmployee/UpdateEmployeeFields/EmployeeDependentsDialog/EmployeeDependentsDialog";
import { useParams } from "react-router-dom";

type Props = {
  employeeData: EmployeeRowItem;
  dependentData?: IEmployeeView;
  countryCode?: number;
  stopPropagation?: boolean;
  clientInternalNumberGOS?: number;
};

export function EmployeeActions({
  employeeData,
  dependentData,
  stopPropagation,
}: Props) {
  const { t } = useTranslation();
  const { palette } = useAppTheme();
  const [manageDependentsOpen, setManageDependentsOpen] = useState(false);

  const isReadOnlyUser = useRoleType()?.hasReadOnlyRole;

  const params = useParams();
  const packageId = params.packageId;

  let employeeHasPackageEnrollment = false;
  if (packageId !== undefined) {
    employeeHasPackageEnrollment =
      employeeData.enrollments?.some(
        (el) => el.benefitPackageTag === Number(packageId)
      ) ?? false;
  }

  let dependentHasPackageEnrollment = false;
  if (packageId !== undefined) {
    dependentHasPackageEnrollment =
      dependentData?.enrollments?.some(
        (el) => el.benefitPackageTag === Number(packageId)
      ) ?? false;
  }

  console.log(
    employeeData && employeeHasPackageEnrollment,
    dependentData && dependentHasPackageEnrollment,
    employeeData,
    dependentData
  );
  const { mutate: onResendInvitation, isLoading: sendInvitationInProgress } =
    useResendEmployeeInvitation();

  const onManageDependentsOpen = useCallback((value) => {
    setManageDependentsOpen(value);
  }, []);

  const disableResendInvitationEmail =
    !employeeData?.selected || sendInvitationInProgress;

  const dependentNeedsAttention = dependentData?.needsAttentionInformation;

  const isNewDependent = dependentNeedsAttention
    ?.toLowerCase()
    ?.includes("new");

  return (
    <div
      onClick={(e) => {
        if (employeeData.selected) {
          e.stopPropagation();
        }
      }}
    >
      <ActionsWrap>
        {dependentData ? null : (
          <StyledTooltip
            relationship="label"
            content={{
              children: t("hbh.resendInvitation.label"),
            }}
          >
            <Button
              icon={
                <SendPersonRegular
                  style={{
                    ...(disableResendInvitationEmail
                      ? {}
                      : { color: palette.themePrimary }),

                    transform: "scale(1.1)",
                  }}
                />
              }
              disabled={disableResendInvitationEmail}
              appearance="transparent"
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                onResendInvitation(
                  {
                    payload: {
                      clientInternalNumberGos:
                        employeeData.clientInternalNumberGos || 0,
                      countryCode: employeeData.countryCode || 0,
                      emailList: [employeeData.employeeLoginEmailAddress || ""],
                    },
                  },
                  {
                    onSuccess: () => {
                      toast.success(t("hbh.invitationSentSuccessfully.label"));
                    },
                  }
                );
              }}
            />
          </StyledTooltip>
        )}
        {dependentData ? (
          <StyledTooltip
            relationship="label"
            content={{
              children: t("hbh.edit.label"),
            }}
          >
            <ActionButton
              disabled={isReadOnlyUser}
              icon={<PersonEdit24Regular />}
              style={{
                ...(dependentNeedsAttention
                  ? (() => {
                      if (isNewDependent) {
                        return { color: palette.green };
                      }
                      return { color: palette.yellowDark };
                    })()
                  : {}),
                minWidth: 24,
                padding: 1,
              }}
              noBorder
              appearance="subtle"
              onClick={(e) => {
                setManageDependentsOpen(true);
              }}
            />
          </StyledTooltip>
        ) : (
          <ManageEmployee
            item={employeeData}
            disabled={!!employeeData.disabled || isReadOnlyUser}
          />
        )}

        {/* <EmployeeEnrollments item={item} /> */}

        <EmployeeEligibility
          employeeData={employeeData}
          dependentData={dependentData}
          disabled={
            (employeeData !== undefined &&
              dependentData === undefined &&
              employeeHasPackageEnrollment) ||
            (dependentData !== undefined && dependentHasPackageEnrollment)
          }
        />

        <DeletePackageUser
          employeeData={employeeData}
          dependentData={dependentData}
          closeDialog={() => {
            onManageDependentsOpen(false);
          }}
        />
      </ActionsWrap>

      {manageDependentsOpen && dependentData && (
        <EmployeeDependentsDialog
          singleDependentEdit
          employeeData={employeeData}
          open={manageDependentsOpen}
          setOpen={onManageDependentsOpen}
          dependentData={dependentData}
        />
      )}
    </div>
  );
}

const ActionsWrap = styled.div`
  display: flex;
  gap: 5px;
  width: 100%;
  justify-content: center;
`;
