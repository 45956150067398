import { BreadcrumbDivider, Text } from "@fluentui/react-components";
import { AvatarWithPreview } from "components/AvatarWithPreview/AvatarWithPreview";
import { Flex } from "components/styled";
import { useAppTheme } from "hooks/useAppTheme";
import { EImageView } from "library/types";
import {
  extractFileNameFromUrlWithoutExtension,
  getExtensionFromUrl,
} from "library/utils";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

type Props = {
  image?: string;
  type?: EImageView;
};

export function ViewByType({ image, type }: Props) {
  const theme = useAppTheme();
  const { t } = useTranslation();

  const fileName = extractFileNameFromUrlWithoutExtension(image);
  const docType = getExtensionFromUrl(image);

  const imageSize = (() => {
    if (type === EImageView.largeImages) {
      return 64;
    }
    if (type === EImageView.mediumImages) {
      return 48;
    }
    if (type === EImageView.smallImages) {
      return 20;
    }
    return 28;
  })();

  if (type === EImageView.tiles) {
    return (
      <TilesWrap>
        <AvatarWithPreview
          style={{
            display: "inline-block",
            boxShadow:
              "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
          }}
          size={36}
          shape="circular"
          name={image}
          imgSrc={image}
        />
        <Flex direction="column" gap={"2px"} $align="flex-start">
          <Text weight="semibold">{fileName}</Text>
          <Text weight="semibold">{`.${docType}`}</Text>
        </Flex>
      </TilesWrap>
    );
  }

  return (
    <>
      <Flex $align="flex-end">
        <AvatarWithPreview
          style={{
            display: "inline-block",
            boxShadow:
              "rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px",
          }}
          size={imageSize}
          shape="square"
          name={image}
          imgSrc={image}
        />
        <Flex gap={"10px"}>
          <Text weight="semibold">{fileName}</Text>
          {type === EImageView.details && (
            <>
              <BreadcrumbDivider />
              <Text>{t("hbh.fileType.label")}:</Text>
              <Text weight="semibold">.{docType}</Text>
            </>
          )}
        </Flex>
      </Flex>
    </>
  );
}

const TilesWrap = styled.div`
  display: flex;
  gap: 10px;
`;
