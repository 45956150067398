import React from "react";
import { AdminRefreshBtn } from "./AdminRefreshBtn";

export function AdminPageSection() {
  return (
    <div>
      <AdminRefreshBtn />
    </div>
  );
}
