import { Button } from "@fluentui/react-components";
import { ArrowClockwiseRegular } from "@fluentui/react-icons";
import { useAtomValue, useSetAtom } from "jotai";
import { EAdminTabView } from "library/types";
import { useInvalidateBenefitCategories } from "state/queries/useGetBenefitCategories";
import { refreshAnalyticsAtom, selectedAdminTabState } from "store";

export function AdminRefreshBtn() {
  const invalidateBenefitCategories = useInvalidateBenefitCategories();
  const setRefreshAnalyticsAtom = useSetAtom(refreshAnalyticsAtom);
  const selectedTab = useAtomValue(selectedAdminTabState);

  const handleRefresh = () => {
    if (selectedTab === EAdminTabView.benefits) {
      invalidateBenefitCategories();
      return;
    }
    if (selectedTab === EAdminTabView.analytics) {
      setRefreshAnalyticsAtom((prev) => !prev);
    }
  };

  return (
    <Button
      onClick={handleRefresh}
      appearance="outline"
      icon={<ArrowClockwiseRegular />}
    />
  );
}
