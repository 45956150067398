import useNotificationHandler from "hooks/useNotificationHandler";
import { QUERY_KEYS } from "library/shared";
import { IPolicy } from "library/types";
import { UseQueryResult, useQuery } from "react-query";
import api from "service/api";

type Params = {
  countryCode: number | string | null;
  clientInternalNumberGos: number | string | null;
  letters?: string;
  enabled?: boolean;
};

export const getPolicies = async ({
  countryCode,
  clientInternalNumberGos,
  letters = "",
}: Params) => {
  if (countryCode === null) return;
  const response = await api.get(
    `/policyversionsssearch/${countryCode}/${clientInternalNumberGos}/${
      letters || ` `
    }`
  );

  return response.data;
};

export function useGetPolicies({
  countryCode,
  letters,
  clientInternalNumberGos,
  enabled = true,
}: Params): UseQueryResult<IPolicy[]> {
  const { handleAddAxiosErrorNotification } = useNotificationHandler();

  return useQuery<IPolicy[], Error>(
    [QUERY_KEYS.insurers, countryCode, letters, clientInternalNumberGos],
    () => getPolicies({ countryCode, letters, clientInternalNumberGos }),
    {
      enabled: enabled,
      onError: (error: any) => {
        handleAddAxiosErrorNotification(error);
      },
    }
  );
}
