import {
  Breadcrumb,
  BreadcrumbDivider,
  BreadcrumbItem,
  Label,
} from "@fluentui/react-components";
import {
  CalendarClockRegular,
  TextBulletListSquareWarningRegular,
} from "@fluentui/react-icons";
import { ButtonWithTooltip } from "components/ButtonWithTooltip/ButtonWithTooltip";
import { isAfter, isBefore } from "date-fns";
import { useAppTheme } from "hooks/useAppTheme";
import { useRoleType } from "hooks/useRoleType";
import { useTaxonomyOptions } from "hooks/useTaxonomyOptions";
import { devices } from "library/constants";
import {
  EApprovalStatus,
  ETaxonomy,
  IBenefitPackageView,
  IEmployeeEnrollment,
  IEmployeeEnrollmentBase,
  IEmployeeView,
} from "library/types";
import {
  calculateAge,
  formatDateString,
  getEnrollmentStatusData,
  isBeforeOrOnToday,
  packageAgeConditionPassedForUser,
} from "library/utils";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { AvatarWithPreview } from "../../../../../../../components/AvatarWithPreview/AvatarWithPreview";
import { EmployeeEnrollmentsActions } from "./EmployeeEnrollmentsActions/EmployeeEnrollmentsActions";
import { BenefitInfoText } from "./BenefitInfoText";

type Props = {
  enrolledPackage: IEmployeeEnrollment;
  packageData: IBenefitPackageView;
  packageUser: IEmployeeView;
  enrollment: IEmployeeEnrollmentBase;
};

export function PackageRowItem({
  enrolledPackage,
  packageData,
  packageUser,
  enrollment,
}: Props) {
  const theme = useAppTheme();
  const { t } = useTranslation();
  const isReadOnly = useRoleType()?.hasReadOnlyRole;
  const enrollmentStatusOptions = useTaxonomyOptions(ETaxonomy.ApprovalStatus);

  const data = getEnrollmentStatusData({
    employeeEnrollment: enrollment,
    enrollmentStatusOptions,
    t,
  });
  const userAge = calculateAge(packageUser?.dateOfBirth || "");

  const ageConditionPassed = packageAgeConditionPassedForUser({
    userDateOfBirth: packageUser?.dateOfBirth,
    packageAgeLimitLower: packageData?.ageLimitLower,
    packageAgeLimitUpper: packageData?.ageLimitUpper,
  });
  const isApprovedEnrollment =
    data.approvalStatusCode === EApprovalStatus.APPROVED ||
    !data.approvalStatusCode;

  const isCancelledEnrollment =
    data.cancellationStatusCode === EApprovalStatus.APPROVED ||
    !data.cancellationStatusCode;

  const hasRejectStatus =
    data.approvalStatusCode === EApprovalStatus.REJECTED ||
    data.cancellationStatusCode === EApprovalStatus.REJECTED;

  const showRejectionComment = hasRejectStatus && enrollment?.rejectionComment;

  return (
    <Container>
      <Wrap
        $waitingForApproval={!!data.hasApproveActions}
        $waitingForRejection={!!data.hasRejectActions}
        $packageExpired={isBefore(packageData?.validTo || "", new Date())}
        $canceled={
          !!enrolledPackage?.dateOfCancellation && isCancelledEnrollment
        }
        $canceledInFuture={isAfter(
          enrolledPackage?.dateOfCancellation || "",
          new Date()
        )}
        $enrolled={
          !!enrolledPackage?.dateOfEnrollment &&
          isBeforeOrOnToday(enrolledPackage?.dateOfEnrollment) &&
          isApprovedEnrollment
        }
        $enrolledInFuture={
          !!enrolledPackage?.dateOfEnrollment &&
          isAfter(enrolledPackage?.dateOfEnrollment || "", new Date()) &&
          isApprovedEnrollment
        }
        $isDependent={!!packageUser?.isDependent}
      >
        <Item>
          <AvatarWithPreview
            avatarStyle={{
              borderRadius: "5px",
            }}
            size={28}
            name={`${packageData.name}`}
            imgSrc={packageData.backgroundPicturePath}
          />
        </Item>
        <Item>
          <Breadcrumb aria-label="Breadcrumb default example">
            <StyledBreadcrumbItem>
              {/* <CertificateRegular /> */}
              <BenefitInfoText text={packageData.benefitCategoryName} />
            </StyledBreadcrumbItem>
            <StyledBreadcrumbDivider />
            <StyledBreadcrumbItem>
              {/* <RibbonRegular /> */}
              <BenefitInfoText text={packageData.benefitImplementationName} />
            </StyledBreadcrumbItem>
            <StyledBreadcrumbDivider />
            <StyledBreadcrumbItem>
              <BenefitInfoText text={packageData.name} />
            </StyledBreadcrumbItem>
          </Breadcrumb>
        </Item>
        <Item>
          <Label>{t("hbh.validFromTo.label")}:</Label>
          <Value>
            {formatDateString(packageData?.validFrom || "")} {" - "}
            {formatDateString(packageData?.validTo || "")}
          </Value>
        </Item>
        <Item>
          <Label>{t("hbh.eligibleFrom.label")}:</Label>
          <Value>
            {formatDateString(enrolledPackage?.dateOfEligibility ?? "")}
          </Value>
        </Item>
        {enrolledPackage?.dateOfEnrollment ? (
          <Item>
            <Label>{t("hbh.enrollmentDate.label")}:</Label>
            <Value>{formatDateString(enrolledPackage?.dateOfEnrollment)}</Value>
          </Item>
        ) : null}
        {enrolledPackage?.dateOfCancellation ? (
          <Item>
            <Label>{t("hbh.cancelationDate.label")}:</Label>
            <Value>
              {formatDateString(enrolledPackage?.dateOfCancellation)}
            </Value>
          </Item>
        ) : null}
        <IconWrap>
          {!ageConditionPassed ? (
            <ButtonWithTooltip
              style={{
                paddingLeft: 0,
                width: "fit-content",
                minWidth: "fit-content",
              }}
              tooltipContent={t("hbh.packageAgeConditionFailed.label", {
                packageUserName: `${packageUser?.firstName} ${packageUser?.lastName}`,
                packageUserAge: userAge,
                lowerPackageLimit: packageData?.ageLimitLower,
                upperPackageLimit: packageData?.ageLimitUpper,
              })}
              icon={
                <CalendarClockRegular
                  style={{
                    color: theme.palette.redDark,
                  }}
                />
              }
            />
          ) : null}
          {showRejectionComment ? (
            <ButtonWithTooltip
              style={{
                padding: 0,
                width: "fit-content",
                minWidth: "fit-content",
              }}
              tooltipContent={enrollment?.rejectionComment || ""}
              icon={
                <TextBulletListSquareWarningRegular
                  style={{
                    color: theme.palette.redDark,
                  }}
                />
              }
            />
          ) : null}
          <EmployeeEnrollmentsActions
            packageData={packageData}
            packageUser={packageUser}
            enrollment={enrollment}
            disabled={isReadOnly}
          />
        </IconWrap>
      </Wrap>
    </Container>
  );
}

export default PackageRowItem;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
`;

const Wrap = styled.div<{
  $enrolled: boolean;
  $enrolledInFuture: boolean;
  $canceled: boolean;
  $isDependent: boolean;
  $packageExpired: boolean;
  $canceledInFuture: boolean;
  $waitingForApproval: boolean;
  $waitingForRejection: boolean;
}>`
  display: flex;

  background: ${({
    theme,
    $enrolled,
    $canceled,
    $packageExpired,
    $enrolledInFuture,
    $canceledInFuture,
  }) => {
    if ($packageExpired) {
      return theme.palette.neutralTertiary;
    }
    if ($canceled && !$canceledInFuture) {
      return theme.semanticColors.blockingBackground;
    }

    if ($canceledInFuture) {
      return `linear-gradient(to right, ${theme.semanticColors.successHighlight} 70%, ${theme.semanticColors.blockingBackground} )`;
    }

    if ($enrolledInFuture) {
      return `linear-gradient(to right, ${theme.palette.neutralLighter} 70%, ${theme.semanticColors.successHighlight})`;
    }

    if ($enrolled) {
      return theme.semanticColors.successHighlight;
    }

    return theme.palette.neutralLighter;
  }};

  border: ${({ theme, $waitingForApproval, $waitingForRejection }) => {
    if ($waitingForApproval) {
      return `3px dashed ${theme.palette.greenDark}`;
    }
    if ($waitingForRejection) {
      return `3px dashed ${theme.palette.redDark}`;
    }

    return "none";
  }};

  border-left: ${({
    theme,
    $canceledInFuture,
    $waitingForApproval,
    $waitingForRejection,
  }) => {
    if ($canceledInFuture) {
      return `5px solid ${theme.semanticColors.blockingBackground}`;
    }

    if ($waitingForApproval) {
      return `3px dashed ${theme.palette.greenDark}`;
    }
    if ($waitingForRejection) {
      return `3px dashed ${theme.palette.redDark}`;
    }

    return `5px solid transparent`;
  }};

  border-radius: 5px;
  gap: 10px;
  margin: ${({ $isDependent }) =>
    $isDependent ? "0 10px 2px 50px" : "0 0 2px 0"};
  box-sizing: border-box;
  padding-right: 2px;
`;

const Item = styled.div<{ width?: string }>`
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;

  padding: 5px;
  width: ${({ width }) => (width ? width : "fit-content")};

  @media only screen and ${devices["2xl"]} {
    flex-direction: row;
  }
`;

const Value = styled.div`
  font-weight: 600;
`;

const IconWrap = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`;

const StyledBreadcrumbItem = styled(BreadcrumbItem)`
  width: 200px;
`;
const StyledBreadcrumbDivider = styled(BreadcrumbDivider)`
  padding: 0 5px;
`;
