import { Text, Tooltip, Tree, TreeItem } from "@fluentui/react-components";
import styled from "styled-components";

import { TreeItemLayout } from "@fluentui/react-components";
import { Flex } from "components/styled";
import { useTaxonomyOptions } from "hooks/useTaxonomyOptions";
import { useAtomValue } from "jotai";
import { devices } from "library/constants";
import {
  EApprovalStatus,
  EmployeeRowItem,
  ETaxonomy,
  IBenefitPackageView,
  IEmployeeEnrollment,
  IEmployeeView,
} from "library/types";
import { companyPackagesState } from "store/UIHrPage";

import { AvatarWithPreview } from "../../../../../../../components/AvatarWithPreview/AvatarWithPreview";
import { EmployeeActions } from "../EmployeeActions/EmployeeActions";
import PackageRowItem from "./PackageRowItem";
import { Warning20Regular } from "@fluentui/react-icons";
import { useAppTheme } from "hooks/useAppTheme";
import {
  getEnrollmentStatusData,
  packageAgeConditionPassedForUser,
} from "library/utils";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

type Props = {
  dependent: IEmployeeView;
  employee: EmployeeRowItem;
};

export function EmployeeDependentRow({ dependent, employee }: Props) {
  const { palette } = useAppTheme();
  const { t } = useTranslation();

  const params = useParams();
  const packageId = params.packageId;

  const companyPackageObject = useAtomValue(companyPackagesState);
  const enrollmentStatusOptions = useTaxonomyOptions(ETaxonomy.ApprovalStatus);

  const dependentTypeOptions = useTaxonomyOptions(ETaxonomy.DependentType);

  const dependentType = dependentTypeOptions.find(
    (option) => option.value === dependent.dependentType
  )?.text;

  const employeeDependentsNeedAttention =
    dependent.enrollments?.some((enrollment) => {
      const enrollmentData = getEnrollmentStatusData({
        t,
        employeeEnrollment: enrollment,
        enrollmentStatusOptions,
      });
      const benefitPackage = (companyPackageObject || {})[
        enrollment.benefitPackageTag
      ];
      const dependentPackageAgeConditionPassed =
        packageAgeConditionPassedForUser({
          userDateOfBirth: dependent?.dateOfBirth,
          packageAgeLimitLower: benefitPackage?.ageLimitLower,
          packageAgeLimitUpper: benefitPackage?.ageLimitUpper,
        });
      return (
        enrollmentData.approvalStatusCode === EApprovalStatus.PENDING ||
        enrollmentData.cancellationStatusCode === EApprovalStatus.PENDING ||
        !dependentPackageAgeConditionPassed
      );
    }) || dependent?.needsAttention;

  return (
    <>
      <Tree aria-label="Default">
        <TreeItem itemType={dependent?.enrollments?.length ? "branch" : "leaf"}>
          <TreeItemLayout
            style={{
              padding: "5px",
              borderRadius: "5px",
              paddingLeft: dependent?.enrollments?.length ? "5px" : "30px",
            }}
          >
            <Row>
              <Flex $align="center" wrap="nowrap">
                {employeeDependentsNeedAttention && (
                  <Warning20Regular
                    style={{
                      color: palette.redDark,
                      marginBottom: 10,
                    }}
                  />
                )}
                <AvatarWithPreview
                  name={`${dependent.firstName} ${dependent.lastName}`}
                  imgSrc={dependent.backgroundPicturePath}
                />
                <Tooltip
                  relationship="description"
                  content={`${dependent.firstName} ${dependent.lastName} - ${dependentType}`}
                >
                  <Text
                    block
                    truncate
                    style={{
                      maxWidth: "400px",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                  >
                    {`${dependent.firstName} ${dependent.lastName} - ${dependentType}`}
                  </Text>
                </Tooltip>
              </Flex>
              <Flex $align="center" wrap="nowrap">
                <EmployeeActions
                  stopPropagation
                  employeeData={{
                    ...employee,
                    dateOfEmployment: employee?.dateOfEmployment,
                  }}
                  dependentData={{
                    ...dependent,
                    dateOfEmployment: employee?.dateOfEmployment,
                  }}
                />
              </Flex>
            </Row>
          </TreeItemLayout>
          <Tree itemType="branch">
            {dependent?.enrollments?.map((enrollment, index) => {
              const dependentPackageData = companyPackageObject
                ? companyPackageObject[enrollment.benefitPackageTag]
                : ({} as IBenefitPackageView);

              const enrolledPackage = (dependent.enrollments || []).find(
                (enroll) => {
                  return (
                    enroll.benefitPackageTag === enrollment.benefitPackageTag
                  );
                }
              );
              dependent.dateOfEmployment = employee?.dateOfEmployment;

              return (
                <TreeItem itemType="branch" key={index}>
                  <PackageRowItem
                    enrolledPackage={enrolledPackage as IEmployeeEnrollment}
                    packageData={dependentPackageData}
                    packageUser={dependent}
                    enrollment={enrollment}
                  />
                </TreeItem>
              );
            })}
          </Tree>
        </TreeItem>
      </Tree>
    </>
  );
}

const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  @media only screen and ${devices.md} {
    width: 600px;
  }
`;
