import { Button } from "@fluentui/react-components";
import { SendFilled } from "@fluentui/react-icons";
import { TextareaField } from "components/controls/TextareaField";
import { Flex } from "components/styled";
import { useAppTheme } from "hooks/useAppTheme";
import { useAtom, useAtomValue } from "jotai";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSendMsgToChatbot } from "state/queries/useSendMsgToChatbot";
import { useUserData } from "state/queries/useUserData";
import { chatHistoryAtom, chatIdAtom } from "store/UIHrPage";
import styled from "styled-components";

type Props = {
  historyContainerRef: React.RefObject<HTMLDivElement>;
  newMessageState: {
    message: string;
    sent: boolean;
  };
  setNewMessageState: ({
    message,
    sent,
  }: {
    message: string;
    sent: boolean;
  }) => void;
};

export function NewMessage({
  historyContainerRef,
  newMessageState,
  setNewMessageState,
}: Props) {
  const theme = useAppTheme();
  // const [message, setMessage] = useState("");
  const { t } = useTranslation();
  const { data: user } = useUserData();

  const [chatHistory, setChatHistory] = useAtom(chatHistoryAtom);

  // const { sendNotification } = useSendPushNotification();
  const { mutate: onSendMsgToChatbot, isLoading } = useSendMsgToChatbot();

  const chatSessionId = useAtomValue(chatIdAtom);

  const scrollToBottom = () => {
    if (historyContainerRef?.current) {
      (historyContainerRef?.current).scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  const handleSendMessage = () => {
    setTimeout(() => {
      if (historyContainerRef?.current) {
        scrollToBottom();
      }
    }, 100);
    setNewMessageState({
      message: newMessageState?.message,
      sent: true,
    });
    onSendMsgToChatbot(
      {
        payload: {
          Id: chatSessionId || "",
          Question: newMessageState?.message || "",
          User: `${user?.displayName}`,
          MessageHistory: chatHistory as any,
        },
      },
      {
        onSuccess: (response) => {
          setChatHistory((prev) => {
            return [
              ...prev,
              {
                PrevQuestion: newMessageState?.message,
                PrevAnswer: response,
              },
            ];
          });
          setNewMessageState({
            message: "",
            sent: false,
          });
        },
      }
    );
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // Prevents a new line in the textarea

      handleSendMessage();
      // You can trigger any action here, such as submitting the text
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatHistory]);

  return (
    <Flex
      width={"100%"}
      justify="space-between"
      $align="flex-end"
      wrap="nowrap"
    >
      <TextareaField
        size="small"
        disabled={isLoading || !chatSessionId}
        noLabel
        label=""
        noErrorSection
        name="message"
        value={newMessageState.message}
        onKeyDown={handleKeyDown}
        setFieldValue={(name, value) => {
          setNewMessageState({
            message: value,
            sent: false,
          });
        }}
        placeholder={t("hbh.writeMessage.label")}
        error={""}
        rows={1}
        maxLength={400}
        resize="none"
      />
      <StyledButton
        appearance="primary"
        size="large"
        disabled={isLoading || !newMessageState.message || !chatSessionId}
        onClick={() => {
          handleSendMessage();
        }}
        icon={
          <SendFilled
            style={{
              color: theme.palette.white,
            }}
          />
        }
        shape="circular"
      />
    </Flex>
  );
}

export default NewMessage;

const StyledButton = styled(Button)`
  &:hover {
    opacity: 0.5;
  }
`;
